import { UserSiteDetails } from "../Constants/UserSite"

export const getusersitedata = (usersitetypes) => {
    return {
        type: UserSiteDetails.GET_USERSWITHSITES,
        payload: usersitetypes
    };
};
export const getsitesbyuserid = (usersitetypes) => {
    return {
        type: UserSiteDetails.GET_SITESBYUSERID,
        payload: usersitetypes
    };
};

export const postusersitedata = (usersitetypes) => {
    return {
        type: UserSiteDetails.POST_USERSWITHSITES,
        payload: usersitetypes
    };
};

export const updateusersitedata = (usersitetypes) => {
    return {
        type: UserSiteDetails.UPDATE_USERSWITHSITES,
        payload: usersitetypes
    };
};
export const deleteusersitedata = (usersitetypes) => {
    return {
        type: UserSiteDetails.DELETE_USERSWITHSITES,
        payload: usersitetypes
    };
};
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchUsersWithRoles, fetchUserById, loginasuser, fetchUserRoledata, fetchAllRoles, fetchAllCompaniesdata } from "../../Redux/Api/fetch.api";
import { getusersandroles, deleteuserdata } from '../../Redux/Actions/UsersActions';
import Add from "../../assets/images/Add.svg";
import Table, { TableProps } from "antd/lib/table";

import Trash_light from "../../assets/images/Trash_light.svg";
import updateIcon from "../../assets/images/updateIcon.svg";
import searchIcon from "../../assets/images/SearchIcon.svg";
//import emailIcon from "../../assets/images/emailicon.svg";
import emailIcon from "../../assets/images/EmailIconNew.svg";
import { Modal } from 'antd';
import Layout from '../Layout';
import axios from "axios";
import * as url from "../../api/uri_helper";
import NotAuthorised from "../Authentication/NotAuthorised";
import { getuserroleandmenu, saveuserdetails,validateuserdata } from '../../Redux/Actions/UsersActions';
import { Menu, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { getallroles } from "../../Redux/Actions/RolesActions";
import { getallcompanies } from "../../Redux/Actions/CompanyActions";
import { Input, Form, FormFeedback } from "reactstrap"
import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/bootstrap.css";
import { Tooltip, Switch } from 'antd';
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';



const UserIndex = () => {
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE);
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['50', '100'],
            showSizeChanger: true,
        },
    });
    // const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')
    const [searchClicked, setSearchClicked] = useState("")
    const [userName, setUserName] = useState("")
    const [roleName, setRoleName] = useState(null)
    const [sortCompany, setSortCompany] = useState('');
    const [isrolecheck, setIsRoleCheck] = useState(false);
    const [iscompanycheck, setIsCompanyCheck] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [name, setName] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [masterCompanies, setMasterCompanies] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedMasterCompanies, setSelectedMasterCompanies] = useState([]);
    const [isRoleReset, setIsRoleReset] = useState(false);
    const [isCompanyReset, setIsCompanyReset] = useState(false);
    const [isCloneUser, setIsCloneUser] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchCompany, setSearchCompany] = useState('');
    const [cloneModal, setCloneModal] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [selectedCountryCode, setSlectedCountryCode] = useState()
    const [phnumber, setPhnumber] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [roleId, setRoleId] = useState();
    const [allowSupportCall, setAllowSupportCall] = useState(false);
    const [isActive, setisActive] = useState(true)
    const [resetDisplay, setResetDisplay] = useState(false);
   
   

    useEffect(() => {
       
        getRoleDetails();
        getalluserswithroles();
        getRoles();
        getallcompaniesdata();
    }, [])

    useEffect(() => {      
        if (currentId) {
            setLoading(true)
            fetchUserById(currentId).then(
                response => {                   
                    setFirstName(response.data.data.firstName);
                    setLastName(response.data.data.lastName);
                    setRoleId(response.data.data.roles[0].roleId)
                    setCompanyId(response.data.data.companyId)
                    setisActive(response.data.data.isActive)
                    setAllowSupportCall(response.data.data.isSupportCall)
                    setLoading(false)
                   
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [currentId])

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const getRoles = () => {
        fetchAllRoles()
            .then(response => {
                dispatch(getallroles(response.data))
                setAllRoles(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

   

    const handleToggle = () => {
        setAllowSupportCall(!allowSupportCall);
    };

        const handleSearchChange = (e) => {
            setSearchQuery(e.target.value);
        };
    

    const filteredRoles = allRoles.filter(role =>
        role.roleName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredCompany = masterCompanies.filter(company =>
        company.companyName.toLowerCase().includes(searchCompany.toLowerCase())
    );

    const handleCompanyChange = (e) => {
        setSearchCompany(e.target.value);
    };
    const getallcompaniesdata = () => {
        fetchAllCompaniesdata()
            .then(response => {
                dispatch(getallcompanies(response.data))
                setMasterCompanies(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const cloneUserData = async (e) => {       
        setLoading(true)
        const formData = new FormData();
        formData.append("FirstName", name);
        formData.append("LastName", lname);
        formData.append("EmailId", email.toLowerCase());
        formData.append("PasswordHash", password);
        formData.append("PhoneNumber", phnumber);
        formData.append("RoleId", roleId);      
        formData.append("CompanyId", companyId);
        formData.append("IsActive", isActive);
        formData.append("IsSupportCall", allowSupportCall);
       // formData.append("LoggedInId", loggedUserId);
        formData.append("CloneUserId", currentId);
        const response_ = await axios({
            method: 'post',
            url: url.POST_ADDUSER,
            data: formData,
        }).then(response => {

            if (response.data.success == false) {
                toast.error(response.data.message, {
                    className: "toastSuccess",
                });
            }
            else {               
                dispatch(saveuserdetails(response.data))
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                setName("")
                setLname("")
                setEmail("")
                setPassword("")
                setPhnumber("")
                setCloneModal(false);
                getalluserswithroles();
            }
            setLoading(false)
        })
            .catch((err) => {
               
                toast.error(err.response.data.message, {
                    className: "toastSuccess",
                },);               
            });
        setLoading(false)

    }
    const getRoleDetails = () => {
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
                // setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const handleRole = (roleId) => {
        setIsRoleCheck(true)
        setSelectedRoles((prev) => {
            if (prev.includes(roleId)) {
                return prev.filter(sel => sel !== roleId);
            }
            else {
                return [...prev, roleId];
            }
        });
    }
    const handleCompany = (companyId) => {
        setIsCompanyCheck(true)
        setSelectedMasterCompanies((prev) => {
            if (prev.includes(companyId)) {
                return prev.filter(sel => sel !== companyId);
            }
            else {
                return [...prev, companyId];
            }
        });
    }

    const getalluserswithroles = (isReset = false) => {       
        setLoading(true)
        fetchUsersWithRoles({
            UserId: loggedUserId,
            UserName: isReset ? "" : userName,
            roleIds: selectedRoles,
            companyIds: selectedMasterCompanies
        })
            .then(response => {
                dispatch(getusersandroles(response.data))
                setTable(response.data.data)
                setOptions({
                    ...options,
                    pagination: {
                        ...options.pagination,
                        total: response.data.count,
                    },
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
            
    }

    const getvalidateuser = (emailid) => {
        loginasuser(emailid)
            .then(response => {

                dispatch(validateuserdata(response.data))
                localStorage.setItem('UserId', response.data.data.userId);
                localStorage.setItem('tekaVerseToken', response.data.data.token);
                localStorage.setItem('isloginClicked', true)
                history.push("/Dashboard");

            })
            .catch((err) => {
                console.log("Err: ", err);
            });

    }
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: name ? name : '',
            lname: lname ? lname : '',
            email: email ? email : '',
            password: password ? password : '',
           
        },

        validationSchema: Yup.object({
            name: Yup.string().required('Please Enter Name ').matches(
                "^[A-Za-z][A-Za-z]*$",
                "Please enter valid Name"),

            lname: Yup.string().required('Please Enter Last Name ').matches(
                "^[A-Za-z][A-Za-z]*$",
                "Please enter valid Name"),

            email: Yup.string().required('Please Enter Email').matches(
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                "Please enter valid email "),

            password: Yup.string().required("Please Enter Password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
        }),

            

        

        onSubmit: (values) => {
            if (!phoneError) {
               
                cloneUserData(values)
            }
        }
    })

    const SearchControl = async () => {
        if (userName !== '') {
            setResetDisplay(true);
        }

        else if (userName == '' && resetDisplay) {
            setResetDisplay(false);
        }
        setSearchClicked(true)
        getalluserswithroles();
    }

    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const bindRole = (userRoles) => {
        return userRoles.map(x => x.roleName).join(", ");
    }

    const handleEdit = (userId) => {
        history.push('/User/edit/' + userId)
    }

    const handleLogin = (emailId) => {
        localStorage.removeItem('tekaVerseToken');
        getvalidateuser(emailId);
    }

    const handleClone = (userId) => {
        setCurrentId(userId)
        setIsCloneUser(true)
       // history.push('/User/edit/' + userId)
        setCloneModal(true)
        
    }

    const handleCancel = () => {
        setName("")
        setLname("")
        setEmail("")
        setPassword("")
        setPhnumber("")
        setSlectedCountryCode()
        setCloneModal(false);
        //getalluserswithroles();
    };

  
    const { confirm } = Modal;
    const handleDelete = (userId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteUserRecord(userId)
            },
            onCancel() {
            },
        });
    }

    const handleResetButton = () => {
        setUserName("")
        getalluserswithroles(true);
        setResetDisplay(false);
    }

    const handleSendRequest = (emailid) => {
        confirm({
            title: 'Are you sure you want to send mail to user?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleSendMail(emailid)
            },
            onCancel() {
            },
        });
    }

    const handleSendMail = async (email) => {

        setLoading(true)
        if (email) {
            const response = await axios.get(url.GET_SENDUSEREMAIL + '\\' + email,)
                .then(response => {

                    if (response.data.success) {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        },);
                        setLoading(false)

                    }
                    else {
                        toast.error(response.data.message, {
                            className: "toastSuccess",
                        },);
                    }

                })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        };
        setLoading(false)

    }

    const handleKeyPress = (e) => {

        if (e.key === "Enter") {

            SearchControl();

        }
    }

    const deleteUserRecord = async (userId) => {

        setLoading(true)
        await axios.delete(url.DELETE_USERTYPE + '/' + userId, {
        }).then(response => {

            dispatch(deleteuserdata(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(response.data.message, {
                className: "toastSuccess",
            },);
            getalluserswithroles()

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(false)
    }



    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",
            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * REACT_APP_DEFAULT_PAGESIZE + index + 1}` },
        },

        {
            title: "Name",
            dataIndex: "name",
            render: (data, user) => (user.lastName + " " + user.firstName),
        },

        {
            title: "Email",
            dataIndex: "emailId",
        },

        {
            title: "Master Company ",
            dataIndex: "companyName",
            render: (companyName) => <Tooltip title={companyName}>{companyName}</Tooltip>,
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            sortOrder: sortCompany,
            onHeaderCell: () => ({
                onClick: () => setSortCompany(sortCompany === 'ascend' ? 'descend' : 'ascend'),
                sortDirections: ['ascend', 'descend', 'ascend'],
            })

        },

        {
            title: "Roles",
            dataIndex: "roles",
            render: (data, user) => (
                user.roles ?
                    <div className="ellips-dec">
                        <Tooltip title={bindRole(user.roles)}>{bindRole(user.roles)}</Tooltip></div>
                    : "NA"
            ),
        },
        {
            title: "Support Call",
            dataIndex: "isSupportCall",
            render: (data) => (data ? "Y" : "N"),
            align: "center",
        },

        {
            title: "Status",
            dataIndex: "isActive",
            render: (data) => (data ? "Active" : "Inactive"),
        },
        {
            title: "Send Request",
            key: 'index',
            render: (text, record, index) => (<>

                <button className="table-btn" id={index + 1}>
                    <img src={emailIcon} onClick={(e) => handleSendRequest(record.emailId)} ></img>
                </button>
            </>)
        },
        {
            title: "Action",
            dataIndex: "",

            render: (data, companyId) => (
                <Dropdown className="action-dropdown"
                    overlay={
                        <Menu>
                            {roleName == "SuperAdmin" && loggedUserId != data.userId ?
                                <Menu.Item key="edit" onClick={() => handleLogin(data.emailId)} >
                                    Login as This User
                                </Menu.Item> : ""
                            }
                            {roleName == "SuperAdmin" ?
                                <Menu.Item key="clone" onClick={() => handleClone(data.userId)}>
                                    Clone User
                                </Menu.Item> : ""
                            }
                            <Menu.Item key="edit" onClick={() => handleEdit(data.userId)}>
                                Edit
                            </Menu.Item>
                            <Menu.Item key="delete" onClick={() => handleDelete(data.userId)}>
                                Delete
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                    placement="bottomRight"
                >
                    <a className="ant-dropdown-link d-flex justify-content-between align-items-center" onClick={e => e.preventDefault()}>
                        <span>Actions </span>
                        {/*<DownOutlined style={{ fontSize: '8px' }}*/}
                        {/*/>*/}
                    </a>
                </Dropdown>
            )
        },
    ]
    const scrollerStyle = {
        maxHeight: '300px', // Adjust the height as needed
        overflowY: 'auto',
    };
    const fixedBottomStyle = {
        position: '-webkit-sticky',
        position: 'sticky',
        bottom: '0',
        left: '0',
        width: '100%',
        backgroundColor: 'white',
        padding: '10px',
        boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
        textAlign: 'right',
        zIndex: '1000',
    };
    useEffect(() => {
        if (isRoleReset || isCompanyReset) {
            SearchControl();
            if (isCompanyReset) {
                setIsCompanyReset(false);
            }
            if (isRoleReset) {
                setIsRoleReset(false);
            }
        }
    }, [isRoleReset, isCompanyReset]);

    return (
        <div>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" &&
                roleName && roleName != "Admin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                    <Layout>
                        <section className="User_section common ">
                            <Toaster />
                            <div className="container">
                                <div className="mt-4 w-100 position-relative wk-select">                                  
                                        <h4 className="col-md-2 px-1">User</h4>
                                       
                                    <div className="ml-auto d-flex flex-wrap right-section">
                                        <div className="type-drop common-drop dropdown roles-dropbox-btn">
                                            <button className="btn w-100 text-left text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                Roles
                                                {selectedRoles.length > 0 && (
                                                    <span className="badge bg-secondary rounded-circle position-absolute top-0 start-100 translate-middle">
                                                        {selectedRoles.length}
                                                    </span>
                                                )}
                                            </button>
                                            <ul className="dropdown-menu roles-dropbox" aria-labelledby="dropdownMenuButton1"
                                                style={scrollerStyle}
                                            >
                                                <li className="px-3">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-2"
                                                        placeholder="Search By Role"
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                    />
                                                </li>

                                                {filteredRoles.map((item) =>
                                                    <li className="px-3">
                                                        <a className="dropdown-item">
                                                            <input type="checkbox" className="form-check-input cursor-pointer" name="checkname"
                                                                checked={selectedRoles.includes(item.roleId)}
                                                                onChange={() => { handleRole(item.roleId) }}
                                                            />
                                                            <label className="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.roleName}</label>
                                                        </a>
                                                    </li>
                                                )}
                                                <li className="text-right"
                                                    style={fixedBottomStyle}
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-bordered mr-2"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            SearchControl()
                                                        }}
                                                        disabled={!isrolecheck}
                                                    >
                                                        Apply
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-bordered"
                                                        onClick={() => {
                                                            setSelectedRoles([])
                                                            setIsRoleReset(true);
                                                            setSearchQuery('');
                                                            setIsRoleCheck(false)
                                                        }}
                                                    >
                                                        Clear
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="type-drop common-drop dropdown companies-dropbox-btn">
                                            <button className="btn w-100 text-left text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                Master Company
                                                {selectedMasterCompanies.length > 0 && (
                                                    <span className="badge bg-secondary rounded-circle position-absolute top-0 start-100 translate-middle">
                                                        {selectedMasterCompanies.length}
                                                    </span>
                                                )}
                                            </button>
                                            <ul className="dropdown-menu companies-dropbox" aria-labelledby="dropdownMenuButton1"
                                                style={scrollerStyle}
                                            >

                                                <li className="px-3">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-2"
                                                        placeholder="Search By Company"
                                                        value={searchCompany}
                                                        onChange={handleCompanyChange}
                                                    />
                                                </li>
                                                {filteredCompany.map((item) =>
                                                    <li className="px-3">
                                                        <a className="dropdown-item">
                                                            <input type="checkbox" className="form-check-input cursor-pointer" name="checkname"
                                                                checked={selectedMasterCompanies.includes(item.companyId)}
                                                                onChange={() => { handleCompany(item.companyId) }}
                                                            />
                                                            <label className="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.companyName}</label>
                                                        </a>
                                                    </li>
                                                )}

                                                <li className="text-right"
                                                    style={fixedBottomStyle}
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-bordered mr-2" 
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            SearchControl()
                                                        }}
                                                        disabled={!iscompanycheck}
                                                       
                                                    >
                                                        Apply
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-bordered"
                                                        onClick={(e) => {
                                                            setSelectedMasterCompanies([]);
                                                            setIsCompanyReset(true);
                                                            setSearchCompany('');
                                                            setIsCompanyCheck(false)
                                                        }}
                                                    >
                                                        Clear
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='d-flex position-relative searchBox' >
                                            <input type="text" className='form-control searchQR' value={userName} placeholder='Search By Name/Email'
                                                onChange={(e) => {
                                                    setUserName(e.target.value.trimLeft());

                                                }}
                                                onKeyDown={(e) => handleKeyPress(e)}
                                            >

                                            </input>
                                            {userName == "" ?
                                                <button onClick={() => SearchControl()} disabled className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button> :
                                                <button onClick={() => SearchControl()} className="searchButton  mt-sm-0"> <img src={searchIcon} ></img></button>
                                            }
                                        </div>
                                        {userName == "" && !resetDisplay ? "" :
                                            <button className='btn btn-primary d-flex align-items-center btn-new' onClick={() => handleResetButton()} >Reset</button>
                                        }



                                        <Link className="btn btn-primary d-flex align-items-center btn-new" to="/Add/User"><img src={Add}></img>Add User</Link>
                                    </div>
                                </div>

                                <div className="Viewer-table index--table position-relative common-table mb-4">

                                    <Table
                                        className='antd--detail-table1'
                                        columns={columns}
                                        dataSource={table}
                                        pagination={options.pagination.total > REACT_APP_DEFAULT_PAGESIZE ? options.pagination : false}
                                        onChange={handleChange}
                                        paginationTotalRows={total}
                                    />
                                </div>



                            </div>
                        </section>


                    </Layout>

                    <Modal
                        title={<div className="modal-title">{`Clone ${firstname} ${lastname}`}</div>}
                        visible={cloneModal}
                        onCancel={handleCancel}
                        footer={null}
                        width={1000}
                       
                    >
                        <div className="content-area">
                            <Form className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    if (!phnumber || !(phnumber && phnumber.length > 10)) {
                                        setPhoneError(true);
                                    }
                                    
                                    return false;
                                }}>
                                 <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>First Name <span className="required-fields"> * </span></label>
                                    <div className="input-group">
                                        <Input name="name" className="form-control" placeholder="Enter Name"
                                                        type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setName(e.target.value.replace(/\s{2}/g, '')) }}
                                                        onBlur={validation.handleBlur} value={validation.values.name}
                                                        invalid={validation.touched.name && validation.errors.name ? true : false}
                                                    />
                                                    {validation.touched.name && validation.errors.name ? (
                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                    ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Last Name <span className="required-fields"> * </span></label>
                                    <div className="input-group">
                                        <Input name="lname" className="form-control" placeholder="Enter Last Name"
                                                        type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setLname(e.target.value.replace(/\s{2}/g, '')) }}
                                                        onBlur={validation.handleBlur} value={validation.values.lname}
                                                        invalid={validation.touched.lname && validation.errors.lname ? true : false}
                                                    />
                                                    {validation.touched.lname && validation.errors.lname ? (
                                                        <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                                    ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Email <span className="required-fields"> * </span></label>
                                    <div className="input-group">
                                        <Input name="email" className="form-control" placeholder="Enter Email"
                                                        type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setEmail(e.target.value) }}
                                                        onBlur={validation.handleBlur} value={validation.values.email}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false}
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                    </div>
                                </div>
                                </div> 

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Password
                                            <span className="required-fields"> * </span>
                                        </label>
                                        <div className="input-group">
                                            <Input name="password" className="form-control" placeholder="Enter Password"
                                                type={passwordType} maxLength={50} 
                                                        onChange={(e) => { validation.handleChange(e); setPassword(e.target.value) }}
                                                        onBlur={validation.handleBlur} value={validation.values.password}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false}
                                                    />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text user_icon" id="basic-addon2">
                                                            <div onClick={togglePassword}>
                                                                {passwordType === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                            </div>
                                                        </span>
                                                    </div>
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Phone Number <span className="required-fields"> * </span></label>
                                        <div className="input-group phnumber">
                                            <PhoneInput
                                                className="w-5"
                                                country={"us"}
                                                enableSearch={true}
                                                        value={phnumber}
                                                        placeholder="Enter phone number" // Custom placeholder
                                                onChange={(phone, country) => {
                                                    setSlectedCountryCode((prevState) => {
                                                        if (prevState !== country.countryCode) {
                                                            setPhnumber(country.dialCode);
                                                        }
                                                        else {
                                                            setPhnumber(phone);
                                                        }
                                                        return country.countryCode;
                                                    })
                                                    if (phone && phone.length > 10 && !phone.replace(country.dialCode, '').startsWith('0')) {
                                                        setPhoneError(false);
                                                    }
                                                    else {
                                                        setPhoneError(true);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {< small className="text-danger d-block mt-2">{!phoneError ? ' ' : 'Please Enter Valid Phone Number'}</small>}
                                    </div>
                                </div>

                                        <div className="col-md-4">
                                            <div className="form-group wk-select">
                                                <label>Master Company</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-0 flex-grow-1 filter-select"
                                                    value={masterCompanies && masterCompanies.find(company => company.companyId === companyId)?.companyName || "Select Company"}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group wk-select">
                                                <label>Roles</label>
                                                <input
                                                    type="text"
                                                    className="form-control mb-0 flex-grow-1 filter-select"
                                                    value={allRoles && allRoles.find(role => role.roleId === roleId)?.roleName || "Select Roles"}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                    <div className="col-md-12">
                                        <div className="alarm-toggle--wrap d-inline-block">

                                            <Switch
                                                id="allowNotifications"
                                                className="ms-2"
                                                checked={allowSupportCall}
                                                onChange={handleToggle}
                                            />

                                            <label className="alarm-toggle-label ml-2">Allow Support Call</label>
                                        </div>
                                    </div>

                                    <div className="text-right mt-3 clone-button">
                                        <button type="submit" className="btn btn-primary mr-3" >Clone</button>
                                        <button className="btn btn-primary" type="button" onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </div>
                            
                            </div>
                                </div>
                            </Form>
                        </div>
                    </Modal>

                    

                    
                </>
            }
        </div>
    )

}
export default connect()(UserIndex);

import { DashBoarddata } from "../Constants/Dashboard"
// initial state
const initialState = {
    dashboard: []
}

export const DashBoardReducers = (state = initialState, { type, payload }) => {
    switch (type) {



        case DashBoarddata.GET_DASHBOARDDATA:
            return { ...state, dashboard: payload };



        default:
            return state;
    }
};

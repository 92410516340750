import { VersionDetails } from "../Constants/Version"

export const getallversionsummary = (version) => {
    return {
        type: VersionDetails.GET_VERSIONDETAILS,
        payload: version
    };
};

export const updateapprovedsummary = (version) => {
    return {
        type: VersionDetails.UPDATE_APPROVEDSUMMARY,
        payload: version
    };
};

import { BannerDetails } from "../Constants/Banner"

export const getallbanners = (banner) => {
    return {
        type: BannerDetails.GET_BANNERDETAILS,
        payload: banner
    };
};

export const saveallbanners = (banner) => {
    return {
        type: BannerDetails.POST_BANNERDETAILS,
        payload: banner
    };
};

export const updatebannerdata = (banner) => {
    return {
        type: BannerDetails.UPDATE_BANNERDETAILS,
        payload: banner
    };
};

import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader";
import { Link, useHistory } from 'react-router-dom';
import TrafficImage from "../../assets/images/traffic-signal.png";

const NotAuthorised = () => {
   
    const Logout = (e) => {
       
        localStorage.removeItem('tekaVerseToken');
        window.location.replace("/");
       
    };
    return (
        <div>
            <section className="error-wrap">   
                <div className="error-img mb-4">
                    <img src={TrafficImage}></img>
                </div>
                <h2>You are not authorised </h2>
                <p className="mb-0">It seems like you don't have permission to use this portal.</p>
                <p>Please sign in with a different account.</p>
                <Link className="logout-admin" to="/" onClick={(e) => Logout(e)}> Continue Logout
                </Link>
            </section>

        </div>
    );
}
export default connect()(NotAuthorised);

import { CompanySiteDetails } from "../Constants/CompanySite"

export const getcompanysitedata = (companysitetypes) => {
    return {
        type: CompanySiteDetails.GET_COMPANYWITHSITES,
        payload: companysitetypes
    };
};

export const postcompanysitedata = (companysitetypes) => {
    return {
        type: CompanySiteDetails.POST_COMPANYWITHSITES,
        payload: companysitetypes
    };
};

export const updatecompanysitedata = (companysitetypes) => {
    return {
        type: CompanySiteDetails.UPDATE_SITESANDCOMPANIES,
        payload: companysitetypes
    };
};

export const deletecompanysitedata = (companysitetypes) => {
    return {
        type: CompanySiteDetails.DELETE_COMPANYWITHSITES,
        payload: companysitetypes
    };
};


import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Footer from '../PageLayout/Footer';
import Header from '../PageLayout/Header';
import * as url from "../../api/uri_helper";
//import Message from 'antd-message';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { Formik, Field, useFormik } from 'formik';
import { Input, Form, FormFeedback } from "reactstrap"
import * as Yup from 'yup';
import EmailIcon from '@mui/icons-material/Email';
import logo from "../../assets/images/authenteklogo.png"
import socialIcon1 from '../../assets/images/social1.svg';
import socialIcon2 from '../../assets/images/social2.svg';
import socialIcon3 from '../../assets/images/social3.svg';
import socialIcon4 from '../../assets/images/social4.svg';
import LocationIcon from '../../assets/images/icon1.svg';
import PhoneIcon from '../../assets/images/icon2.svg';
import SupportIcon from '../../assets/images/icon3.svg';
import Logo from '../../assets/images/authenteklogo.png';
import Loader from "../loader";
import axios from 'axios';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
const TOAST_LIMIT = 1;
const emailRegex = /^\s*(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

const EmailVerfication = () => {
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [error, setError] = useState({
        emailErr: '',
    })
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [isChecked, setisActive] = useState(false)

    const sendEmail = async (email) => {
        setLoading(true)        
        if (email) {
            const response = await axios.get(url.GET_EMAILVERIFICATION + '\\' + email.email,).then(response => {
                if (response.data.success) {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    },);                  
                        history.push({
                            pathname: '/EmailConfirmation',
                            state: { data: response.data.data }
                        });
                    setLoading(false)                   
                }
                else {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    },);
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        };
        setLoading(false)
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
           // email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
            email: email,
        },
        // Alert messages 
        validationSchema: Yup.object({
            email: Yup.string().required('Please Enter Email').matches(
                /^([a-zA-Z0-9+_.-]+)(|([+])([0-9])+)@([a-zA-Z0-9.-]+)\.([a-zA_Z]{2,3}$)/,
                "Please enter valid email "
            )
        }),

        // on form submit / button click
        onSubmit: (values) => {
            // call the api here
            
            // setDisable(true);
            sendEmail(values);
        }
    });

  
    return (
        <div>
            {loading ? <Loader /> : ''}
            {/*<div className="text-center">                 */}
            {/*    <a className="navbar-brand" href="#"> <img src={logo} alt="Logo" /> </a>*/}
            {/*</div>    */}
            <div className="brand-logo text-center">
                <h1 className="logo_str"><b>TEK</b>a<span>Verse</span></h1>
            </div>
            <section className="Email_Verification forgot-page"><Toaster />         
                <div className="container">   
                    <div className="forgot_pass py-5 my-3">
                        <h6 className="mb-3 primary-clr text-center">Email Verification</h6>
                        <div className="card auth-card text-left">

                           
                            <Form className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                <div className=""></div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <div className="input-group">

                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">
                                                        <EmailIcon style={{ color: "gray" }} />
                                                    </span>
                                                </div>
                                                <Input name="email" lassName="form-control" placeholder="Enter Email"
                                                    type="text" maxLength={50} onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur} value={validation.values.email}
                                                    invalid={
                                                        validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="d-flex w-100 align-items-center justify-content-between">
                                            <button className="btn btn-primary">Send</button>
                                            <Link className='font-12' to="/" title="Go to Login Page"> Back to Login </Link>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                        {/*<div className="row">                                                        */}
                        {/*    <div className="col-lg-5 col-md-6">*/}
                           
                        {/*        </div>*/}
                             
                        {/*          <div className="col-lg-7 col-md-6">*/}
                        {/*    <div className="right_contact">*/}
                        {/*        <ul className="contact_list">*/}
                        {/*            <li>*/}
                        {/*                <span className="con_icon"><img src={LocationIcon} width="24" height="24"></img></span>*/}

                        {/*                <span className="con_detail">*/}
                        {/*                    <b>Location</b>*/}
                        {/*                    <span>2155 Chenault Dr. Suite 510 Carrollton,*/}
                        {/*                        TX 75006</span>*/}
                        {/*                </span>*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                <span className="con_icon"><img src={PhoneIcon} width="24" height="24" /></span>*/}
                        {/*                <span className="con_detail">*/}
                        {/*                    <b>Feel free to contact us</b>*/}
                        {/*                    <span>(833) 288-4835</span>*/}
                        {/*                </span>*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                <span className="con_icon"><img src={SupportIcon} width="24" height="24" /></span>*/}
                        {/*                <span className="con_detail">*/}
                        {/*                    <b>Support</b>*/}
                        {/*                    <span>sales@authentek.io</span>*/}
                        {/*                </span>*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*        <ul className="social_icons">*/}
                        {/*            <li><a href="#"><img src={socialIcon1} alt="Social Icon 1" /></a></li>*/}
                        {/*            <li><a href="#"><img src={socialIcon2} alt="Social Icon 2" /></a></li>*/}
                        {/*            */}{/*<li><a href="#"><img src={socialIcon3} alt="Social Icon 3" /></a></li>*/}
                        {/*            */}{/*    <li><a href="https://www.linkedin.com/company/authentek-solutions-llc/" target="_blank"><img src={socialIcon4} alt="Social Icon 4" /></a></li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                  
            
           
            </section>
            <div className="footer-section pb-3 pt-3 w-100"

            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 center-text">&#169; AuthenTEK ({new Date().getFullYear()}) | TEKaVerse &#8482;</div>
                        <div className="col-sm-6 center-text text-right">
                            <div className="footer--logo">Powered by <a href="https://authentek.io/" target="_blank"><img src={Logo}></img> </a></div>
                        </div>
                    </div>
                </div>
            </div>
           {/* <Footer />*/}
        </div>
    );
};

export default connect()(EmailVerfication);

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import { Select } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import axios from "axios";
import * as url from "../../api/uri_helper";
import { fetchProjectDetails, fetchUsersDetails } from "../../Redux/Api/fetch.api";
import { getallprojects } from '../../Redux/Actions/AccessibilityActions';
import { getallusers } from '../../Redux/Actions/UsersActions';
import { addusersandapps } from '../../Redux/Actions/UsersApplicationsActions';
import Table, { TableProps } from "antd/lib/table";
import { Tooltip } from 'antd';
import Layout from '../Layout';

const Access = () => {
    const dispatch = useDispatch(); 
    const [pageData, setPageData] = useState({});
    const [loading, setLoading] = useState(false); 
    const [table, setTable] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [checkedListOfIds, setCheckedListOfIds] = useState([]);
    const [userId, setUserId] = useState()  
    const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
   
    const [total, setTotal] = useState()

    useEffect(() => {
        getallapps();
        getusers();
    }, [])

    const getallapps = () => {    
        setLoading(true)
        fetchProjectDetails()
            .then(response => {               
                dispatch(getallprojects(response.data))
                setTable(response.data.data)     
                setOptions({
                    ...options,
                    pagination: {
                        pageSize: options.pagination.pageSize,
                        current: options.pagination.current,
                        total: response.data.count,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }
                })
            })
       
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const uncheckcheckbox = () => {
        var items = document.getElementsByName('checkname');
        for (var i = 0; i < items.length; i++) {
            if (items[i].type === 'checkbox')
                items[i].checked = false;
        }
    }

    const handlesubmit = async () => {
        setLoading(true)
        if (checkedListOfIds.length >= 1) {
            const data = [];
            checkedListOfIds.map(x => data.push({
                AppId: x,
                UserId: userId
            }))
            const response = await axios.post(url.POST_USERSANDAPPS,
                {
                    "UserApplicationQrs": data
                }).then(response => {
                   
                    dispatch(addusersandapps(response.data))
                    setUserId();
                    if (!response.data.success) // False 
                    {
                       
                        toast.error(response.data.message, {
                            className: "toastSuccess",
                        });
                    }
                    else {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        })
                    }   
                    setLoading(false)
                })          
            setLoading(false)
            setCheckedListOfIds('')
            uncheckcheckbox();            
        }
    }

    const getusers = () => {
        
        fetchUsersDetails()
            .then(response => {
                
                dispatch(getallusers(response.data))
                setAllUsers(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const handleChange = (pagination, sorter) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const handleCheck = (appId) => {
        
        var updatedList = [...checkedListOfIds];
        if (event.target.checked) {
            updatedList = [...checkedListOfIds, appId];
        } else {
            updatedList.splice(checkedListOfIds.indexOf(appId), 1);
        }
        setCheckedListOfIds([...updatedList]);
       
    };

   
    const columns = [
        {
            title: " ",
            dataIndex: "appId",
          
            render: (appId) => (<div className="checkgroup">
                {
                    <input type="checkbox" className="btn-checkbox" name="checkname"

                        onChange={() => { handleCheck(appId) }}
                        key={appId}
                    ></input>
                }
            </div>)
        },

        {
            title: "Project Name",
            dataIndex: "name",
            
        },

        {
            title: "Description",
            dataIndex: "description",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>
        },
    ]

    return (
        <Layout>         
        
                {loading ? <Loader /> : ''}
                <section className="Accessibility_section common">                  
                <Toaster />
                    <div className="container">
                        <div className="form-group wk-select1"> <span className="user-span">Select User :</span>
                        <select className="form-select mb-0 flex-grow-1 filter-select" aria-label=".form-select-lg example"
                           // showSearch
                            style={{ width: 250 }}
                            placeholder="Select User"
                                value={userId}                               
                                key={userId}
                                onChange={(e) => setUserId(e.target.value)}                                                     
                        >
                                <option selected="">Select User </option>
                            {
                                allUsers && allUsers.map((data, k) => (
                                    <option key={data.userId} value={data.userId}>
                                        {data.firstName + " " +data.lastName}
                                    </option>)

                                )}

                        </select>

                        {
                           
                            checkedListOfIds.length >= 1 && userId ?
                                <button className="btn btn-primary" onClick={() => handlesubmit()}>Submit</button> :
                                <button className="btn btn-primary" disabled onClick={() => handlesubmit()}>Submit</button>

                               
                        }
                    </div>
                    
                        <div className="Viewer-table position-relative common-table">
                         <Table
                                 className='table-responsive antd--detail-table1 Viewer-table'
                                     columns={columns}
                                dataSource={table}
                                pagination={options.pagination.total > 10 ? options.pagination : false}
                                onChange={handleChange}
                                paginationTotalRows={total}
                      
                        />
                        </div>
                    </div>
                  
                


                </section>
               
          

        </Layout>

    );



}
export default connect()(Access);
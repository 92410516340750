import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import Layout from '../Layout';
import Loader from "../loader";
import { fetchAllSubCompaniesdata, fetchAllSiteData, fetchCompanySitesById, fetchUserMenudata } from "../../Redux/Api/fetch.api";
import { getallsubcompanydata } from '../../Redux/Actions/CompanyRelationAction';
import { getallsitesdetails } from '../../Redux/Actions/SiteActions';
import { postcompanysitedata, updatecompanysitedata } from '../../Redux/Actions/CompanySiteAction';
import { Select } from "antd";
const Option = Select.Option;
import NotAuthorised from "../Authentication/NotAuthorised";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { Dropdown, Menu, Checkbox } from 'antd';

const SubCompanyAdd = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [allCompaniesData, setAllCompaniesData] = useState([]);
    const [masterCompanyId, setMasterCompanyId] = useState();
    const [subCompanyId, setSubCompanyId] = useState();
    const [isActive, setisActive] = useState(true)
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    const [selectSites, setSelectSites] = useState([]);
    const [site, setSite] = useState([]);
    let history = useHistory();
    const [allSitesData, setAllSitesData] = useState([]);
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    const [userRoleData, setUserRoleData] = useState([]);
    const [checkedListOfSites, setCheckedListOfSites] = useState([]);
   

    let pageButton = "Update"

    if (!currentId) {
        pageButton = "Save"
    }

    useEffect(() => {
        getUserDetails();
        getSubCompanyData();
        getAllSitesData();
    }, [])

    const getUserDetails = () => {
        fetchUserMenudata(loggedUserId)
            .then(response => {
               
                dispatch(getuserroleandmenu(response.data.data))
                setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    useEffect(() => {
        setLoading(true)
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true)
            fetchCompanySitesById(urlData[3]).then(
                response => {
                   
                    setSubCompanyId(response.data.data.companyId)
                    { response.data.data.sites ? setSelectSites(getSite(response.data.data.sites)) : "" }
                    setisActive(response.data.data.isActive)
                    setLoading(false)
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [currentId])

    

    const getSubCompanyData = () => {
        
        fetchAllSubCompaniesdata()
            .then(response => {
                
                dispatch(getallsubcompanydata(response.data))
                setAllCompaniesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getAllSitesData = () => {
        
        fetchAllSiteData()
            .then(response => {
                
                dispatch(getallsitesdetails(response.data))
                setAllSitesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            })
    }

    const getSite = (companysite) => {
        let site = [];
        companysite.forEach(element => {
            let selectedSub = {
                disabled: undefined, key: element.siteId, label: element.siteName,
                value: element.siteId
            };
            site.push(selectedSub);
        });
        return site;
    }

    const handleChangeSite = (selected) => {
        
        const arr1 = []
        selected.map((item, i) => {
            allSitesData.filter(datas => datas.siteName === selected[i].label).map(filter_data => {
                arr1.push(filter_data)
            })
        })
        setSite(arr1)
        setSelectSites(selected)

    }

    const getSiteIds = (selectedSites) => {
        let site = [];
        selectedSites.map(siteItem => {
            return site.push(siteItem.siteId ? siteItem.siteId : siteItem.value)
        })
        return site;
    }

    const addCompanySiteData = async () => {
        
        setLoading(true)
        const formData = new FormData();
        formData.append("CompanyId", subCompanyId);
        getSiteIds(selectSites).forEach((value) => {
            formData.append('Sites[]', value);
        });
        const response_ = await axios({
            method: 'post',
            url: url.POST_COMPANIESSITES,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {
                toast.error(response.data.message, {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(postcompanysitedata(response.data))
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                history.push('/SubCompanyMapping');
                
            }
            setLoading(false)
        })   


    }

    const updateCompanySiteData = async () => {
        
        setLoading(true)
        const sitesToUpdate = getSiteIds(selectSites);
        // const subIdsToUpdate = getSubCompanyIds(subcompany && subcompany.length > 0 ? subcompany : selectSubCompany);
        const formData = new FormData();
        formData.append("CompanyId", subCompanyId);      
        sitesToUpdate.forEach((value) => {
            formData.append('Sites[]', value);
        });
        const response_ = await axios({
            method: 'put',
            url: url.UPDATE_COMPANY_SITES,
            data: formData,
        }).then(response => {
            if (response.data.success == false) {
                toast.error(response.data.message, {
                    className: "toastSuccess",
                });
            }
            else {
                dispatch(updatecompanysitedata(response.data))
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                history.push('/SubCompanyMapping');
                
            }
            setLoading(false)
        })       
    }

    return (
        <div>
            {
                roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="User_section common ">
                        <Toaster />
                        {loading ? <Loader /> : ''}
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">
                                    <Link className="backBTN" to="/Mapping">
                                        <img src={Arrow_leftbrown}></img>
                                        Back
                                    </Link>
                                </h4>

                            </div>

                            <div className="content-area">

                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form-group wk-select">
                                                <label>Sub Company <span className="required-fields"> * </span></label>
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="users" aria-label=".form-select-lg example"
                                                    value={subCompanyId}
                                                    key={subCompanyId}
                                                    onChange={(e) => setSubCompanyId(e.target.value)}
                                                >
                                                    <option value="">Select Sub Company</option>
                                                    {
                                                        allCompaniesData && allCompaniesData.map(company =>
                                                            <option key={company.subCompanyId} value={company.subCompanyId}>
                                                                {company.subCompanyName} </option>
                                                        )
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                            
                                        <div className="col-md-7 select-Role comdropdown">
                                            <label>Sites <span className="required-fields"> * </span></label>
                                                <Select
                                                    className="abc"
                                                placeholder="Select Sites"
                                                mode="multiple"
                                                labelInValue
                                                tokenSeparators={[" ", ","]}
                                                value={selectSites}
                                                    onChange={handleChangeSite}
                                                                                                    
                                            >
                                                    {allSitesData && allSitesData.map(site =>
                                                        subCompanyId ? < Option key={site.siteId} value={site.siteId} >
                                                            {site.siteName}
                                                        </Option> : ""
                                                    )}
                                            </Select>

                                            </div>
                                           

                                        <div className="col-md-12 mt-4">
                                            {subCompanyId && subCompanyId != "Select Sub Company" && selectSites && selectSites.length > 0 ? <button className="btn btn-primary"
                                                onClick={currentId == undefined || currentId == null || currentId == isNaN ? addCompanySiteData : updateCompanySiteData}>{pageButton}</button>
                                                : <button className="btn btn-primary" disabled
                                                    onClick={currentId == undefined || currentId == null || currentId == isNaN ? addCompanySiteData : updateCompanySiteData}>{pageButton}</button>}
                                            <Link className="btn btn-bordered ml-2" to="/SubCompanyMapping">Cancel</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            }
        </div>
    )


}
export default connect()(SubCompanyAdd);


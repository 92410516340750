
import React, { useEffect, useState } from 'react';
import Footerbanner from '../../assets/images/banner.jpeg';
import Contact from "../Dashboard/Contact";
import { connect, useDispatch, useSelector } from 'react-redux';
import '../../../src/custom.css'
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/images/authenteklogo.png';


const Footer = () => {
   
    const allUserTypes = useSelector((state) => state.allUserdata.users);
    const colorcode = allUserTypes.data && allUserTypes.data.colorCodeDetails;
    const footercolor = colorcode && colorcode.footerColorCode;   
    const loggedInUserId = localStorage.getItem('UserId')

	
    return (
        <footer>
            <div className="footer-section pb-3 pt-3 w-100"
                style={{
                    backgroundColor: footercolor,
                }}
            >
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-6 center-text">&#169; AuthenTEK ({new Date().getFullYear()}) | TEKaVerse &#8482;</div>
						<div className="col-sm-6 center-text text-right">
							<div className="footer--logo">Powered by <a href="https://authentek.io/" target="_blank"><img src={Logo}></img> </a></div>
						</div>						
					</div>
				</div>
			</div>
        </footer>
    );
};

export default Footer;

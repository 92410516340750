import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader";
import { Link, useHistory } from 'react-router-dom';
import Layout from '../Layout';
import { fetchUserMenudata } from "../../Redux/Api/fetch.api";
import airtel from "../../assets/images/airtel_image.png";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { savecompaniestheme } from '../../Redux/Actions/CompanyTheme';
import { ColorExtractor } from 'react-color-extractor'
import { Select } from 'antd';
import * as url from "../../api/uri_helper";
import axios from "axios";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Logo from '../../assets/images/authenteklogo.png';

const Themes = () => {
    const loggedInUserId = localStorage.getItem('UserId')
    const dispatch = useDispatch(); 
    const [userCompanyData, setUserCompanyData] = useState([]);
    const [colorCodesData, setColorCodesData] = useState([]);
    const [headerId, setHeaderId] = useState("");
    let history = useHistory();
    const [footerId, setFooterId] = useState("");
    const [bodyId, setBodyId] = useState("");
    const [colors, setColors] = useState(null);
    const [companyLogo, setCompanyLogo] = useState("");
    const [companyId, setCompanyId] = useState();
    const [companyName, setCompanyName] = useState("");
    const [headerError, setHeaderError] = useState(false);
    const { Option } = Select;
    const [loading, setLoading] = useState(false);


    useEffect(() => {       
        getcompanylogobyid();
    }, [])

    const getcompanylogobyid = () => {    
        debugger;
        fetchUserMenudata(loggedInUserId)
            .then(response => {   
                debugger;
                dispatch(getuserroleandmenu(response.data))
                setUserCompanyData(response.data.data.userCompanyDetails);
                setCompanyLogo(response.data.data.userCompanyDetails[0].companyLogo)
                setCompanyName(response.data.data.userCompanyDetails[0].companyName)
                setCompanyId(response.data.data.userCompanyDetails[0].companyId)              
                setColorCodesData(response.data.data.colorCodeDetails)
                setHeaderId(response.data.data.colorCodeDetails.headerColorCode)
                setFooterId(response.data.data.colorCodeDetails.footerColorCode)
                setBodyId(response.data.data.colorCodeDetails.bodyColorCode)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });       
    }

    const addTheme = async () => {
        debugger;
        setLoading(true)
        const formData = new FormData();
        formData.append("CompanyId", companyId);
        formData.append("ThemeName", companyName);
        formData.append("AppliedBy", loggedInUserId);
        formData.append("HeaderColorCode", headerId);
        formData.append("FooterColorCode", footerId);
        formData.append("BodyColorCode", bodyId);
        const response_ = await axios({
            method: 'post',
            url: url.POST_COMPANYTHEME,
            data: formData,
        }).then(response => {
            debugger;
            dispatch(savecompaniestheme(response.data))
            if (response.data.success == false) {
                toast.error(response.data.message, {
                    className: "toastSuccess",
                });
                setLoading(false)
            }
            else {
                dispatch(savecompaniestheme(response.data))
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
                history.push('/Apps');
                setLoading(false)
            }           
        })
    }

    const renderSwatches = () => {      
        if (Array.isArray(colors) && colors?.length > 0) {
            const swatches = colors.map((color, id) => {
                return (
                    <div
                        key={id}
                        style={{
                            backgroundColor: color,
                            width: 40,
                            height: 40
                        }}
                    />
                );
            });
            return swatches;
        }
    };

    const getColors = (detectedColorCodes) => {
        setColors(detectedColorCodes);
    };
  
    return (
        <Layout>
        <div>               
                {loading ? <Loader /> : ''}
            <section className="Themes common">
                    <Toaster />
                    <div className="container">
                        <div className="theme-inner">
                        <div className="company_logo">
                            <label className="form-label mb-0 d-inline-block"> <b>Company Logo :</b></label>
                            <ColorExtractor getColors={getColors}>                               
                                    <img src={companyLogo}></img>
                                </ColorExtractor>
                                <div className="colors_swatches">
                                    {renderSwatches()}
                                </div>
                        </div>
                        
                        <div className="col-lg-12">
                        <div className="row">
                        <div className="col-md-4 mt-4">
                          
                                <label>Header Color </label>
                                        <Select className="form-select mb-0 flex-grow-1" name="roles" aria-label=".form-select-lg example"
                                            showSearch
                                   style={{ width: 300 }}
                                    placeholder="Select Header Color"                                   
                                     key={headerId}
                                            id={headerId}
                                            defaultValue={headerId}
                                            onChange={(e) => setHeaderId(e)}                                           
                                >
                                            <Option value="0">{'Select Header Color'}</Option>
                                {
                                    colors && colors.map((colr,id) =>(
                                        <Option key={id} value={colr}>
                                            <div className="d-flex gap20 align-items-center color-picker">
                                                <div
                                                    key={id}
                                                    style={{
                                                        backgroundColor: colr,
                                                        width: 30,
                                                        height: 30
                                                    }}
                                                />
                                                {colr}
                                            </div>
                                       </Option>
                                        ))
                                    }
                                </Select>                                                          
                                </div>
                                <div className="col-md-4 mt-4">

                                    <label>Footer Color </label>
                                    <Select className="form-select mb-0 flex-grow-1" name="roles" aria-label=".form-select-lg example"
                                        style={{ width: 300 }}
                                        placeholder="Select Footer Color"
                                        defaultValue={footerId}
                                        key={footerId}
                                        onChange={(e) => setFooterId(e)}
                                    >
                                            <Option value="0">{'Select Footer Color'}</Option>
                                        {
                                            colors && colors.map((colr, id) =>(
                                                <Option key={id} value={colr}>
                                                    <div className="d-flex gap20 align-items-center color-picker">
                                                        <div
                                                           key={id}
                                                            style={{
                                                                backgroundColor: colr,
                                                                width: 30,
                                                                height: 30
                                                            }}
                                                        />
                                                        {colr}
                                                    </div>
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </div>

                                <div className="col-md-4 mt-4">
                                    <label>Body Color </label>
                                    <Select className="form-select mb-0 flex-grow-1" name="roles" aria-label=".form-select-lg example"
                                        style={{ width: 300 }}
                                        placeholder="Select Body Color"
                                        defaultValue={bodyId}
                                        key={bodyId}
                                        onChange={(e) => setBodyId(e)}
                                    >
                                            <Option value="0">{'Select Body Color'}</Option>
                                        {
                                            colors && colors.map((colr, id) =>(
                                                <Option key={id} value={colr}>
                                                    <div className="d-flex gap20 align-items-center color-picker">
                                                        <div
                                                            key={id}
                                                            style={{
                                                                backgroundColor: colr,
                                                                width: 30,
                                                                height: 30
                                                            }}
                                                        />
                                                        {colr}
                                                    </div>


                                                </Option>
                                            ))
                                        }
                                    </Select>


                                    </div>
                                    <div className="col-sm-12 text-center mt-3">
                                        {headerId && footerId && bodyId ? <button type="button" className="btn btn-primary mt-3" onClick={() => addTheme()}>Apply Theme</button> :
                                            <button type="button" disabled className="btn btn-primary mt-3" onClick={() => addTheme()}>Apply Theme</button>}
                                    </div>
                            </div>
                        </div>
                        
                        </div>
                </div>
              

            </section>
               
       </div>
        </Layout>
    );




}
export default connect()(Themes);

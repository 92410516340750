import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import Layout from "../Layout";
import { Scheduler } from 'smart-webcomponents-react/scheduler';
import '../../../src/calendar.css';
import { getAllSupportEvents } from "../../Redux/Api/fetch.api";

const SupportCalendar = () => {

    const templateContainerRef = useRef();  // Reference for the template container
    const [events, setEvents] = useState([]);  // State to store event data
    const [resources, setResources] = useState([]);  // State to store resources dynamically

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    // This effect is triggered once on component mount
    useEffect(() => {
        initializeEventScheduler();  // Fetch data and initialize the scheduler

        // Create a custom template for resources (clients)
        const template = document.createElement('template');
        template.id = 'groupTemplate';
        template.innerHTML = `
            <div class="resource-content">
                <div class="avatar"></div>
                <div>
                    <div class="name">{{label}}</div>
                </div>
            </div>`;
        // Append the custom template to the container
        templateContainerRef.current.appendChild(template);
    }, []);

    // Function to fetch data from the API and initialize the scheduler
    const initializeEventScheduler = async () => {
        try {
            const response = await getAllSupportEvents();
            // Set event data from the API response
            setEvents(response.data.data);

            // Retun unique data and order on in signle line 
            const uniqueOrderedData = Array.from(
                new Map(response.data.data.map(item => [item.clientId, item])).values()
            ).sort((a, b) => b.userName.localeCompare(a.userName));

            // Map resource data from the events for the scheduler
            setResources(uniqueOrderedData.map(resource => ({
                label: resource.userName,  // Client's label
                id: resource.clientId   // Client's unique ID
            })));
        } catch (error) {
            console.error("Error fetching support events:", error);  // Log any errors
        }
    };

    // Scheduler settings
    const view = 'month';  // Default view is set to month
    const views = ['day', 'week', 'month'];  // Available views
    const groupTemplate = 'groupTemplate';  // Custom group template for displaying resources
    const firstDayOfWeek = 1;  // Set Monday as the first day of the week
    const groups = ['clientId'];  // Group events by client ID

    return (
        <div>
            <>
                <Layout>
                    <div className="">
                        <div className="mt-4 w-auto EventCalendar">
                            <h4 className="m-0 user-group-heading">Schedule Calendar</h4>
                        </div>

                        <div className="container workitem-container">
                            <div className="Viewer-table index--table position-relative common-table mb-4">
                                <div>
                                    {/* Scheduler component with dynamic data */}
                                    <Scheduler 
                                        disableWindowEditor
                                        disableDrag
                                        disableResize 
                                        disableSelection 
                                        disableAutoScroll 
                                        disableDrop
                                        disableContextMenu
                                        disableEventMenu

                                        id="scheduler"
                                        dataSource={events}  // Events data
                                        view={view}  // Default view
                                        views={views}  // Available views
                                        groupTemplate={groupTemplate}  // Custom template for grouping
                                        firstDayOfWeek={firstDayOfWeek}  // Start week on Monday
                                        groups={groups}  // Group by client ID
                                        resources={[{
                                            value: 'clientId',  // Key for grouping
                                            dataSource: resources,  // Dynamic resources from API
                                            label: 'Client'  // Label for resource column
                                        }]}
                                    ></Scheduler>

                                    {/* Container for the template */}
                                    <div ref={templateContainerRef} id="templateContainer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        </div>
    )
}
export default connect()(SupportCalendar);

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import Edit_light from "../../assets/images/Edit_light.svg";
import { Select } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchAllSites } from "../../Redux/Api/fetch.api";
import axios from "axios";
import * as url from "../../api/uri_helper";
import Table, { TableProps } from "antd/lib/table";
import Trash_light from "../../assets/images/Trash_light.svg";
import { Modal } from 'antd';
import Add from "../../assets/images/Add.svg";
import Layout from '../Layout';
import { Tooltip } from 'antd';
import { getallsites, deletesitesdetails } from '../../Redux/Actions/SiteActions';
import NotAuthorised from "../Authentication/NotAuthorised";
import { fetchUserMenudata, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import { Menu, Dropdown } from 'antd';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import searchIcon from "../../assets/images/SearchIcon.svg";


const SiteIndex = () => {
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [table, setTable] = useState([]);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE);
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['50', '100'],
            showSizeChanger: true,
        },
    });
    //const [pageSize, setPageSize] = useState(10);
    //const [options, setOptions] = useState({ pagination: { current: 1 }, pageSize: pageSize });
    const [totalIndex, setCurrentIndex] = useState()
    const [pageData, setPageData] = useState({});
    const [total, setTotal] = useState()
    let history = useHistory();
    const loggedUserId = localStorage.getItem('UserId')  
    const [roleName, setRoleName] = useState(null);
    const [siteName, setSiteName] = useState("")
    const [resetDisplay, setResetDisplay] = useState(false);
  
   
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        getRoleDetails();
        getallSitesDetails();
    }, []);
    
    const getallSitesDetails = (isReset = false) => {
        setLoading(true)
        fetchAllSites({
            UserId: loggedUserId,
            SiteName: isReset ? "" : siteName,
            
        })
            .then(response => {               
                dispatch(getallsites(response.data))
                setTable(response.data.data)               
                setOptions({
                    ...options,
                    pagination: {
                        ...options.pagination,
                        total: response.data.count,
                    },
                });
                setLoading(false)   
            })
            .catch((err) => {
                console.log("Err: ", err);
            });       
                
    }
   
    const getRoleDetails = () => {      
        if (loggedUserId == null) {
            history.push("/");
        }
        fetchUserRoledata(loggedUserId)
            .then(response => {               
                dispatch(getuserroleandmenu(response.data))               
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
    
    const { confirm } = Modal;
    const handleDelete = (siteId) => {
        confirm({
            title: 'Are you sure you want to delete this record?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteSiteRecord(siteId)
            },
            onCancel() {
            },
        });
    }

    const deleteSiteRecord = async (siteid) => {        
        setLoading(true)
        await axios.delete(url.DELETE_SITETYPE + '/' + siteid, {
        }).then(response => {
            
            dispatch(deletesitesdetails(response.data))
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            toast.success(response.data.message, {
                className: "toastSuccess",
            },);
            getallSitesDetails()

        }).catch((err) => {
            console.log("Err: ", err);
        });
        setLoading(false)

    }
    const handleEdit = (siteId) => {
        history.push('/Site/edit/' + siteId)
    }

    const handleMapComponent = (siteId) => {
        history.push('/Map/' + siteId)
    }

    const SearchControl = async () => {       
        if (siteName !== '') {
            setResetDisplay(true);
        }

        else if (siteName == '' && resetDisplay) {
            setResetDisplay(false);
        }
        getallSitesDetails();
    }

    const handleResetButton = () => {
        setSiteName("")
        getallSitesDetails(true);
        setResetDisplay(false);
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            SearchControl();
        }
    }


    const handleChange = (pagination, sorter, current) => {
        const updatedOptions = { ...options, pagination };
        setOptions(updatedOptions);
        setPageData(updatedOptions)
    };

    const bindProject = (userRoles) => {
        return userRoles.map(x => x.name).join(", ");
    }

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",

            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },

        },
        {
            title: "Site Id",
            dataIndex: "siteId",

        },

        {
            title: "Site Name",
            dataIndex: "siteName",

        },

        {
            title: "Description",
            dataIndex: "description",
            className: "description",
            render: (description) => <Tooltip title={description}>{description}</Tooltip>,
        },

        {
            title: "Address",
            dataIndex: "address",
            className: "address",
            render: (address) => <Tooltip title={address}>{address}</Tooltip>,
        },

        {
            title: "Reseller Company ",
            dataIndex: "companyName",

        },

        {
            title: "Retention Days",
            dataIndex: "retentionDays",
                      
        },


        {
            title: "Projects",
            dataIndex: "apps",
            render: (data, site) => (
                site.apps ?
                    <div className="ellips-dec projectcol">
                        <Tooltip title={bindProject(site.apps)}>{bindProject(site.apps)}</Tooltip></div>
                    : "NA"
            ),
        },

        {
            title: "Status",
            dataIndex: "isActive",
            render: (data) => (data ? <span>Active</span> : <span>Inactive</span>),

        },

        {
            title: "Companies on Map",
            className: "text-center showMap",
            render: (data) => (
                <Tooltip title="Click to view companies on Google map">
                    <FmdGoodIcon onClick={() => handleMapComponent(data.siteId)} className="map-icon" />
                </Tooltip>
            ),

        },
        {
            title: "Action",
            dataIndex: "",

            render: (data, companyId) => (
                <Dropdown className="action-dropdown"
                    overlay={
                        <Menu>

                            <Menu.Item key="edit" onClick={() => handleEdit(data.siteId)}>
                                Edit
                            </Menu.Item>
                            <Menu.Item key="delete" onClick={() => handleDelete(data.siteId)}>
                                Delete
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                    placement="bottomRight"
                >
                    <a className="ant-dropdown-link d-flex justify-content-between align-items-center" onClick={e => e.preventDefault()}>
                        <span>Actions </span>
                        {/*<DownOutlined style={{ fontSize: '8px' }}*/}
                        {/*/>*/}
                    </a>
                </Dropdown>
            )
        },

        
    ]
    return (
        <div>

            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    {loading ? <Loader /> : ''}
                <Layout>
                    <section className="Company_section common ">
                        <Toaster />
                        <div className="container site-container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">Sites</h4>
                                    <div className="ml-auto d-flex flex-wrap right-section">
                                        <div className='d-flex position-relative searchBox' >
                                            <input type="text" className='form-control searchQR' value={siteName} placeholder='Search By Site Name'
                                                onChange={(e) => {
                                                    setSiteName(e.target.value.trimLeft());

                                                }}
                                                onKeyDown={(e) => handleKeyPress(e)}
                                            >

                                            </input>
                                            {siteName == "" ?
                                                <button onClick={() => SearchControl()} disabled className="searchButton mt-sm-0"> <img src={searchIcon} ></img></button> :
                                                <button onClick={() => SearchControl()} className="searchButton  mt-sm-0"> <img src={searchIcon} ></img></button>
                                            }
                                        </div>
                                        {siteName == "" && !resetDisplay ? "" :
                                            <button className='btn btn-primary d-flex align-items-center btn-new' onClick={() => handleResetButton()} >Reset</button>
                                        }

                                    <Link className="btn btn-primary d-flex align-items-center btn-new" to="/Site/Add"><img src={Add}></img>Add Site</Link>
                                </div>
                            </div>
                            <div className="Viewer-table index--table position-relative common-table mb-4">
                              
                                <Table
                                    className='table-responsive antd--detail-table1'
                                    columns={columns}
                                        dataSource={table}
                                        pagination={options.pagination.total > REACT_APP_DEFAULT_PAGESIZE ? options.pagination : false} 
                                   // pagination={options.pagination.total > 10 && totalIndex > 9 ? options.pagination : false}
                                    onChange={handleChange}
                                    // scroll={{ x: "450px" }}
                                    paginationTotalRows={total}

                                />
                            </div>
                        </div>
                    </section>
                    </Layout >
                </>

                                   
            }
            
        </div>
        
        
    )
}
export default connect()(SiteIndex);


import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import Layout from '../Layout';
import Loader from "../loader";
import { fetchSitesWithAlarms, getAllTodaysEvents } from "../../Redux/Api/fetch.api";
import GetClientIPAddress from "../../api/GetClientIP";
import axios from "axios";
import * as url from "../../api/uri_helper";
import alarm from "../../assets/images/alarm.svg";
import location from "../../assets/images/location.png";
import logicon from "../../assets/images/log-icon.svg";
import RedirectIcon from "../../assets/images/Redirect.svg";
import { Tooltip, Skeleton } from 'antd';
import Table, { TableProps } from "antd/lib/table";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import SummarizeIcon from '@mui/icons-material/Summarize';
import googleMap from "../../assets/images/google-maps.png"
// import Carousel from 'react-bootstrap/Carousel';
import Carousel from 'react-grid-carousel'
import SendIcon from "../../assets/images/send-message.png";
//import ClockIcon from "../../assets/images/clock-icon.svg";
import SupportIcon from "../../assets/images/customer-support.png";
//import Clock from "../../components/Dashboard/Clock.js";
import GroupIcon from '@mui/icons-material/GroupAddRounded';
import RequestIcon from '@mui/icons-material/HubTwoTone';
import loaderSmall from "../../assets/images/ellipse-dots.gif";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const DashboardData = () => {
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const [loading, setLoading] = useState(false);
    const [loadingonRedirect, setloadingonRedirect] = useState(false);
    const loggedUserId = localStorage.getItem('UserId')
    const [alarmsData, setAlarmsData] = useState();
    const [eventsData, setEventsData] = useState();
    const [allAlarmsData, setAllAlarmsData] = useState();
    const [dashboardUrl, setDashboardUrl] = useState()
    const [token, setToken] = useState();
    const [domainUrl, setDomainUrl] = useState()
    const [total, setTotal] = useState()
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE);
    const [options, setOptions] = useState({
        pagination: {
            current: 1,
            pageSize: pageSize,
            pageSizeOptions: ['50', '100'],
            showSizeChanger: true,
        },
    });
    const [totalIndex, setCurrentIndex] = useState()
    const allUserTypes = useSelector((state) => state.allUserdata.users);
    const userRole = allUserTypes.data && allUserTypes.data.userRoleDetails;
    const roleId = userRole && userRole[0].roleId;
    const [IPClientSide, setIPClientSide] = useState("");

    const [supportRequests, setSupportRequests] = useState();
    const [closedRequestCount, setClosedRequestCount] = useState();
    const [openRequestCount, setOpenRequestCount] = useState();
    const [newRequestCount, setNewRequestCount] = useState(0);
    const [totalRequestCount, setTotalRequestCount] = useState(0);
    const [activeSupportGroup, setActiveSupportGroup] = useState(0);

    let history = useHistory();

    useEffect(() => {
        getIPAddress();
        getSitesWithAlarms(); // Call for the first time call
        getAllTodaysEvent(); // Call for get the all events based on today's date

        const interval = setInterval(() => {
            getSitesWithAlarms(); // Call the API every 5 seconds
        }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup the interval when the component unmounts
        return () => {
            console.log('Component unmounted. Clearing interval...');
            clearInterval(interval);
        };

    }, []); // Empty dependency array means this effect runs once on mount

    const getIPAddress = async () => {
        const ip = await GetClientIPAddress();
        setIPClientSide(ip);
    }

    const getAllTodaysEvent = () => {
        setLoading(true)
        getAllTodaysEvents()
            .then(response => {

                if (response.data.success) {
                    setEventsData(response.data.data)

                }
            })
            .catch((err) => {
                console.log("Err: ", err);
                setLoading(false)
            });
    }

    const getSitesWithAlarms = () => {
        setLoading(true)
        fetchSitesWithAlarms({
            UserId: loggedUserId,
        })
            .then(response => {
                if (response.data.success) {
                    setAlarmsData(response.data.data.activeAlarms)
                    setAllAlarmsData(response.data.data.allAlarms)
                    setSupportRequests(response.data.data.supportRequests);
                    setActiveSupportGroup(response.data.data.activeSupportGroup);

                    setClosedRequestCount(response.data.data.supportRequests.closedCount);
                    setNewRequestCount(response.data.data.supportRequests.newRequestCount);
                    setOpenRequestCount(response.data.data.supportRequests.openRequestCount);

                    const totalRequestCount = response.data.data.supportRequests.closedCount + response.data.data.supportRequests.newRequestCount + response.data.data.supportRequests.openRequestCount;
                    setTotalRequestCount(totalRequestCount);
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("Err: ", err);
                setLoading(false)
            });
    }

    const handleSupport = (siteId, companyid) => {
        const queryString = new URLSearchParams({
            siteId: siteId,
            companyId: companyid

        }).toString();
        var win = window.open("/supportlogs?" + queryString, '_blank');
        win.focus();


    }

    const openDashboardUrl = (url, token, companyId, siteId) => {
        if (url != null) {

            const secretKey = '12345';
            const encodeddata = btoa(token)
            const encodedcompany = btoa(companyId)
            const encodedsite = btoa(siteId)
            var encryptedUrl = url + "Access/" + encodeddata + "/" + encodedcompany + "/" + encodedsite;
            var win = window.open(encryptedUrl, '_blank');
            win.focus();
        }

    }

    const getDashboardUrl = async (appid, userid, companyId, siteId) => {
        setloadingonRedirect(true);
        const ip = await GetClientIPAddress();
        await axios.post(url.GETDASHBOARD_URL, {
            AppId: appid,
            UserId: userid,
            CompanyId: companyId,
            SiteId: siteId,
            IPAddress: ip
        })
            .then(response => {
                if (response.data.success) {
                    openDashboardUrl(response.data.data.domainUrl, response.data.data.accessToken, companyId, siteId);
                    setDashboardUrl(response.data.data.dashBoardUrl)
                    setToken(response.data.data.accessToken)
                    setDomainUrl(response.data.data.domainUrl)
                    setloadingonRedirect(false);
                }
                else {
                    setloadingonRedirect(false);
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    },);
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response.data.message, {
                    className: "toastSuccess",
                },);
                setloadingonRedirect(false);
            });
    }

    const showIpMismatchError = () => {
        toast.error("You are not eligible for this site, please coordinate with administration.", {
            className: "toastSuccess",
        });
    }

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "",

            render: (text, record, index) => { setCurrentIndex(`${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}`); return `${(options.pagination.current === undefined ? 0 : options.pagination.current - 1) * 10 + index + 1}` },

        },
        {
            title: "Company",
            dataIndex: "subCompanyLogo",
            render: (text, record) => (<>
                <div className="company-logo bg-color">
                    <img className="logo-maxheight" src={record.subCompanyLogo} ></img>
                </div>
            </>)
        },

        {
            title: "Site",
            dataIndex: "siteName",

        },

        {
            title: "Reseller Company",
            dataIndex: "resellerCompanyLogo",
            render: (text, record) => (<>
                <div className="company-logo bg-color">
                    <img src={record.resellerCompanyLogo} ></img>
                </div>
            </>)
        },

        {
            title: "Alarms",
            render: (text, record) => (<>

                <p className={"alarm-badge mb-0 customAlarms " + (record.alarmsCount > 0 ? 'blink customAlarms' : '')}> <img height={20} width={20} src={alarm}></img> {record.alarmsCount}</p>
            </>)
        },

        {
            title: "Apps",
            dataIndex: "appIds",
            render: (appIds, record) => (<>
                <div className="d-inline-flex gap-3">
                    {appIds.map((appId, index) => (
                        roleId == 1 ?
                            (record.ipAddress == null || record.ipAddress == "") || (record.ipAddress == IPClientSide) ? (
                                <Tooltip title="Click to Redirect">
                                    <button
                                        key={index}
                                        type="button"
                                        style={{ "cursor": "pointer" }}
                                        onClick={() => getDashboardUrl(appId, loggedUserId, record.subCompanyId, record.siteId)}
                                        className={`apps-col px-4 py-2 badge ${appId === 3 ? "badge-primary" : appId === 5 ? "badge-warning" : "badge-success"}`}
                                    >
                                        {appId == 1 ? "TEKaHelp" : appId == 3 ? "TEKaLog" : appId == 5 ? "TEKaGUI" : ""}
                                    </button>
                                </Tooltip>
                            ) : (
                                <button
                                    key={index}
                                    type="button"
                                    style={{ "cursor": "pointer" }}
                                    onClick={() => showIpMismatchError()}
                                    className={`apps-col px-4 py-2 badge  ${appId === 3 ? "badge-primary" : appId === 5 ? "badge-warning" : "badge-success"}`}
                                >
                                    {appId == 1 ? "TEKaHelp" : appId == 3 ? "TEKaLog" : appId == 5 ? "TEKaGUI" : ""}
                                </button>
                            ) :
                            <Tooltip title="Click to Redirect">
                                <button
                                    key={index}
                                    type="button"
                                    style={{ "cursor": "pointer" }}
                                    onClick={() => getDashboardUrl(appId, loggedUserId, record.subCompanyId, record.siteId)}
                                    className={`apps-col px-4 py-2 badge ${appId === 3 ? "badge-primary" : appId === 5 ? "badge-warning" : "badge-success"}`}
                                >
                                    {appId == 1 ? "TEKaHelp" : appId == 3 ? "TEKaLog" : appId == 5 ? "TEKaGUI" : ""}
                                </button>
                            </Tooltip>
                    ))}

                </div>
            </>)
        },

        {
            title: "Assigned",
            dataIndex: "assignedRequestCnt",
            render: (text, record) => (<>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    {record.assignedRequestCnt > 0 ?
                        <p className="alarm-badge mb-0">{record.assignedRequestCnt}</p>
                        : ""
                    }
                </div>
            </>)
        },

        {
            title: "Acknowledged",
            dataIndex: "ackRequestCnt",
            render: (text, record) => (<>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    {record.ackRequestCnt > 0 ?
                        <p className="alarm-badge mb-0">{record.ackRequestCnt}</p>
                        : ""
                    }
                </div>
            </>)
        },

        {
            title: "Working",
            dataIndex: "processingRequestCnt",
            render: (text, record) => (<>
                <div>
                    {record.processingRequestCnt > 0 ?
                        <p className="alarm-badge mb-0">{record.processingRequestCnt}</p>
                        : ""
                    }
                </div>
            </>)
        },

        {
            title: "Closed",
            dataIndex: "resolvedRequestCnt",
            render: (text, record) => (<>
                <div>
                    {record.resolvedRequestCnt > 0 ?
                        <p className="alarm-badge mb-0">{record.resolvedRequestCnt}</p>
                        : ""
                    }
                </div>
            </>)
        },

        {
            title: "Logs",
            render: (text, record) => (<>
                {record.assignedRequestCnt > 0 ?
                    <div className="logs-icon rounded">
                        <Tooltip title="Click to view support logs">
                            <button className=""
                                onClick={() => handleSupport(record.siteId, record.subCompanyId)}
                            >
                                <img class="w-100" src={logicon}
                                    className="apply-img"></img>
                                Logs
                            </button>
                        </Tooltip>
                    </div> : ""
                }
            </>)
        },


        //{
        //    title: "Support Request",
        //    dataIndex: "appSupportDetails",
        //    className:"supportrequest-col text-center",
        //    width: '50%',
        //    render: (appSupportDetails, record) => (

        //        <div>
        //            {appSupportDetails.map((apps, index) => (
        //                <>
        //                    <div id="supportBox">

        //                        <div class="supportBoxAssign">
        //                            <div class="box">
        //                                <h4 className="text-center"><b>{apps.assignedRequestCnt}</b></h4>
        //                                <p className="text-center">Assigned</p>
        //                            </div>
        //                        </div>

        //                        <div class="supportBoxAck">
        //                            <div class="box">
        //                                <h4 className="text-center"><b>{apps.ackRequestCnt}</b></h4>
        //                                <p className="text-center">Acknowledged</p>
        //                            </div>
        //                        </div>

        //                        <div class="supportBoxWorking">
        //                            <div class="box">
        //                                <h4 className="text-center"><b>{apps.processingRequestCnt}</b></h4>
        //                                <p className="text-center">Working</p>
        //                            </div>
        //                        </div>

        //                        <div class="supportBoxClosed">
        //                            <div class="box">
        //                                <h4 className="text-center"><b>{apps.resolvedRequestCnt}</b></h4>
        //                                <p className="text-center">Closed</p>
        //                            </div>
        //                        </div>

        //                        <div className="logs-icon rounded">
        //                            <Tooltip title="Click to view support logs">
        //                            <button className=""
        //                             onClick={() => handleSupport(record.siteId, record.subCompanyId, apps.appID)}
        //                            >
        //                                <img class="w-100" src={logicon}                                        
        //                                    className="apply-img"></img>      
        //                                </button>
        //                            </Tooltip>
        //                        </div>
        //                    </div>
        //                </>
        //            ))}
        //        </div>
        //    )
        //}



    ]

    const handleMapComponent = () => {
        history.push('/AllSites')
    }

    function getInitials(name) {
        if (name) {
            const nameParts = name.split(' ');
            const initials = nameParts.map(part => part.charAt(0));
            const result = initials.join('');
            return result;
        }
    }

    return (
        <Layout>
            <div>
                <section className="Software_Modules_section custom-dashboard-height pb-4">
                    {loadingonRedirect ? <Loader /> : ''}

                    <div className="container-fluid">

                        <div className="Software_Modules_inner padding-45">
                          
                            <div className="active-alarm">
                              
                                <div className="row">
                                    <div className="col-lg-3 active-alarm-slider">
                                        <>
                                            {alarmsData && alarmsData.length > 0 ? (
                                                <>
                                                    <h5 className="m-0 mb-3">All Active Alarms</h5>
                                                    {alarmsData.length > 1 ? (
                                                        <Carousel autoplay={3000} loop>
                                                            {alarmsData.map((item) => (
                                                                <Carousel.Item key={item.subCompanyId}>
                                                                    <div className="soft-module-outer1">
                                                                        <div className="soft-module-inner">
                                                                            <div className="soft-header">
                                                                                <div className="sub-logo">
                                                                                    <img src={item.subCompanyLogo} alt="Sub Company Logo" />
                                                                                </div>
                                                                                <div className="d-flex">
                                                                                    <div className="sub-company-name">
                                                                                        <img src={location} height={16} alt="Location" />
                                                                                        {item.siteName}
                                                                                    </div>
                                                                                    {(item.ipAddress === null || item.ipAddress === "") || (item.ipAddress === IPClientSide) || roleId !== 1 ? (
                                                                                        <div className="ml-auto">
                                                                                            <Tooltip title="Click to Redirect">
                                                                                                <img
                                                                                                    height={16}
                                                                                                    src={RedirectIcon}
                                                                                                    alt="Redirect Icon"
                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                    className="redirect-icon redirect-ico"
                                                                                                    onClick={() => getDashboardUrl(5, loggedUserId, item.subCompanyId, item.siteId)}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                            <div className="soft-content">
                                                                                <div className="soft-border-right">
                                                                                    <div className="soft-alarm">
                                                                                        <img src={alarm} height={70} width={70} alt="Alarm" />
                                                                                    </div>
                                                                                    <div className="soft-alaram-count">
                                                                                        <div>
                                                                                            <h3>{item.alarmsCount}</h3>
                                                                                            <span>Alarms</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="soft-btn">
                                                                                    {roleId === 1 ? (
                                                                                        (item.ipAddress === null || item.ipAddress === "") || (item.ipAddress === IPClientSide) ? (
                                                                                            <Tooltip title="Click to Redirect">
                                                                                                <button
                                                                                                    className="btn btn-primary cat-btn"
                                                                                                    onClick={() => getDashboardUrl(5, loggedUserId, item.subCompanyId, item.siteId)}
                                                                                                >
                                                                                                    TEKaGUI
                                                                                                </button>
                                                                                            </Tooltip>
                                                                                        ) : (
                                                                                            <button className="btn btn-primary cat-btn" disabled>
                                                                                                TEKaGUI
                                                                                            </button>
                                                                                        )
                                                                                    ) : (
                                                                                        <Tooltip title="Click to Redirect">
                                                                                            <button
                                                                                                className="btn btn-primary cat-btn"
                                                                                                onClick={() => getDashboardUrl(5, loggedUserId, item.subCompanyId, item.siteId)}
                                                                                            >
                                                                                                TEKaGUI
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="soft-footer">
                                                                                <div className="flex-content">
                                                                                    <span className="powered-by">Reseller Company</span>
                                                                                    <img className="ml-2" src={item.resellerCompanyLogo} alt="Reseller Company Logo" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Carousel.Item>
                                                            ))}
                                                        </Carousel>
                                                    ) : (
                                                        <div className="soft-module-outer1">
                                                            <div className="soft-module-inner">
                                                                <div className="soft-header">
                                                                    <div className="sub-logo">
                                                                        <img src={alarmsData[0].subCompanyLogo} alt="Sub Company Logo" />
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="sub-company-name">
                                                                            <img src={location} height={16} alt="Location" />
                                                                            {alarmsData[0].siteName}
                                                                        </div>
                                                                        {(alarmsData[0].ipAddress === null || alarmsData[0].ipAddress === "") || (alarmsData[0].ipAddress === IPClientSide) || roleId !== 1 ? (
                                                                            <div className="ml-auto">
                                                                                <Tooltip title="Click to Redirect">
                                                                                    <img
                                                                                        height={16}
                                                                                        src={RedirectIcon}
                                                                                        alt="Redirect Icon"
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className="redirect-icon redirect-ico"
                                                                                        onClick={() => getDashboardUrl(5, loggedUserId, alarmsData[0].subCompanyId, alarmsData[0].siteId)}
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="soft-content">
                                                                    <div className="soft-border-right">
                                                                        <div className="soft-alarm">
                                                                            <img src={alarm} height={70} width={70} alt="Alarm" />
                                                                        </div>
                                                                        <div className="soft-alaram-count">
                                                                            <div>
                                                                                <h3>{alarmsData[0].alarmsCount}</h3>
                                                                                <span>Alarms</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="soft-btn">
                                                                        {roleId === 1 ? (
                                                                            (alarmsData[0].ipAddress === null || alarmsData[0].ipAddress === "") || (alarmsData[0].ipAddress === IPClientSide) ? (
                                                                                <Tooltip title="Click to Redirect">
                                                                                    <button
                                                                                        className="btn btn-primary cat-btn"
                                                                                        onClick={() => getDashboardUrl(5, loggedUserId, alarmsData[0].subCompanyId, alarmsData[0].siteId)}
                                                                                    >
                                                                                        TEKaGUI
                                                                                    </button>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <button className="btn btn-primary cat-btn" disabled>
                                                                                    TEKaGUI
                                                                                </button>
                                                                            )
                                                                        ) : (
                                                                            <Tooltip title="Click to Redirect">
                                                                                <button
                                                                                    className="btn btn-primary cat-btn"
                                                                                    onClick={() => getDashboardUrl(5, loggedUserId, alarmsData[0].subCompanyId, alarmsData[0].siteId)}
                                                                                >
                                                                                    TEKaGUI
                                                                                </button>
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="soft-footer">
                                                                    <div className="flex-content">
                                                                        <span className="powered-by">Reseller Company</span>
                                                                        <img className="ml-2" src={alarmsData[0].resellerCompanyLogo} alt="Reseller Company Logo" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="col-md-6 col-sm-12 no-data text-center">
                                                    <p>No active alarms found</p>
                                                </div>
                                            )}
                                        </>
                                    </div>

                                    <div className="col-lg-6">
                                        <>
                                            <div className="supportRequestcontainer">
                                                <h5 className="m-0 mb-3">Support Requests</h5>
                                              
                                            </div>

                                            <div className="row support-request">
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 soft-module-outer1">
                                                    <div className="box_1">
                                                        <h5> New Requests</h5>
                                                        <div className="d-flex align-items-center">
                                                            <span><img src={SupportIcon} height={70} width={70} alt="Alarm" /></span>
                                                            <div className="w-100 d-flex align-items-center gap-3">
                                                                <div className="content">
                                                                    <h3>{newRequestCount}</h3>
                                                                    <span>Out Of {totalRequestCount}</span>
                                                                </div>
                                                                <Tooltip title="Click to Redirect">
                                                                    <a
                                                                        className="btn btn-primary cat-btn"
                                                                        href="/Support"
                                                                        target="_blank"
                                                                    >
                                                                        <img
                                                                            src={SendIcon}
                                                                            onClick={() => getDashboardUrl(5, loggedUserId, item.subCompanyId, item.siteId)}
                                                                        />
                                                                    </a>

                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 soft-module-outer1">
                                                    <div className="box_1">
                                                        <h5>Open Requests</h5>
                                                        <div className="d-flex align-items-center">
                                                            <span><img src={SupportIcon} height={70} width={70} alt="Alarm" /></span>
                                                            <div className="w-100 d-flex align-items-center gap-3">
                                                                <div className="content">
                                                                    <h3>{openRequestCount}</h3>
                                                                    <span>Out Of {totalRequestCount}</span>
                                                                </div>
                                                                <Tooltip title="Click to Redirect">
                                                                    <a
                                                                        className="btn btn-primary cat-btn"
                                                                        href="/Support"
                                                                        target="_blank"
                                                                    >
                                                                        <img
                                                                            src={SendIcon}
                                                                        />
                                                                    </a>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 soft-module-outer1">
                                                    <div className="box_1">
                                                        <h5>Closed Requests</h5>
                                                        <div className="d-flex align-items-center">
                                                            <span><img src={SupportIcon} height={70} width={70} alt="Alarm" /></span>
                                                            <div className="w-100 d-flex align-items-center gap-3">
                                                                <div className="content">
                                                                    <h3>{closedRequestCount}</h3>
                                                                    <span>Out Of {totalRequestCount}</span>
                                                                </div>
                                                                <Tooltip title="Click to Redirect">
                                                                    <a
                                                                        className="btn btn-primary cat-btn"
                                                                        href="/Support"
                                                                        target="_blank"
                                                                    >
                                                                        <img
                                                                            src={SendIcon}
                                                                        />
                                                                    </a>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        <div className="mt-5">
                                            <>
                                                <h5 className="m-0 mb-3">Active Support Groups
                                                    {roleId == 7 ?
                                                        <Tooltip title="Click to Review Calendar">
                                                            <a
                                                                className="btn btn-primary cat-btn m-1"
                                                                href={`/SupportCalendar`}
                                                                target="_blank"
                                                            >
                                                                <CalendarMonthIcon style={{ fontSize: '20px' }} />
                                                            </a>
                                                        </Tooltip> : ""
                                                    }
                                                </h5>

                                                {activeSupportGroup && Object.keys(activeSupportGroup).length > 0 ? (
                                                    <>
                                                        <div className="row">
                                                            <div className="active-supprot-carosuel carousel-wrapper col-md-12">
                                                                <Carousel cols={3} rows={1} gap={15} autoplay={3000}
                                                                    hideArrow={Object.keys(activeSupportGroup).length <= 3} loop >
                                                                    {Object.entries(activeSupportGroup).map(([group, members]) => {
                                                                        // Filter members to include only those with assignedCount > 0
                                                                        const filteredMembers = members.filter(member => member.assignedCount > 0);

                                                                        return members.length > 0 ? (
                                                                            <Carousel.Item key={group}>
                                                                                <div className="box_1">

                                                                                    <div className="heading d-flex align-items-center justify-content-between">
                                                                                        <h3 className="">{group}</h3>
                                                                                        {roleId == 7 ?
                                                                                            <Tooltip title="Click to open support group">
                                                                                                <a
                                                                                                    className="btn btn-primary cat-btn m-0"

                                                                                                    href={`/CallOrder?group=${encodeURIComponent(group)}`}
                                                                                                    target="_blank"
                                                                                                >
                                                                                                    <GroupIcon fontSize="10px" />
                                                                                                </a>
                                                                                            </Tooltip> : ""
                                                                                        }
                                                                                    </div>

                                                                                    <div className="scroll-box">
                                                                                        {members.map((member, index) => (
                                                                                            <div className="loop-box" key={index}>
                                                                                                <div className="d-flex align-items-center gap-3">
                                                                                                    {/* Conditionally render profile image if available */}
                                                                                                    {member.profile ? (
                                                                                                        <span>
                                                                                                            <img
                                                                                                                src={member.profile}
                                                                                                                alt={`${member.userName} profile`}
                                                                                                            />
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <p className="name-prefix assigned-name  d-inline-block mb-0 mr-2">{getInitials(member.userName)}</p>
                                                                                                    )}
                                                                                                    <div className="d-flex gap-2 w-100 align-items-center">
                                                                                                        <h6 className="m-0 w-100 font-bold">{member.userName}</h6>
                                                                                                        <p className="m-0 count-box">{member.assignedCount}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>

                                                                                </div>
                                                                            </Carousel.Item>
                                                                        ) : null;
                                                                    })}
                                                                </Carousel>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="text-center">
                                                        <p>No Active Support Groups available</p>
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                  
                                    <div className="col-lg-3">
                                       
                                        <div className="supportRequestcontainer">
                                            <h5 className="m-0 mb-3">Today's Event</h5>
                                                {loading ?
                                                    <div className="right-text"> {<img className="supportRequestLoader" src={loaderSmall} />}</div>
                                                    : ''}
                                        </div>

                                                 <div className="active-support">                                          
                                                  <div className="head">
                                                <h4>Active Support Technicians <span>Today</span></h4>
                                            </div>

                                                      <ul>
                                                {eventsData && eventsData.length > 0 ? (
                                                    eventsData.map((event, index) => (
                                                        <li key={index}>
                                                            <div className="img-box">
                                                                {event.profile ? (
                                                                    <span>
                                                                        <img
                                                                            src={event.userProfile}
                                                                            alt={event.userName}
                                                                            className="profile-img"
                                                                        />
                                                                    </span>
                                                                ) : (
                                                                        <p className="name-prefix img-box d-flex align-items-center justify-content-center mr-2">{getInitials(event.userName)}</p>
                                                                )}
                                                            </div>
                                                            <div className="content-box">
                                                                <h5>{event.userName} {/*<span>7 min ago</span>*/}
                                                                </h5>
                                                                <p>{event.groupName}</p>
                                                            </div>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>
                                                        <div className="text-center">
                                                            <p>No active support groups available</p>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                          
                                                 </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <>{allAlarmsData && allAlarmsData.length > 0 ?
                                <h4 className="m-0 mb-4 d-flex justify-content-between gap-2 align-items-center flex-wrap ">All Companies & Sites
                                    <div>
                                        <button className="btn btn-primary" onClick={handleMapComponent}> Site on Google Map <img src={googleMap} className="google-map" /></button>
                                    </div>
                                </h4> : ""
                            }
                                <Toaster />
                                <div>
                                    {
                                        allAlarmsData ? (
                                            allAlarmsData && allAlarmsData.length > 0 ? (
                                                <div className="Viewer-table index--table position-relative common-table mb-4 dashboard-tb">
                                                    <Table
                                                        className='table-responsive antd--detail-table1'
                                                        columns={columns}
                                                        dataSource={allAlarmsData}
                                                        pagination={options.pagination.total > REACT_APP_DEFAULT_PAGESIZE ? options.pagination : false}
                                                        paginationTotalRows={total}
                                                    />
                                                </div>
                                            )
                                                : (<div className="col-md-6 no-data text-center">
                                                    <p>No Companies & Sites found</p>
                                                </div>)
                                        )
                                            : ('')}
                                </div>
                            </>

                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default connect()(DashboardData);
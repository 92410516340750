import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../loader';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as url from "../../api/uri_helper"
import axios from 'axios';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input, Form, FormFeedback } from "reactstrap"
import { useHistory, useParams } from 'react-router';
//import Message from 'antd-message';
import toast, { Toaster } from 'react-hot-toast';
import logo from "../../assets/images/authenteklogo.png"
import socialIcon1 from '../../assets/images/social1.svg';
import socialIcon2 from '../../assets/images/social2.svg';
import socialIcon3 from '../../assets/images/social3.svg';
import socialIcon4 from '../../assets/images/social4.svg';
import LocationIcon from '../../assets/images/icon1.svg';
import PhoneIcon from '../../assets/images/icon2.svg';
import SupportIcon from '../../assets/images/icon3.svg';
import { fetchupdatepassword } from "../../Redux/Api/fetch.api";
import Logo from '../../assets/images/authenteklogo.png';

const ForgotPassword = (data) => {   
   
    const [loading, setLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordTypeCon, setPasswordTypeCon] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const togglePasswordCon = () => {
        if (passwordTypeCon === "password") {
            setPasswordTypeCon("text")
            return;
        }
        setPasswordTypeCon("password")
    }

    let history = useHistory();

    let { emailId } = useParams();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        // initialize of the Values
        initialValues: {
            newPassword: '',
            conPassword: ''
        },
        // Alert messages 
        validationSchema: Yup.object({
            newPassword: Yup.string().required("Please Enter New password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),

            conPassword: Yup.string().required("Please Enter Confirm password").oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        }),

        // on form submit / button click
        onSubmit: (values) => {                    
            onUpdatePassword(values, emailId);
        }
    });

    const onUpdatePassword = async (params, emailId) => {        
          setLoading(true);
       fetchupdatepassword({           
            emailId: emailId,
            newPassword: params.newPassword,
        }).then(response => {            
            if (response.data.success) {
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });              
                    history.push('/');              
                 setLoading(false);               
            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
  
    return (
        <div>
            {loading ? <Loader /> : ''}
            {/*<div className="text-center">*/}
            {/*    <a className="navbar-brand" href="#"> <img src={logo} alt="Logo" /> </a>*/}
            {/*</div>*/}
            <div className="brand-logo text-center">
                <h1 className="logo_str"><b>TEK</b>a<span>Verse</span></h1>
            </div>
            <section className="Email_Verification forgot-page"><Toaster />
                <div className="container">
                    <div className="forgot_pass py-5 my-3">
                        <h6 className="mb-3 primary-clr text-center">Reset Password</h6>
                        <div className="card auth-card text-left">
                         {/*   <h4>Reset Password</h4>*/}
                            <Form className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <div className="input-group">

                                                <Input name="newPassword" className="form-control" placeholder="Enter New Password"
                                                    type={passwordType} maxLength={50} onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur} value={validation.values.newPassword}
                                                    invalid={
                                                        validation.touched.newPassword && validation.errors.newPassword ? true : false
                                                    }
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text user_icon" id="basic-addon2">
                                                        <div onClick={togglePassword}>
                                                            {passwordType === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                        </div>
                                                    </span>
                                                </div>
                                                {validation.touched.newPassword && validation.errors.newPassword ? (
                                                    <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                                                ) : null}

                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <div className="input-group">

                                                <Input name="conPassword" className="form-control" placeholder="Enter Confirm Password"
                                                    type={passwordTypeCon} maxLength={50} onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur} value={validation.values.conPassword}
                                                    invalid={
                                                        validation.touched.conPassword && validation.errors.conPassword ? true : false
                                                    }
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text user_icon" id="basic-addon2">
                                                        <div onClick={togglePasswordCon}>
                                                            {passwordTypeCon === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                        </div>
                                                    </span>
                                                </div>

                                                {validation.touched.conPassword && validation.errors.conPassword ? (
                                                    <FormFeedback type="invalid">{validation.errors.conPassword}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div class="d-flex w-100 align-items-center justify-content-between">
                                            <button class="btn btn-primary" type="submit">Reset Password</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>

                        {/*<div className="row">*/}
                        {/*    <div className="col-lg-5 col-md-6 col-12">*/}
                              
                        {/*    </div>*/}


                            {/*<div className="col-lg-7 col-md-6 col-12">*/}
                            {/*    <div className="right_contact">*/}
                            {/*        <ul className="contact_list">*/}
                            {/*            <li>*/}
                            {/*                <span className="con_icon"><img src={LocationIcon} width="24" height="24"></img></span>*/}

                            {/*                <span className="con_detail">*/}
                            {/*                    <b>Location</b>*/}
                            {/*                    <span>2155 Chenault Dr. Suite 510 Carrollton,*/}
                            {/*                        TX 75006</span>*/}
                            {/*                </span>*/}
                            {/*            </li>*/}
                            {/*            <li>*/}
                            {/*                <span className="con_icon"><img src={PhoneIcon} width="24" height="24" /></span>*/}
                            {/*                <span className="con_detail">*/}
                            {/*                    <b>Feel free to contact us</b>*/}
                            {/*                    <span>(833) 288-4835</span>*/}
                            {/*                </span>*/}
                            {/*            </li>*/}
                            {/*            <li>*/}
                            {/*                <span className="con_icon"><img src={SupportIcon} width="24" height="24" /></span>*/}
                            {/*                <span className="con_detail">*/}
                            {/*                    <b>Support</b>*/}
                            {/*                    <span>sales@authentek.io</span>*/}
                            {/*                </span>*/}
                            {/*            </li>*/}
                            {/*        </ul>*/}
                            {/*        <ul className="social_icons">*/}
                            {/*            <li><a href="#"><img src={socialIcon1} alt="Social Icon 1" /></a></li>*/}
                            {/*            <li><a href="#"><img src={socialIcon2} alt="Social Icon 2" /></a></li>*/}
                            {/*            */}{/*<li><a href="#"><img src={socialIcon3} alt="Social Icon 3" /></a></li>*/}
                            {/*            */}{/*<li><a href="#"><img src={socialIcon4} alt="Social Icon 4" /></a></li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                       {/* </div>*/}
                    </div>
                </div>
            </section>
            <div className="footer-section pb-3 pt-3 w-100"

            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 center-text">&#169; AuthenTEK ({new Date().getFullYear()}) | TEKaVerse &#8482;</div>
                        <div className="col-sm-6 center-text text-right">
                            <div className="footer--logo">Powered by <a href="https://authentek.io/" target="_blank"><img src={Logo}></img> </a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default connect()(ForgotPassword);

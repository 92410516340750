import { SupportDetails } from "../Constants/Support"

export const getallsupportdata = (support) => {
    return {
        type: SupportDetails.GET_SUPPORTDETAILS,
        payload: support
    };
};

export const updateprioritydata = (support) => {
    return {
        type: SupportDetails.UPDATE_PRIORITY,
        payload: support
    };
};

export const updateassigneddetails = (support) => {
    return {
        type: SupportDetails.UPDATE_ASSIGNEDDATA,
        payload: support
    };
};

export const updatestatusdetails = (support) => {
    return {
        type: SupportDetails.UPDATE_STATUSDATA,
        payload: support
    };
};

export const getsupportlogsdata = (support) => {
    return {
        type: SupportDetails.GET_SUPPORTLOGS,
        payload: support
    };
};

export const getcompanysupportdata = (support) => {
    return {
        type: SupportDetails.GET_COMPANYSUPPORTLOGS,
        payload: support
    };
};

export const getsupportreportdata = (support) => {
    return {
        type: SupportDetails.GET_SUPPORTREPORTDATA,
        payload: support
    };
};
import Layout from "../Layout";
import React, { useEffect, useCallback, useMemo, useState, useRef } from "react";
import Loader from "../loader";
import { Link, useHistory, useLocation } from "react-router-dom";
import { red } from "@mui/material/colors";
import DirectionsIcon from "@mui/icons-material/Directions";
import { GoogleMap, useLoadScript, Marker, MarkerF, InfoWindow, } from "@react-google-maps/api";
import BlueMapIcon from "../../assets/images/mapicon.png";
import RedMapIcon from "../../assets/images/redmapicon.png";
import GppBadRoundedIcon from '@mui/icons-material/GppBadRounded';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import Arrow_Backicon from "../../assets/images/backiconmap.png";
import { Tooltip } from 'antd';
import { fetchAllSiteData, fetchCompanyDataBySiteId, fetchSiteById, fetchSitesWithResellerCompany } from "../../Redux/Api/fetch.api";

const SitePointing = () => {
    const { REACT_APP_GOOGLE_KEY } = process.env;
    const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: REACT_APP_GOOGLE_KEY });
    const center = useMemo(() => ({ lat: 38.122705, lng: -85.717826 }), []);
    const [loading, setLoading] = useState(false);
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [selected, setSelected] = useState("off");
    const [siteid, setSiteId] = useState();
    const [allSites, setAllSitesData] = useState([])
    const [markerClick, setMarkerClick] = useState(false);
    const loggedUserId = localStorage.getItem('UserId')

    useEffect(() => {
        getAllSitesData();
    }, [])

    const getAllSitesData = () => {
        setLoading(true)
        fetchSitesWithResellerCompany(loggedUserId)
            .then(response => {
                setAllSitesData(response.data.data);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    
    const handleMarkerClick = (data) => {      
        setMarkerClick(true);
        setSiteId(data)
    }

    const handleInfoClose = (data) => {
        setSelected(null)
        setSiteId()      
    }
  
    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps</div>;
    }

    // const handleMarkerClick = () => {
    //     setSelected({ lat, lng });

    // };

    return (
        <Layout title="Products located on Google Maps">
            <div className="map-product_container">
                {/*<div className="mt-4 w-100 position-relative wk-select">*/}
                {/*    <h4 className="m-0">*/}
                {/*        <Link className="backBTN" to="/Dashboard">*/}
                {/*            <img src={Arrow_leftbrown}></img>*/}
                {/*            Back*/}
                {/*        </Link>*/}
                {/*    </h4>*/}

                {/*</div>*/}

                <GoogleMap
                   // onLoad={handleOnLoad}
                    onClick={() => setSelected(null)}
                    mapContainerStyle={{ width: "100%", height: "80vh" }}                 
                     center={center}
                    zoom={5}
                >
                    <div className="site-name-overlay">
                        <Tooltip title="Click to back">
                        <Link className="backBTNmap" to="/Dashboard">
                            <img src={Arrow_Backicon} alt="Back Icon" />
                            </Link>
                        </Tooltip>
                       
                    </div>
                    {allSites && allSites.map((data, index) => (
                        data.latitude && data.longitude ?
                            <Marker
                                //  key={data.siteId}
                                key={index}
                                position={{ lat: data.latitude, lng: data.longitude }}                               
                                onClick={() => handleMarkerClick(data.siteId)}
                                icon={{
                                    url: data.alarmsCount && data.alarmsCount > 0 ? GppBadRoundedIcon : BlueMapIcon,
                                    scaledSize: new window.google.maps.Size(35, 35) // Adjust the size as needed
                                }}
                            >
                              
                                {siteid == data.siteId && markerClick && (
                                    <InfoWindow
                                        position={{ lat: data.latitude, lng: data.longitude }}
                                        onCloseClick={() => handleInfoClose(data)}
                                    >
                                        <div className="map-detail-hove_card">

                                            <div className="map-detail-hove_card-content">
                                                <div className="map-detail_inner">
                                                    {data.companyLogo && data.companyLogo != "" ?
                                                        <div className="map-companyLogo">
                                                            <img src={data.companyLogo}></img>
                                                        </div> : ""
                                                    }
                                                    <h5 className="mt-0 map-site-name">
                                                        <b>Site: </b>
                                                        <small>{data.siteName}</small>
                                                    </h5>
                                                   
                                                    <div className="map-resellercompany-name ">
                                                        <b>Reseller Company:</b> <small>{data.companyName}</small>
                                                    </div>
                                                    <span className="map-items_address d-block mt-2">
                                                        <b>Site Address: </b>
                                                        <small>{data.address}</small>
                                                    </span>
                                                    {data.alarmsCount > 0 ?
                                                        <h5 className="mt-0 map-site-name mt-1">
                                                            <b>Alarms Count: </b>
                                                            <small style={{ color: 'red' }}>{data.alarmsCount}</small>
                                                        </h5> : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </InfoWindow>
                                )}
                            </Marker> : ''
                    ))}
                </GoogleMap>
            </div>
        </Layout>
    );
};
export default SitePointing;

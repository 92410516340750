import React, { useState, useEffect } from 'react';
import Header from '../../src/components/PageLayout/Header'
import Footer from '../../src/components/PageLayout/Footer'
import { fetchUserMenudata, validateuser } from "../Redux/Api/fetch.api";
import { getuserroleandmenu, validateuserdata } from '../Redux/Actions/UsersActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';

const Layout = ({ children, supportCount }) => {
    const loggedInUserId = localStorage.getItem('UserId')
    const [bodyId, setBodyId] = useState("");
    const [countNewSupportRequest, setcountNewSupportRequest] = useState(0);
    const dispatch = useDispatch();
    let history = useHistory();
    const[stateProfilePicture, setStateProfilePicture]= useState(false)
    
    useEffect(() => {
        getvalidateuser();
        getUserDetails();        
    }, [])

    useEffect(() => {
        if (localStorage.getItem('tekaVerseToken') == null) {
            history.push("/");
        }       
    }, []);

    const getUserDetails = () => {         
        fetchUserMenudata(loggedInUserId)
            .then(response => {              
                dispatch(getuserroleandmenu(response.data))              
                setBodyId(response.data.data.colorCodeDetails.bodyColorCode)
                localStorage.setItem('profilePicture',response.data.data.userRoleDetails[0].profilePicture)
                setStateProfilePicture(true)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
  
    const getvalidateuser = () => {
        validateuser(loggedInUserId)
            .then(response => {                                         
                if (response.data.success == false) {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    });
                    localStorage.removeItem('tekaVerseToken');
                    history.push("/");
                }
                else {
                    dispatch(validateuserdata(response.data))
                    setcountNewSupportRequest(response.data.count);
                }                
            })
            .catch((err) => {
                console.log("Err: ", err);
            });       
    }

    return (
        <React.Fragment>
            <div className="custom">
                <div>
                    {stateProfilePicture ? <Header supportRequestCount={supportCount == null ? countNewSupportRequest : supportCount} /> : ""}
                </div>
                <div>
                    <div className="main_section" style={{
                        backgroundColor: bodyId,
                    }}>
                        <div> {children} </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}
export default Layout;
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from "../loader";
import Layout from "../Layout";
import Table from "antd/lib/table";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { fetchSupportRequestHistory } from "../../Redux/Api/fetch.api";
import { useHistory } from 'react-router-dom'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Tooltip, Collapse, Divider } from 'antd';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import total_count from '../../assets/images/total-count.svg';


const SupportLogsDisplay = () => {
    const { supportId } = useParams();
    const { REACT_APP_DEFAULT_PAGESIZE } = process.env;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(REACT_APP_DEFAULT_PAGESIZE)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [historytable, setHistoryTable] = useState([]);
    const [logCount, setLogCount] = useState(0);
    const history = useHistory();
    useEffect(() => {
        (supportId != undefined)
        {
            getSupportRequestHistory(supportId);
        }
    }, [])

    const getSupportRequestHistory = async (supportId) => {
        setLoading(true)
        fetchSupportRequestHistory({
            SupportId: supportId
        })
            .then(response => {

                setHistoryTable(response.data.data)
                setLogCount(response.data.count)
                setTotalItems(response.data.count);
                setLoading(false)
            })

            .catch((err) => {
                console.log("Err: ", err);
            });
    }

   
    const columns = [

        {
            title: "Sr. No.",
            dataIndex: "",
            render: (value, item, index) => (currentPage - 1) * pageSize + index + 1,
        },

        {
            title: "Company",
            dataIndex: "companyName",
        },

        {
            title: "Site",
            dataIndex: "siteName",
        },

        {
            title: "Requested By",
            dataIndex: "createdBy",
        },

        {
            title: "Priority",
            dataIndex: "priority",
            render: (value, item, index) =>
            (<>
                {item.priority == 0 ?
                    <div>
                        <span>High</span>
                    </div> :
                    item.priority == 1 ?
                        <div>
                            <span>Medium</span>
                        </div> :
                        <div>
                            <span>Low</span>
                        </div>
                }
            </>)
        },

        {
            title: "Assigned To",
            dataIndex: "assignedUserName",
            render: (value, item, index) =>

            (<>
                <div className="d-flex align-items-center name-col-created">
                    {
                        item.profilePicture ?
                            <img src={item.profilePicture} alt="img" className="name-prefix-profile_picture mb-0 mr-2" /> :
                            item.assignedUserName ?
                                <p className="name-prefix assigned-name d-inline-block mb-0 mr-2">{getInitials(item.assignedUserName)}</p> :
                                <p className="name-prefix assigned-name d-inline-block mr-2 mb-0">UA</p>
                    }  <span>{item.assignedUserName}</span>
                </div>
            </>)
        },

        {
            title: "Assigned Date",
            dataIndex: "assignedDate",
           // render: (assignedDate) => getFullDate(assignedDate),
        },

        {
            title: "Status Message",
            dataIndex: "statusMessage",
        },
    ]

    const handleBackRedirection = () => {
        history.goBack();
    }

    function getInitials(name) {
        if (name) {
            const nameParts = name.split(' ');
            const initials = nameParts.map(part => part.charAt(0));
            const result = initials.join('');
            return result;
        }
    }

    const openloadreport = () => {
        getSupportRequestHistory(supportId);
    }

    const handleChangePagination = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };
    return (
        <div>
            <>
                {loading ? <Loader /> : ''}
                <Layout>
                    <div className="p-3">
                        <section className="Company_section common common-table py-3">
                            <Toaster />

                            <>

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="d-flex gap-3">
                                                <button className="backBTN" onClick={handleBackRedirection}>
                                                    <img src={Arrow_leftbrown} alt="moveBackward" />Back
                                                </button>
                                                <div className="load-report ml-auto p-0">
                                                    <label>Load Logs</label>
                                                    <Tooltip title="Click to load logs">
                                                        <RestartAltIcon onClick={() => openloadreport()} style={{ color: '#fff', height: '30px', width: '30px', border: '1px solid #fff', borderRadius: '4px', padding: '4px', cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-4 support_log_head">
                                            <h4 className="m-0">Support Request Log</h4>
                                        </div>
                                        <div className="col-md-4">
                                            <h6 className="m-0 d-flex align-items-center justify-content-center request_id"><BookmarkBorderIcon style={{ marginRight: "3px" }} />Request Id: #{supportId}</h6>

                                        </div>
                                        <div className="col-md-4">
                                            <p className="ml-md-auto mb-0"></p>
                                        </div>

                                    </div>


                                    <label className="text-right w-100 total_count"><span><img src={total_count} /></span>Total Count : {logCount}</label>
                                    <div className="Viewer-table index--table position-relative support-row-tb common-table mb-4">
                                        <Table
                                            className='table-responsive antd--detail-table1 support assgin-table'
                                            columns={columns}
                                            dataSource={historytable}
                                            pagination={{
                                                current: currentPage,
                                                pageSize: pageSize,
                                                total: totalItems,
                                                showSizeChanger: true,
                                                pageSizeOptions: ['10', '50', '100'],
                                            }}
                                            onChange={handleChangePagination}
                                        />
                                    </div>
                                </div>
                            </>

                        </section>
                    </div>
                </Layout>
            </>
        </div>
    )


}

export default connect()(SupportLogsDisplay);


import * as React from 'react';
import Footer from '../PageLayout/Footer';
import Header from '../PageLayout/Header';

function AboutUS() {
    return (
        <section className="AboutUS">
            <Header />
            <div className="container">
                <h1>AboutUs coming soon.</h1>
            </div>
            <Footer />

        </section>
    );
}

export default AboutUS;

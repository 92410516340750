import React, { useState } from 'react';
import { connect } from 'react-redux';
import Footer from '../PageLayout/Footer';
import * as url from "../../api/uri_helper"
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import socialIcon1 from '../../assets/images/social1.svg';
import socialIcon2 from '../../assets/images/social2.svg';
import socialIcon3 from '../../assets/images/social3.svg';
import socialIcon4 from '../../assets/images/social4.svg';
import LocationIcon from '../../assets/images/icon1.svg';
import PhoneIcon from '../../assets/images/icon2.svg';
import SupportIcon from '../../assets/images/icon3.svg';
import { useHistory } from 'react-router';
import Loader from "../loader";
import Logo from '../../assets/images/authenteklogo.png';
// Import static images 
import logo from "../../assets/images/authenteklogo.png"
import SeekPng from "../../assets/images/SeekPng.svg";
import axios from 'axios';

const EmailConfirmation = (data) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false); 
   

    const sendEmail = async (e) => {         
        setLoading(true)   
        const response = await axios.get(url.GET_EMAILVERIFICATION + '\\' + data.location.state.data)
            .then(response => {
                if (response.data.success) {
                    toast.success(response.data.message, {
                        className: "toastSuccess",
                    },);
                    //setTimeout(() => {
                    //    history.push('/');
                    //}, 2000)
                   
                    setLoading(false) 
                   
                }
                else {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    },);
                }
                    
                })
    
            .catch((err) => {
                console.log("Err: ", err);
            });
       // setLoading(false)
    }

    return (
        <div>
            {loading ? <Loader /> : ''}
            {/*<div>*/}
            {/*    <a className="navbar-brand" href="#"> <img src={logo} alt="Logo" /> </a>*/}
            {/*</div>*/}
            <div className="brand-logo text-center">
                <h1 className="logo_str mb-0"><b>TEK</b>a<span>Verse</span></h1>
            </div>
            <section className="Email_Confirmation forgot-page"><Toaster />
                <div className="container">
                    <div className="forgot_pass py-5 my-3">
                        <h6 className="mb-4 primary-clr text-center">Email Confirmation</h6>
                        <div className="card auth-card text-center emailConfirm">

                            <img src={SeekPng} className='emailImg'></img>

                            <h2>Email Confirmation</h2>
                            <p>We have sent email to <a className="mailforgot" href="#">{data.location.state.data}</a> to confirm
                                the validity of our email address. After receiving the email follow
                                the link provided to complete you registration.</p>

                            <div class="resend">
                                <p>If you not got any email <Link className='text-link' onClick={(e) => sendEmail()}> Resend confirmation mail </Link></p>
                            </div>
                        </div>  
                        {/*<div className="row">*/}
                        {/*      <div className="col-lg-5 col-md-6">*/}
                              

                        {/*    </div>*/}

                            {/*<div className="col-lg-7 col-md-6">*/}
                            {/*    <div className="right_contact">*/}
                            {/*        <ul className="contact_list">*/}
                            {/*            <li>*/}
                            {/*                <span className="con_icon"><img src={LocationIcon} width="24" height="24"></img></span>*/}

                            {/*                <span className="con_detail">*/}
                            {/*                    <b>Location</b>*/}
                            {/*                    <span>2155 Chenault Dr. Suite 510 Carrollton,*/}
                            {/*                        TX 75006</span>*/}
                            {/*                </span>*/}
                            {/*            </li>*/}
                            {/*            <li>*/}
                            {/*                <span className="con_icon"><img src={PhoneIcon} width="24" height="24" /></span>*/}
                            {/*                <span className="con_detail">*/}
                            {/*                    <b>Feel free to contact us</b>*/}
                            {/*                    <span>(833) 288-4835</span>*/}
                            {/*                </span>*/}
                            {/*            </li>*/}
                            {/*            <li>*/}
                            {/*                <span className="con_icon"><img src={SupportIcon} width="24" height="24" /></span>*/}
                            {/*                <span className="con_detail">*/}
                            {/*                    <b>Support</b>*/}
                            {/*                    <span>sales@authentek.io</span>*/}
                            {/*                </span>*/}
                            {/*            </li>*/}
                            {/*        </ul>*/}
                            {/*        <ul className="social_icons">*/}
                            {/*            <li><a href="#"><img src={socialIcon1} alt="Social Icon 1" /></a></li>*/}
                            {/*            <li><a href="#"><img src={socialIcon2} alt="Social Icon 2" /></a></li>*/}
                            {/*            */}{/*<li><a href="#"><img src={socialIcon3} alt="Social Icon 3" /></a></li>*/}
                            {/*            */}{/*<li><a href="https://www.linkedin.com/company/authentek-solutions-llc/" target="_blank"><img src={socialIcon4} alt="Social Icon 4" /></a></li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                       {/* </div>*/}
                    </div>
                </div>

               
            </section>
            <div className="footer-section pb-3 pt-3 w-100"

            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 center-text">&#169; AuthenTEK ({new Date().getFullYear()}) | TEKaVerse &#8482;</div>
                        <div className="col-sm-6 center-text text-right">
                            <div className="footer--logo">Powered by <a href="https://authentek.io/" target="_blank"><img src={Logo}></img> </a></div>
                        </div>
                    </div>
                </div>
            </div>
           {/* <Footer />*/}
        </div>
    )

}
export default connect()(EmailConfirmation);


import { combineReducers } from "redux";
import { DashBoardReducers } from './DashBoardReducer';
import { LoginReducers } from './LoginReducer';
import { UsersReducers } from './UsersReducer';



const reducers = combineReducers({
    allDashBoarddata: DashBoardReducers,
    allLogindata: LoginReducers,
    allUserdata: UsersReducers




})

export default reducers;

import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { useDispatch, useSelector } from "react-redux";
import Loader from '../loader';
import Header from '../PageLayout/Header';
import Footer from '../PageLayout/Footer';
import { Input, Form, FormFeedback } from "reactstrap"
import { useFormik } from "formik";
import * as Yup from "yup";
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as url from "../../api/uri_helper"
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { changepassword } from "../../Redux/Api/fetch.api";
import Layout from '../Layout';

const ChangeOldPassword = () => {   
    const [loading, setLoading] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordTypeNew, setPasswordTypeNew] = useState("password");
    const [passwordTypeCon, setPasswordTypeCon] = useState("password");
    let history = useHistory();
    const allLoginTypes = useSelector((state) => state.allLogindata.login);
    const comingemail = allLoginTypes.emailId;
    const comingusername = allLoginTypes.userName; 

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const togglePasswordNew = () => {
        if (passwordTypeNew === "password") {
            setPasswordTypeNew("text")
            return;
        }
        setPasswordTypeNew("password")
    }

    const togglePasswordCon = () => {
        if (passwordTypeCon === "password") {
            setPasswordTypeCon("text")
            return;
        }
        setPasswordTypeCon("password")
    }
    
    const onChangePassword = async (params) => {
            
        if ((params.emailid) && params.currentPassword && params.newPassword) {
            changepassword({
                EmailId: params.emailid,
                CurrentPassword: params.currentPassword,
                NewPassword: params.newPassword,
            })      
                .then(response => {
                 
               // setLoading(false);
                    if (response.data.success) {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        },);             
                       
                          setTimeout(() => {
                            history.push('/Apps');
                        }, 1000)                          
                    }
                else {
                    toast.error(response.data.message, {
                        className: "toastSuccess",
                    },);                  
                }
            })
                .catch((err) => {
                    console.log("Err: ", err);
                });          
        }
    }
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        // initialize of the Values
        initialValues: {
            emailid: comingemail,
           // emailid: localStorage.getItem('emailid') ? localStorage.getItem('emailid') : '',
            currentPassword: '',
            newPassword: '',
            conPassword: ''
        },
        // Alert messages 
        validationSchema: Yup.object({
            currentPassword: Yup.string().required("Please Enter Current password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Incorrect Current Password"
            ),


            newPassword: Yup.string().required("Please Enter New password").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),

            conPassword: Yup.string().required("Please Enter Confirm password").oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        }),

        // on form submit / button click
        onSubmit: (values) => {
            // call the api here
            
            if (values.emailid || values.emailid != "undefined") // get email id from local storage
            {
                onChangePassword(values)
            }
            else {
                // have to get email Id if not stored in local storage or can go for username
                const username = comingusername;
                onChangePassword(values, username)
            }
        }
    });

    return (
       
            <div>
              
                <Layout>                         
                <section className="Change_password common"> 
                    <Toaster />
                    {loading ? <Loader /> : ''}
                    <div className="container">                         
                        <div className="col-sm-12 text-center right-section mx-auto py-4">
                            <div class="card auth-card text-left">
                                <h4>Change Password</h4>
                                <Form className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                    <div className="mt-4"></div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>Current Password</label>
                                                <div className="input-group">
                                                   
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon3">
                                                            <LockIcon style={{ color: "gray" }} />
                                                        </span>
                                                    </div>
                                                    <Input name="currentPassword" className="form-control" placeholder="Enter Current Password"
                                                        type={passwordType} maxLength={50} onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} value={validation.values.currentPassword}
                                                        invalid={
                                                            validation.touched.currentPassword && validation.errors.currentPassword ? true : false
                                                        }
                                                    />

                                                    <div className="input-group-append">
                                                        <span className="input-group-text user_icon" id="basic-addon2">
                                                            <div onClick={togglePassword}>
                                                                {passwordType === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                            </div>
                                                        </span>
                                                    </div>
                                                    {validation.touched.currentPassword && validation.errors.currentPassword ? (
                                                        <FormFeedback type="invalid">{validation.errors.currentPassword}</FormFeedback>
                                                    ) : null}

                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label>New Password</label>
                                                <div className="input-group">
                                                    {/* <span className="input-group-addon"><img src={PassLight}></img></span> */}
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon3">
                                                            <LockIcon style={{ color: "gray" }} />
                                                        </span>
                                                    </div>
                                                    <Input name="newPassword" className="form-control" placeholder="Enter New Password"
                                                        type={passwordTypeNew} maxLength={50} onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} value={validation.values.newPassword}
                                                        invalid={
                                                            validation.touched.newPassword && validation.errors.newPassword ? true : false
                                                        }
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text user_icon" id="basic-addon2">
                                                            <div onClick={togglePasswordNew}>
                                                                {passwordTypeNew === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                            </div>
                                                        </span>
                                                    </div>


                                                    {validation.touched.newPassword && validation.errors.newPassword ? (
                                                        <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                                                    ) : null}


                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label>Confirm Password</label>
                                                <div class="input-group">
                                                    {/* <span className="input-group-addon"><img src={PassLight}></img></span> */}
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon3">
                                                            <LockIcon style={{ color: "gray" }} />
                                                        </span>
                                                    </div>
                                                    <Input name="conPassword" className="form-control" placeholder="Enter Confirm Password"
                                                        type={passwordTypeCon} maxLength={50} onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur} value={validation.values.conPassword}
                                                        invalid={
                                                            validation.touched.conPassword && validation.errors.conPassword ? true : false
                                                        }
                                                    />

                                                    <div className="input-group-append">
                                                        <span className="input-group-text user_icon" id="basic-addon2">
                                                            <div onClick={togglePasswordCon}>
                                                                {passwordTypeCon === "password" ? <VisibilityOffIcon style={{ color: 'gray' }} /> : <VisibilityIcon style={{ color: 'gray' }} />}
                                                            </div>
                                                        </span>
                                                    </div>

                                                    {validation.touched.conPassword && validation.errors.conPassword ? (
                                                        <FormFeedback type="invalid">{validation.errors.conPassword}</FormFeedback>
                                                    ) : null}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                           
                                    <div class="row">
                                        <div class="col-sm-12 mt-4">
                                            <button className="btn btn-primary me-2" type="submit">Save</button>
                                            <Link className="btn btn-bordered ms-2" to="/Apps">Cancel</Link>
                                        </div>
                                    </div>
                                    </Form>
                            </div>
                            </div>
                    </div>
                </section>               
                </Layout>
            </div>       
    )
}

export default connect()(ChangeOldPassword);


import React, { useState } from "react";
import ImageUploader from "react-images-upload";

const UploadImage = (props) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [isActive, setIsActive] = useState(false);
    const [data, setData] = useState()
    const onDrop = (pictureFiles, pictureDataURLs) => {
        setIsActive(!isActive);
        setData(pictureDataURLs);
        const newImagesUploaded = pictureDataURLs.slice(
            props.defaultImages.length
        );
        props.handleChange(newImagesUploaded);
        if (props.mediaTypeSelected) {

            props.setMediaTypeSelected(false)
        } else {

            props.setMediaTypeSelected(true)
        }

    };

    return (
        <ImageUploader
            withIcon={false}
            withLabel={false}
            withPreview={true}
            buttonText={props.buttonText}
            singleImage={props.images}
            // fileSizeError={"File size is too big!"}
            fileTypeError={"This extension is not supported!"}
            onChange={onDrop}
            imgExtension={props.imgExtension}
            maxFileSize={props.maxFileSize}
            defaultImages={props.defaultImages}
        />
    );

}

export default UploadImage;

import { Accessibilitytypes } from "../Constants/Accessibility"

export const getallprojects = (accesstypes) => {
    return {
        type: Accessibilitytypes.GET_ALLPROJECTS,
        payload: accesstypes
    };
};

export const getAllDevs = (accesstypes) => {
    return {
        type: Accessibilitytypes.GET_ALLDEVELOPERS,
        payload: accesstypes
    };
};

export const getallTimeZones = (accesstypes) => {
    return {
        type: Accessibilitytypes.GET_ALLTIMEZONES,
        payload: accesstypes
    };
};

export const getallapps = (accesstypes) => {
    return {
        type: Accessibilitytypes.GET_ALLAPPS,
        payload: accesstypes
    };
};

export const saveallprojects = (accesstypes) => {
    return {
        type: Accessibilitytypes.POST_ALLAPPS,
        payload: accesstypes
    };
};

export const updateprojectdata = (accesstypes) => {
    return {
        type: Accessibilitytypes.UPDATE_APPDATA,
        payload: accesstypes
    };
};
import { getAllUsers } from "../Constants/Users"

export const getallusers = (userstypes) => {
    return {
        type: getAllUsers.GET_ALLUSERS,
        payload: userstypes
    };
};

export const getusersandroles = (userstypes) => {
    return {
        type: getAllUsers.GET_USERSWITHROLES,
        payload: userstypes
    };
};

export const saveuserdetails = (userstypes) => {
    return {
        type: getAllUsers.POST_USERDETAILS,
        payload: userstypes
    };
};

export const getuserroleandmenu = (userstypes) => {
    return {
        type: getAllUsers.GET_USERROLEANDMENU,
        payload: userstypes
    };
};

export const updateuserdata = (userstypes) => {
    return {
        type: getAllUsers.UPDATE_USERDETAILS,
        payload: userstypes
    };
};

export const deleteuserdata = (userstypes) => {
    return {
        type: getAllUsers.DELETE_USERDETAILS,
        payload: userstypes
    };
};

export const validateuserdata = (userstypes) => {
    return {
        type: getAllUsers.VALIDATE_USER,
        payload: userstypes
    };
};

export const getuserslistfromnotification = (userstypes) => {
    return {
        type: getAllUsers.GET_USERSFROMNOTIFICATION,
        payload: userstypes
    };
};

export const SupportDetails = {
    GET_SUPPORTDETAILS: "GET_SUPPORTDETAILS",
    UPDATE_PRIORITY: "UPDATE_PRIORITY",
    UPDATE_ASSIGNEDDATA: "UPDATE_ASSIGNEDDATA",
    UPDATE_STATUSDATA: "UPDATE_STATUSDATA",
    GET_SUPPORTLOGS: "GET_SUPPORTLOGS",
    GET_COMPANYSUPPORTLOGS: "GET_COMPANYSUPPORTLOGS",
    GET_SUPPORTREPORTDATA: "GET_SUPPORTREPORTDATA",
    
}

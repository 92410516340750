import { SiteDetails } from "../Constants/Site"

export const getallsites = (sitetypes) => {
    return {
        type: SiteDetails.GET_ALLSITES,
        payload: sitetypes
    };
};

export const getallsitesdetails = (sitetypes) => {
    return {
        type: SiteDetails.GET_ALLSITESDETAILS,
        payload: sitetypes
    };
};
export const getsitesbycompanyid = (sitetypes) => {
    return {
        type: SiteDetails.GET_SITESBYCOMPANYID,
        payload: sitetypes
    };
};
export const postallsites = (sitetypes) => {
    return {
        type: SiteDetails.POST_ALLSITES,
        payload: sitetypes
    };
};

export const deletesitesdetails = (sitetypes) => {
    return {
        type: SiteDetails.DELETE_SITEDETAILS,
        payload: sitetypes
    };
};

export const updatesitetypedata = (sitetypes) => {
    return {
        type: SiteDetails.UPDATE_SITEDETAILS,
        payload: sitetypes
    };
};

export const getallsitesretention = (sitetypes) => {
    return {
        type: SiteDetails.GET_SITERETENTIONDATA,
        payload: sitetypes
    };
};
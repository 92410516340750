import { useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import Layout from '../Layout';
import BugReportIcon from '@mui/icons-material/BugReport';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import TaskIcon from '@mui/icons-material/Task';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { DatePicker } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import QAIcon from "../../assets/images/QAIcon.png";
import DevIcon from "../../assets/images/developerIcon.png";
import MechanicIcon from "../../assets/images/Mechanic.png";
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg"
import ClassicEditor from 'ckeditor5-build-classic-base64-upload';
import Compressor from 'compressorjs';
import SendEmailIcon from "../../assets/images/sendEmailicon.svg"
import * as dayjs from 'dayjs'
import { Link, useHistory, useLocation } from "react-router-dom";
import Edit_light from "../../assets/images/Edit_light.svg";
import { fetchdevComments, postDeveloperComments, fetchWorkItemDetail, fetchAllDevelopers, fetchAllWorkdata,fetchsitebyusercompany,fetchSubCompaniesByUserId, fetchAllSubCompaniesdata, fetchProjectDetails, fetchUpdatedComments, postUpdateItem, fetchUserMenudata } from '../../Redux/Api/fetch.api';
import { getAllDevs, getallprojects } from '../../Redux/Actions/AccessibilityActions';
import { getallsubcompanydata } from '../../Redux/Actions/CompanyRelationAction';
import { getsubcompanybyuserid } from '../../Redux/Actions/UserCompanyAction';
import { event } from 'jquery';
import { updateworkitems, getworkcategory } from '../../Redux/Actions/WorkItemAction';
import Loader from "../loader";
import { getusersitecompanydata } from '../../Redux/Actions/UserCompanySiteAction';

 const EditAssign = () => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [userRole, setUserRole] = useState("Super Admin");
    const [userRoleId, setUserRoleId] = useState();
    const [comment, setComment] = useState("<p></p>")
    const [dueDate, setDueDate] = useState("")
    const [estimateDateTime, setEstimateDateTime] = useState()
     const [workItem, setWorkItem] = useState([]);
     const [companyId, setCompanyId] = useState(0);
    const [systemInfo, setSystemInfo] = useState("<p></p>");
    const [reproSteps, setReproSteps] = useState("<p></p>")
    const [discussion, setDiscussion] = useState("<p></p>")
    const [allComments, setAllComments] = useState([])
    const [priority, setPriority] = useState("");
    const [status, setStatus] = useState("");
    const [project, setProject] = useState("");
    const [assignedName, setAssignedName] = useState("UnAssigned");
    const [intialLetters, setInitialLetter] = useState("UA");
    const [allDevs, setAllDevs] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [assignedBy, setAssignedBy] = useState("");
    const [editCommentTouched, setEditColumnTouched] = useState(false)
    const [commentId, setCommentId] = useState()
    const [createdBy, setCreatedBy] = useState()
    const [assignedId, setAssignedId] = useState()
    const [titleError, setTitleError] = useState(false)
    const [commentCount, setCommentCount] = useState(0);
    const [assignedToId, setAssignedToId] = useState();
    const [title, setTitle] = useState("");
    const [workItemType, setWorkItemType] = useState("");
    const [workTypeId, setWorkTypeId] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [updatedDate, setUpdatedDate] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [completedTime, setCompletedTime] = useState()
     const loggedUserId = localStorage.getItem('UserId')
     const [projectError, setProjectError] = useState(false)
    let history = useHistory();
     const [isEditorDisabled, setIsEditorDisabled] = useState(false);
     const [currentId, setCurrentId] = useState(null);
     const [timeError, setTimeError] = useState(false);
     const [priorityError, setPriorityError] = useState(false)
     const [buttonClicked, setButtonClicked] = useState(false);
     const [allSubCompanies, setAllSubCompanies] = useState([]);
     const [subCompanies, setSubCompanies] = useState([]);
     const [companySiteData, setCompanySiteData] = useState([]);
     const [siteId, setSiteId] = useState(0);
     const [workcategoryId, setWorkCategoryId] = useState(0);
     const [allWorkCategory, setAllWorkCategory] = useState([])
     const [summary, setSummary] = useState("<p></p>")
     const [isChecked, setisChecked] = useState(false)
    
   

     useEffect(() => {           
         getAllDeveloper();
         getallapps();
         getUserDetails();
         getSubCompanyData();
         getSubCompaniesDetails();
         getallworkdata();
     }, [])

     useEffect(() => {
         if (companyId > 0)
         getsitesbyuserandcompany();
     }, [companyId]);

     useEffect(() => {
         document.body.style.overflow = loading ? 'hidden' : 'auto';
         return () => {
             document.body.style.overflow = 'auto';
         };
     }, [loading]);

     const handleMailChange = () => {
         setisChecked(!isChecked)
     }

     useEffect(() => {
         setLoading(true)
         let urlData = location.pathname.split('/');
         setCurrentId(urlData[3])
         if (currentId) {
            
             fetchWorkItemDetail(urlData[3])
                 .then(resp => {
                    
                     setIsActive(resp.data.data?.isActive)
                     setUpdatedDate(resp.data.data?.createdDate)                   
                     setPriority(resp.data.data?.priority);
                     setStatus(resp.data.data?.status);
                     setProjectId(resp.data.data?.projectId);
                     setProject(resp.data.data?.projectName);
                     setCompanyId(resp.data.data?.companyId == null ? "" : resp.data.data?.companyId)
                     setSiteId(resp.data.data?.siteId == null ? "" : resp.data.data?.siteId)
                     setWorkCategoryId(resp.data.data?.workCategoryId == null ? "" : resp.data.data?.workCategoryId)
                     setWorkItem(resp.data.data);
                     setTitle(resp.data.data.workTitle)
                     setDueDate(resp.data.data?.itemDueDate == null ? "" : resp.data.data?.itemDueDate);
                     setCreatedBy(resp.data.data?.assignedById)
                     setAssignedToId(resp.data.data?.assignedToId)
                     if (loggedUserId != resp.data.data?.assignedById) {
                         setIsEditorDisabled(true)
                     }
                     setEstimateDateTime(resp.data.data?.estimatedTime == 0 || resp.data.data?.estimatedTime == null ? "" : resp.data.data?.estimatedTime);
                     setCompletedTime(resp.data.data?.itemCompletedTime == null || resp.data.data?.itemCompletedTime == 0? "" : resp.data.data?.itemCompletedTime);
                     const nameParts = resp.data.data?.assignedTo.split(' ');
                     let initials = '';
                     nameParts.forEach((namePart, index) => {
                         initials += namePart[0];
                         if (index < nameParts.length - 1) {
                             initials += '';
                         }
                     });
                     setInitialLetter(initials);
                   
                     setAssignedBy(resp.data.data?.assignedBy);
                     setSystemInfo(resp.data.data?.systemInfo === null ? '<p></p>' : resp.data.data?.systemInfo);
                     setReproSteps(resp.data.data?.reproduceSteps === null ? '<p></p>' : resp.data.data?.reproduceSteps);
                     setDiscussion(resp.data.data?.description === null ? '<p></p>' : resp.data.data?.description);
                     setSummary(resp.data.data?.versionSummary === null ? '<p></p>' : resp.data.data?.versionSummary);   
                     setAllComments(resp.data.data?.workItemHistory);                    
                     setCommentCount(resp.data.data?.workItemHistory.length)
                     setWorkItemType(resp.data.data?.workType === 0 ? "Bug" : resp.data.data?.workType === 1 ? "Task" : resp.data.data?.workType === 3 ? "Support" :"Test Case");
                     setWorkTypeId(resp.data.data?.workType)
                     setAssignedName(resp.data.data?.assignedTo === "" ? "UnAssigned" : resp.data.data?.assignedTo);                    
                     setInitialLetter(resp.data.data?.assignedTo === "" ? "UA" : getInitials(resp.data.data?.assignedTo));                    
                 })            
             setTimeout(() => {
                 setLoading(false)
             }, 3000)         
         }                 
     }, [currentId])

     const getSubCompanyData = () => {

         fetchAllSubCompaniesdata()
             .then(response => {
                 dispatch(getallsubcompanydata(response.data))
                 setSubCompanies(response.data.data)
             })
             .catch((err) => {
                 console.log("Err: ", err);
             });
     }

     const getallworkdata = () => {
         setLoading(true)
         fetchAllWorkdata()
             .then(response => {
                 dispatch(getworkcategory(response.data))
                 setAllWorkCategory(response.data.data)
             })
             .catch((err) => {
                 console.log("Err: ", err);
             });
         setTimeout(() => {
             setLoading(false)
         }, 3000)
     }

   


     const getSubCompaniesDetails = () => {
         fetchSubCompaniesByUserId(loggedUserId)
             .then(response => {
                 dispatch(getsubcompanybyuserid(response.data))
                 setAllSubCompanies(response.data.data.companyUserdata)
                 if (response.data.data.companyUserdata.length <= 1)
                     setCompanyId(response.data.data.companyUserdata[0].subCompanyId)
             })
             .catch((err) => {
                 console.log("Err: ", err);
             })
     }
    const getUserDetails = () => {
        const loggedUserId = localStorage.getItem('UserId')
        fetchUserMenudata(loggedUserId)
            .then(response => {               
               
                setUserRole(response.data.data.userRoleDetails[0].roleName)
                setUserRoleId(response.data.data.userRoleDetails[0].roleId)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
     }

     const getsitesbyuserandcompany = () => {
        
         setLoading(true)
         fetchsitebyusercompany({
             UserId: loggedUserId,
             CompanyId: companyId
         })
             .then(response => {

                 dispatch(getusersitecompanydata(response.data.data))
                 setCompanySiteData(response.data.data);
                 if (response.data.data.length == 1) {
                     setSiteId(response.data.data[0].siteId)

                 }

                 else if (response.data.data.length == 0) {
                     setSiteId(0)
                 }


             })
             .catch((err) => {
                 console.log("Err: ", err);
             });
         setLoading(false)
     }

    //useEffect(() => {
    //    setInterval(() => {
    //        fetchWorkItemDetail(id)
    //            .then(resp => { setAllComments(resp.data.data?.workItemHistory); })
    //            .catch(err => console.log("Auto fetching : ", err))
    //    }, 240000)
    //}, [])

     
    const getallapps = () => {
        fetchProjectDetails()
            .then(response => {
                dispatch(getallprojects(response.data))
                setAllProjects(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
    const handleStatusChange = (event) => {
        event.preventDefault();
        setStatus(event.target.value);
    }

    const handleUpdate = async (event) => { 
       
        // setLoading(true)
        event.preventDefault();
        if (title === "") {
            setTitleError(true)
        }
        if (project === 0 || project === "") {
            setProjectError(true);
        }
        if (priority === "" || priority === 0) {
            setPriorityError(true);
        }

        const compressImage = (base64Image) => {
            return new Promise((resolve, reject) => {
                // Extract the base64 data (remove the data URL scheme part)
                const base64Data = base64Image.split(',')[1];
                const mimeString = base64Image.split(',')[0].split(':')[1].split(';')[0];

                // Decode base64 string to binary data
                const byteString = atob(base64Data);

                // Create an ArrayBuffer and a Uint8Array to hold the binary data
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const intArray = new Uint8Array(arrayBuffer);

                for (let i = 0; i < byteString.length; i++) {
                    intArray[i] = byteString.charCodeAt(i);
                }

                // Create a Blob from the ArrayBuffer
                const blob = new Blob([arrayBuffer], { type: mimeString });
                
                // Use Compressor.js to compress the Blob
                new Compressor(blob, {                    
                    quality: 0.8,
                    maxWidth: 1024,                    
                    success(result) {                        
                        let reader = new FileReader();                       
                        reader.readAsDataURL(result);                       
                        reader.onloadend = () => {                          
                            resolve(reader.result);
                        };
                    },
                    error(err) {
                        reject(err);
                    },
                });
            });
        };
       
        const formData = new FormData();       
        formData.append("ProjectId", projectId);
        formData.append("WorkTitle", title);
        formData.append("IsActive", isActive);
        formData.append("WorkType", workTypeId);
        formData.append("AssignedTo", assignedToId);
       
       
        const maxSize = 700 * 1024; // 700 KB
        const processImages = async (content) => {
            const regex = /<figure class="image"><img src="data:image\/[^"]+"/g;
            const images = content.match(regex);

            if (images) {
                for (let imageTag of images) {
                    let base64ImageStart = imageTag.indexOf('data:image/');
                    let base64ImageEnd = imageTag.indexOf('"', base64ImageStart);
                    let base64Image = imageTag.substring(base64ImageStart, base64ImageEnd);

                    const base64Size = base64Image.length * (3 / 4) - (base64Image.endsWith('==') ? 2 : base64Image.endsWith('=') ? 1 : 0);
                    if (base64Size > maxSize) {
                        try {
                            let compressedImage = await compressImage(base64Image);
                            content = content.replace(base64Image, compressedImage);
                        } catch (error) {
                            console.error("Image compression failed:", error);
                        }
                    }
                }
            }
            return content;
        };
        let updatedDiscussion = await processImages(discussion);
        let updatedSystemInfo = await processImages(systemInfo);
        let updatedReproSteps = await processImages(reproSteps);

        formData.append("Description", updatedDiscussion);
        formData.append("SystemInfo", updatedSystemInfo);
        formData.append("ReproduceSteps", updatedReproSteps);
        formData.append("VersionSummary", summary);
        formData.append("Priority", priority);
        formData.append("Status", status);
        formData.append("CompanyId", companyId);
        formData.append("SiteId", siteId);
        formData.append("WorkCategoryId", workcategoryId);
        formData.append("ItemDueDate", dueDate === null ? "" : dueDate);
        formData.append("EstimatedTime", estimateDateTime == null ? 0 : estimateDateTime);
        formData.append("ItemCompletedTime", completedTime == null ? 0 : completedTime);
        formData.append("AssignedBy", localStorage.getItem("UserId"));
        formData.append("WorkItemId", id);
        formData.append("CreatedBy", localStorage.getItem("UserId"));
        formData.append("IsActive", isActive);
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().replace(/\.\d{3}Z$/, '');
        formData.append("UpdatedDate", formattedDate)
        if (titleError == false && projectError == false && priorityError == false && timeError == false) {
           setLoading(true)
            postUpdateItem(formData)
                .then(response => {
                   
                    dispatch(updateworkitems(response.data))
                    if (!response.data.success) // False 
                    {
                        toast.error(response.data.message, {
                            className: "toastSuccess",
                        },);
                        setLoading(false);
                    }
                    else {
                        toast.success(response.data.message, {
                            className: "toastSuccess",
                        },);
                       
                            history.push('/Workitem');
                      

                        setTimeout(() => {
                            setLoading(false)
                        }, 1000)
                        setEditColumnTouched(false)
                    }

                })
        }
       
    }

    function handleProjectChange(event) {
        // event.preventDefault();
        const selectedOption = event.target.selectedOptions[0];
        const projectIdSelected = selectedOption.getAttribute('id');
        setProject(event.target.value);
        setProjectId(projectIdSelected);
    }

    const handlePriority = (event) => {
        event.preventDefault();
        setPriority(event.target.value)
    }

     const handleDevelopersComment = async (event) => {
         setButtonClicked(true);
        event.preventDefault();
        if (editCommentTouched) {
            //Update Comment
            const formData = new FormData();
            formData.append("Id", commentId);
            formData.append("WorkItemId", id);
            formData.append("DeveloperComments", comment);
            formData.append("CreatedBy", localStorage.getItem("UserId"));
            formData.append("Checked", isChecked);
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().replace(/\.\d{3}Z$/, '');
            formData.append("UpdatedDate", formattedDate)
           await  fetchUpdatedComments(formData)
                .then(resp => {
                 
                    fetchWorkItemDetail(id)
                        .then(resp => {
                            
                         
                            setWorkItem(resp.data.data);
                            setDueDate(resp.data.data?.itemDueDate == null ? "" : resp.data.data?.itemDueDate);
                            setEstimateDateTime(resp.data.data?.estimatedTime == 0 || resp.data.data?.estimatedTime == null ? "" : resp.data.data?.estimatedTime);
                            setSystemInfo(resp.data.data?.systemInfo === null ? '<p></p>' : resp.data.data?.systemInfo);
                            setReproSteps(resp.data.data?.reproduceSteps === null ? '<p></p>' : resp.data.data?.reproduceSteps);
                            setDiscussion(resp.data.data?.description === null ? '<p></p>' : resp.data.data?.description);
                            setAllComments(resp.data.data?.workItemHistory);
                            setPriority(resp.data.data?.priority);
                            setStatus(resp.data.data?.status);
                            setProject(resp.data.data?.projectName);
                         
                            setCompletedTime(resp.data.data?.itemCompletedTime == null || resp.data.data?.itemCompletedTime == 0 ? "" : resp.data.data?.itemCompletedTime);
                        })
                        .catch(err => console.log("Err : ", err))
                    setComment("<p></p>")
                    setisChecked(false)
                })
                .catch(err => console.log("Err : ", err))
            setComment("<p></p>")
            setisChecked(false)
            setEditColumnTouched(false)
         

        }
        else {
            //New Comment
            const formData = new FormData();
            formData.append("WorkItemId", id);
            formData.append("DeveloperComments", comment);
            formData.append("CreatedBy", localStorage.getItem("UserId"));
            const currentDate = new Date();
            formData.append("Checked", isChecked);
            const formattedDate = currentDate.toISOString().replace(/\.\d{3}Z$/, '');
            formData.append("CreatedDate", formattedDate)
            await postDeveloperComments(formData)
                .then(resp => {
                    console.log("Response from Post API : ", resp)
                    fetchWorkItemDetail(id)
                        .then(resp => {
                           
                            console.log("Response from workitem detail", resp)
                            setWorkItem(resp.data.data);
                            setDueDate(resp.data.data?.itemDueDate == null ? "" : resp.data.data?.itemDueDate);
                            setEstimateDateTime(resp.data.data?.estimatedTime == 0 || resp.data.data?.estimatedTime == null ? "" : resp.data.data?.estimatedTime);
                            setSystemInfo(resp.data.data?.systemInfo === null ? '<p></p>' : resp.data.data?.systemInfo);
                            setReproSteps(resp.data.data?.reproduceSteps === null ? '<p></p>' : resp.data.data?.reproduceSteps);
                            setDiscussion(resp.data.data?.description === null ? '<p></p>' : resp.data.data?.description);
                            setAllComments(resp.data.data?.workItemHistory);
                            setCommentCount(resp.data.data?.workItemHistory.length)
                            setPriority(resp.data.data?.priority);
                            setStatus(resp.data.data?.status);
                            setProject(resp.data.data?.projectName);
                            setCompletedTime(resp.data.data?.itemCompletedTime == null || resp.data.data?.itemCompletedTime == 0 ? "" : resp.data.data?.itemCompletedTime);
                        })
                        .catch(err => console.log("Err : ", err))
                    setComment("<p></p>")
                    setisChecked(false)
                })
                .catch(err => console.log("Err : ", err))
            setComment("<p></p>")
            setisChecked(false)
        }
    }

    function getInitials(fullName) {
        const nameParts = fullName.split(' ');
        const initials = nameParts.map(namePart => namePart[0]).join('');
        return initials;
    }

    const handleDateTimeChange = (event) => {
        setEstimateDateTime(event.target.value);
    };

    const disabledDate = (current) => {
        // Disable dates before today (including today)
        return current && current.isBefore(dayjs().startOf('day'));
     };

     const handleTitleInput = (event) => {
         if (event.target.value === "") {
             setTitleError(true)
         }
         else {
             setTitleError(false);
             setTitle(event.target.value)
         }
     }
   
     const handleChange = (event) => {
         event.preventDefault();
         setIsActive(!isActive)
     }
    const getAllDeveloper = () => {
        fetchAllDevelopers()
            .then(response => {
                dispatch(getAllDevs(response.data))
                setAllDevs(response.data.data)
            }).catch((err) => {
                console.log("Err: ", err);
            });
    }

     

    const handleDateChange = (date, dateString) => {
        setDueDate(dateString);
     };

    

     return (
         <>
             {loading ? <Loader /> : ''}
        <Layout>
            <section className="Company_section common ">
                <Toaster />
               
                <div className="container">                                      
                    <div class="mt-4 w-100 position-relative wk-select">                      
                        <h4 class="m-0">
                            <Link class="backBTN" to="/workitem">
                                <img src={Arrow_leftbrown} />  Back
                            </Link>
                        </h4>
                    </div>
                         <h4> Edit {workItemType == "Bug" ? "Report Bug" : workItemType == "Task" ? "Feature Request" : workItemType == "TestCase" ? "Test Case" :  workItemType == "Support"? "Support Request" :"" } : {id}</h4>
                    <div className="content-area">
                        <form>
                                 <p><span>{workItemType == "Bug" ? <BugReportIcon style={{ color: 'red', marginRight: 5 }} fontSize="small" /> : workItemType == "Task" ? <TaskIcon style={{ color: '#fb9502' }} fontSize="small" /> : workItemType == "TestCase" ? PlaylistAddCheckIcon : ""}  Edit {workItemType == "Bug" ? "Report Bug" : workItemType == "Task" ? "Feature Request" : workItemType == "Support" ? "Support Request" : workItemType == "TestCase"? "Test Case" : "" }</span></p>
                            <input placeholder="Enter Title" maxlength="150" disabled={userRole === "Developer"} type="text" class="mb-2 form-control form-control" aria-invalid="false" value={title}

                                onChange={(e) => {
                                    setTitle(e.target.value.trimLeft());
                                    if (e.target.value === "") {
                                        setTittleError(true)
                                    }
                                    else {
                                        setTittleError(false);

                                    }
                                }}
                            />
                            {< small className="text-danger d-block mt-2">{!titleError ? ' ' : 'Please Enter Title'}</small>}
                            <div className="assigned-section d-flex align-items-center mb-3">
                                <div className="assigned-user dropdown mr-4">
                                    <button className="btn text-white dropdown-toggle position-relative" type="button" id="dropdownMenuButton1" disabled={userRole === "Developer"} data-bs-toggle="dropdown" aria-expanded="false">
                                        {assignedName}
                                        <p className="position-absolute name-prefix assigned-name mb-0">{intialLetters}</p>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li className="px-2">
                                            <span className="dropdown-item d-flex p-0" onClick={() => { setAssignedName("UnAssigned"); setAssignedToId(0); setInitialLetter("UA") }}>
                                                <p className="name-prefix-unassigned assigned-name">UA</p>
                                                <div className="name-details ml-2">
                                                    <h6 className="mt-2">UnAssigned</h6>
                                                </div>
                                                {/* <img src={item.roles[0].roleName === "Developer" ? DevIcon : QAIcon} alt="DevIcon" className="DevIcon"></img> */}
                                            </span>
                                        </li>
                                      
                                        {allDevs.map((item, index) => {
                                            const intialLetters = item.firstName[0] + item.lastName[0]
                                            return (
                                                <li className="px-2">
                                                    <span className="dropdown-item d-flex p-0" onClick={() => { setInitialLetter(intialLetters); setAssignedName(item.userName); setAssignedToId(item.userId) }}>
                                                        <p className="name-prefix assigned-name">{intialLetters}</p>
                                                        <div className="name-details ml-2">
                                                            <h6 className="mb-0">{item.userName}</h6>
                                                            <p className="mb-0">{item.emailId}</p>
                                                        </div>
                                                        <img src={item.roleId === 8 ? DevIcon : item.roleId == 3 ? QAIcon : MechanicIcon} alt="DevIcon" className="DevIcon"></img>
                                                    </span>
                                                </li>)
                                        })}
                                    </ul>
                                     </div>
                                     <div className="comment-count">
                                         <QuestionAnswerOutlinedIcon /><span className="ml-2 mr-2">{commentCount} </span>Comments
                                     </div>
                            </div>
                            <div className="more-element-wrap p-3 mb-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="other-aspects">
                                            <ul className="list-inline">
                                                <li className="list-inline-item mr-1">
                                                    <div className="states-drop mr-2">
                                                        <label className="mr-2 d-block mb-0">State </label>
                                                        <select className=""  onChange={(event) => handleStatusChange(event)}>
                                                            <option selected={status === "New"} value={0}>New</option>
                                                            <option selected={status === "Active"} value={1}>Active</option>
                                                            <option selected={status === "Resolved"} value={2}>Resolved</option>
                                                            <option selected={status === "ReOpen"} value={3}>ReOpen</option>
                                                            <option selected={status === "QA"} value={4}>QA</option>
                                                            <option selected={status === "Closed"} value={5}>Closed</option>
                                                        </select>
                                                    </div>
                                                    <div style={{ height: 8, textDecoration: 'none' }}></div>
                                                     </li>

                                                     {allSubCompanies && allSubCompanies.length >= 1 && subCompanies && subCompanies.length >= 1 ?
                                                         <li className="list-inline-item mr-1">
                                                             <div className="states-drop mr-2">
                                                                 <label className="mr-2 mb-0 d-block"><div>Company</div></label>
                                                                 <select className="" name="users" aria-label=".form-select-lg example"
                                                                     value={companyId}
                                                                     key={companyId}
                                                                     onChange={(e) => {
                                                                         setSiteId(0)
                                                                         setCompanyId(e.target.value)
                                                                     }}
                                                                 >
                                                                     <option selected value="0">Select Company</option>
                                                                     {userRole == "User" ?
                                                                         allSubCompanies && allSubCompanies.map(sub =>
                                                                             <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                                 {sub.subCompanyName} </option>
                                                                         ) :
                                                                         subCompanies && subCompanies.map(sub =>
                                                                             <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                                 {sub.subCompanyName} </option>
                                                                         )
                                                                     }
                                                                 </select>
                                                             </div>

                                                         </li> :

                                                         <li className="list-inline-item mr-1">
                                                             <div className="states-drop mr-2">
                                                                 <label className="mr-2 mb-0 d-block">Company</label>
                                                                 <select className="" name="users" aria-label=".form-select-lg example"
                                                                     value={companyId}
                                                                     key={companyId}
                                                                     disabled
                                                                     onChange={(e) => {
                                                                         setSiteId(0)
                                                                         setCompanyId(e.target.value)
                                                                     }}
                                                                 >
                                                                     <option selected value="0">Select Company</option>
                                                                     {userRole == "User" ?
                                                                         allSubCompanies && allSubCompanies.map(sub =>
                                                                             <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                                 {sub.subCompanyName} </option>
                                                                         ) :
                                                                         subCompanies && subCompanies.map(sub =>
                                                                             <option key={sub.subCompanyId} value={sub.subCompanyId}>
                                                                                 {sub.subCompanyName} </option>
                                                                         )
                                                                     }
                                                                 </select>
                                                             </div>

                                                         </li>

                                                     }

                                                     {companyId && companyId != "0"  ?

                                                         <li className="list-inline-item mr-1">
                                                             <div className="states-drop mr-2">
                                                                 <label className="mr-2 mb-0 d-block"><div>Site</div></label>
                                                                 <select className="" name="users" aria-label=".form-select-lg example"
                                                                     value={siteId}
                                                                     key={siteId}
                                                                     onChange={(e) => {

                                                                         setSiteId(e.target.value)

                                                                     }}
                                                                 >
                                                                     <option value="">Select Site</option>
                                                                     {
                                                                         companySiteData && companySiteData.map(sub =>

                                                                             <option key={sub.siteId} value={sub.siteId}>
                                                                                 {sub.siteName} </option>
                                                                         )
                                                                     }
                                                                 </select>
                                                             </div>

                                                         </li> :

                                                         <li className="list-inline-item mr-1">
                                                             <div className="states-drop mr-2">
                                                                 <label className="mr-2 mb-0 d-block"><div>Site</div></label>
                                                                 <select className="" name="users" aria-label=".form-select-lg example"
                                                                     value={siteId}
                                                                     key={siteId}
                                                                     disabled
                                                                     onChange={(e) => {

                                                                         setSiteId(e.target.value)

                                                                     }}
                                                                 >
                                                                     <option value="">Select Site</option>
                                                                     {
                                                                         companySiteData && companySiteData.map(sub =>

                                                                             <option key={sub.siteId} value={sub.siteId}>
                                                                                 {sub.siteName} </option>
                                                                         )
                                                                     }
                                                                 </select>
                                                             </div>

                                                         </li>
                                                     }
                                                     <li className="list-inline-item mr-1">
                                                         <div className="states-drop mr-2">
                                                             <label className="mr-2 mb-0 d-block"><div>Category</div></label>
                                                             <select className="" disabled={userRole === "Developer"} name="users" aria-label=".form-select-lg example"
                                                                 value={workcategoryId}
                                                                 key={workcategoryId}
                                                                 onChange={(e) => {

                                                                     setWorkCategoryId(e.target.value)

                                                                 }}
                                                             >
                                                                 <option value="" disabled>Select Category</option>
                                                                 {
                                                                     allWorkCategory && allWorkCategory.map(sub =>

                                                                         <option key={sub.workCategoryId} value={sub.workCategoryId}>
                                                                             {sub.workDescription} </option>
                                                                     )
                                                                 }
                                                             </select>
                                                         </div>

                                                     </li>
                                                <li className="list-inline-item">
                                                    <div className="states-drop mr-2">
                                                        <label className="mr-2 mb-0 d-block">Project</label>
                                                        <select className="" disabled={userRole === "Developer"}
                                                            onChange={(event) => handleProjectChange(event)}
                                                        >
                                                                 {allProjects && allProjects.map(item =>
                                                                    
                                                                     <option selected={project === `${item.name}`} id={item.appId} value={item.name}>{item.name}
                                                                     </option> 
                                                                 )}
                                                        </select>
                                                    </div>
                                                    {< small className="text-danger d-block mt-1 ml-5 pl-3">{!projectError ? ' ' : 'Please Select Project'}</small>}
                                                </li>
                                            </ul>
                                            <div className="last-update">
                                                <small className="mb-0 d-block">Created By:
                                                    <span className="border-right pr-2 mr-2"> {assignedBy} </span>
                                                    {new Date(updatedDate).toLocaleString('en-US', {
                                                        timeZone: 'America/New_York',
                                                        day: 'numeric',
                                                        month: 'short',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        hour12: true
                                                    })}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="Form-body">
                                <div className="row">
                                    <div className="col-md-8">
                                    {workItemType && workItemType == "Bug" && (
                                        <div className="repolink-wrap mb-3" >
                                        {userRole == "User" && loggedUserId != createdBy ? <div className="repolink-wrap mb-3">
                                            <h5>Reproduce Steps</h5>
                                            <div dangerouslySetInnerHTML={{ __html: reproSteps }}></div>
                                        </div>
                                            :
                                           
                                            <div className="repolink-wrap mb-3" >
                                                <h5>Reproduce Steps </h5>
                                                <div style={{ color: 'gray' }}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={reproSteps}
                                                        style={{ color: 'gray' }}                                                       
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setReproSteps(data);
                                                        }}
                                                        config={{ placeholder: "Enter Steps..." }}
                                                    />
                                                </div>
                                                </div> 

                                             
                                                
                                            }
                                        </div>
                                    )}
                                             <div className="repolink-wrap mb-3" >
                                        {userRole == "User" && loggedUserId != createdBy ? <div className="sysinfo-wrap mb-3">
                                            <h5>Description</h5>
                                            <div dangerouslySetInnerHTML={{ __html: systemInfo }}></div>
                                        </div>
                                            :
                                                     <div className="sysinfo-wrap mb-3">
                                                <h5 >Description</h5>
                                                <div style={{ color: 'gray' }}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={systemInfo}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setSystemInfo(data)
                                                            }}
                                                            config={{ placeholder: "Enter Description..." }}
                                                    />
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <div className="repolink-wrap mb-3" >
                                            {userRole == "User" && loggedUserId != createdBy ? <div className="sysinfo-wrap mb-3">
                                                <h5>Discussion</h5>
                                                <div dangerouslySetInnerHTML={{ __html: discussion }}></div>
                                            </div>
                                                :
                                                <div>
                                                    <h5>Discussion</h5>
                                                    <div style={{ color: 'gray' }}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={discussion}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setDiscussion(data)
                                                                 }}
                                                               //  config={editorConfig}
                                                            config={{ placeholder: "Enter Discussion..." }}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                             </div>

                                             <div className="repolink-wrap mb-3" >
                                                 <h5>Version Summary</h5>
                                                 <div style={{ color: 'gray' }}>
                                                     <CKEditor
                                                         editor={ClassicEditor}
                                                         data={summary}
                                                         onChange={(event, editor) => {
                                                             const data = editor.getData();
                                                             setSummary(data);
                                                         }}
                                                         config={{
                                                             placeholder: 'Enter Summary...',
                                                             toolbar: {
                                                                 items: [
                                                                     'heading',
                                                                     '|',
                                                                     'bold',
                                                                     'italic',
                                                                     '|',
                                                                     'bulletedList',
                                                                     'numberedList',
                                                                     '|',
                                                                     'indent',
                                                                     'outdent',
                                                                     '|',
                                                                     'undo',
                                                                     'redo'
                                                                 ]
                                                             }
                                                         }}
                                                     />
                                                 </div>
                                             </div>
                                      
                                             
                                    </div>
                                    {userRoleId && userRoleId > 6 &&(
                                    <div className="col-md-4">
                                        <div className="planning-main-wrap border rounded p-3 mb-3">
                                            <div className="planning-box mb-5">
                                                <h5>Planning</h5>
                                                <div className="reason-inputs">
                                                    <label className="mr-2 mb-0 d-block">Priority</label>
                                                    <select className="w-100" disabled={userRole === "Developer"}
                                                        onChange={(event) => handlePriority(event)}
                                                    >
                                                        <option selected={priority === "High"} value={0}>High</option>
                                                        <option selected={priority === "Medium"} value={1}>Medium</option>
                                                        <option selected={priority === "Low"} value={2}>Low</option>
                                                    </select>
                                                    {< small className="text-danger d-block mt-2">{!priorityError ? ' ' : 'Please Select Priority'}</small>}
                                                </div>
                                            </div>
                                            <div className="planning-box">
                                                <h5>Effort (Hours)</h5>
                                                <div className="reason-inputs">
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-5">
                                                            <label>Due Date :</label>
                                                        </div>
                                                        <div className="col-lg-7 mb-2">
                                                            <DatePicker
                                                                showTime
                                                                value={dueDate != "" ? dayjs(dueDate) : null}
                                                                size={"small"}
                                                                format="YYYY-MM-DD HH:mm:ss"
                                                                className="dateTime"
                                                                onChange={handleDateChange}
                                                                disabledDate={disabledDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reason-inputs">
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-5">
                                                            <label>Estimate Time :</label>
                                                        </div>
                                                        <div className="col-lg-7 mb-2 ">
                                                           
                                                            <input type="text" className="dateTime"
                                                                value={estimateDateTime}
                                                                maxLength="2"
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric characters
                                                                    const sanitizedValue = newValue.slice(0, 2); // Limit to two characters
                                                                    setEstimateDateTime(sanitizedValue);

                                                                }}
                                                                    //onChange={handleDateTimeChange} 
                                                                    />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="reason-inputs">
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-5">
                                                            <label>Completed Time:</label>
                                                        </div>
                                                        <div className="col-lg-7 mb-2">
                                                            <input type="text" className="dateTime" value={completedTime}
                                                                maxLength="2"
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric characters
                                                                    const sanitizedValue = newValue.slice(0, 2); // Limit to two characters
                                                                    setCompletedTime(sanitizedValue);

                                                                }}
                                                              
                                                            />
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                {completedTime != 0 ?
                                                    <div className="reason-inputs">
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-5">
                                                                <label>
                                                                    {estimateDateTime > completedTime ? "Remaining Time:" : "Extra Time:"}
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-7">
                                                                <label className="mb-0">
                                                                {estimateDateTime != null && completedTime != null ?
                                                                    (estimateDateTime - completedTime >= 0 ? (estimateDateTime - completedTime) + " hrs" :
                                                                        `${(Math.abs(estimateDateTime - completedTime) === 1 ? '1 hr' : `${Math.abs(estimateDateTime - completedTime)} hrs`)}`)
                                                                        : "0 hrs"}
                                                                    </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                         )}
                                    <div className="col-lg-12 col-md-12">
                                             <div className="discuss-wrap" >
                                                 {<div> <input type="checkbox" className="check mr-1"
                                                     checked={isActive} onChange={(event) => handleChange(event)} /> Active </div>}

                                                 {<div className="d-flex mt-2 mb-3">
                                                     <Link className="btn btn-bordered mr-3" to="/Workitem">Cancel</Link>
                                                     <button className="btn btn-primary text-black"
                                                         onClick={(event) => handleUpdate(event)}>Update</button>
                                                 </div>}
                                             </div>
                                         </div>
                                     </div>
                                     <div className="row">
                                         <div className="col-md-8 col-lg-8 col-sm-12">
                                             <div className={`${userRole === "Developer" ? 'mt-4' : null}`} >
                                                 <h5>Comments</h5>
                                                 <div
                                                     style={{ color: 'gray' }}
                                                 >
                                                     <CKEditor
                                                         editor={ClassicEditor}
                                                         data={`${comment}`}
                                                         onChange={(event, editor) => {
                                                             const data = editor.getData();
                                                             setComment(data)
                                                             setButtonClicked(false)
                                                         }}
                                                     />
                                                 </div>
                                                 <div className="row mt-2">
                                                     <div className="col-md-12 mb-6 mr-2">

                                                         <div className="form-checkbox  d-flex align-items-center">


                                                             <label className="m-0">  <input type="checkbox" className="check mr-1" name="isActive" checked={isChecked} onChange={() => handleMailChange()} ></input>
                                                                 Email to Michael</label>
                                                             <img src={SendEmailIcon} className="msg-icon ml-2"></img>                                                         </div>


                                                     </div> </div> 
                                                 <div className="d-flex  mb-3 mt-3" id="version_summary">
                                                     <button className="btn btn-bordered mr-3" onClick={(event) => {
                                                         event.preventDefault();
                                                         setComment("<p></p>");
                                                         setEditColumnTouched(false)
                                                     }}>Cancel</button>
                                                     <button className="btn btn-primary text-black" onClick={(event) => handleDevelopersComment(event)} disabled={buttonClicked || comment === "<p></p>" || comment === ""}>
                                                         {editCommentTouched ? 'Update' : 'Save'}</button>
                                                 </div>
                                                 <div>
                                                     {allComments.length > 0 ? <ul className="discussion-section p-3">
                                                         {allComments ? allComments.map((item, index) => {
                                                             var result;
                                                             const IntialLetters = item.user.firstName[0] + item.user.lastName[0];


                                                             return (
                                                                 <li className={`d-flex border-bottom mb-3 ${editCommentTouched && commentId === item.workItemHistoryId ? 'highlight-comment' : ''}`}>

                                                                     <p className="name-prefix assigned-name  mr-3">{IntialLetters}</p>
                                                                     <div className="discussion-comment ml-2 w-100">
                                                                         <div class="d-flex justify-content-between align-items-center">
                                                                             <h6 className="mb-2">{item.user.firstName} {item.user.lastName} <span className="ml-2">{item.timeAgo}</span></h6>
                                                                             {loggedUserId == item.createdBy ?
                                                                                 <button className="table-btn ml-auto"
                                                                                     onClick={(event) => {
                                                                                         event.preventDefault(),
                                                                                             setEditColumnTouched(true),
                                                                                             setComment(item.developerComments),
                                                                                             setCommentId(item.workItemHistoryId)
                                                                                         setButtonClicked(false);

                                                                                     }}>
                                                                                     <img src={Edit_light}></img>
                                                                                 </button> : null}

                                                                         </div> <div dangerouslySetInnerHTML={{ __html: item.developerComments }}>

                                                                         </div>
                                                                     </div>

                                                                 </li>
                                                             )
                                                         }) : null}
                                                     </ul > : null}
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
             </Layout>
         </>
    )
}
export default connect()(EditAssign);

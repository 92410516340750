import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader";
import { Link, useHistory } from 'react-router-dom';

const NotFound = () => {
    return (
        <div>
            <section className="error-wrap">
                <h1>404</h1>
                <h5>Not found</h5>
                <p>The Resource Requested could not be found on this server!</p>
            </section>
          
        </div>
    );
}
export default connect()(NotFound);

export const getAllUsers = {
    GET_ALLUSERS: "GET_ALLUSERS",
    GET_USERSWITHROLES: "GET_USERSWITHROLES",
    POST_USERDETAILS: "POST_USERDETAILS",
    GET_USERROLEANDMENU: "GET_USERROLEANDMENU",
    UPDATE_USERDETAILS: "UPDATE_USERDETAILS",
    DELETE_USERDETAILS: "DELETE_USERDETAILS",
    VALIDATE_USER: "VALIADTE_USER",
      GET_USERSFROMNOTIFICATION: "GET_USERSFROMNOTIFICATION",
}

import { WorkItemDetails } from "../Constants/WorkItem"

export const getallworkitems = (workitem) => {
    return {
        type: WorkItemDetails.GET_ALLWORKITEMS,
        payload: workitem
    };
};
export const updateworkitems = (workitem) => {
    return {
        type: WorkItemDetails.UPDATE_ALLWORKITEMS,
        payload: workitem
    };
};

export const addworkitems = (workitem) => {
    return {
        type: WorkItemDetails.POST_ALLWORKITEMS,
        payload: workitem
    };
};

export const getworkcategory = (workitem) => {
    return {
        type: WorkItemDetails.GET_ALLWORKCATEGORY,
        payload: workitem
    };
};

export const removeworkitemsearch = (workitem) => {
    return {
        type: WorkItemDetails.REMOVE_WORKITEMSEARCH,
        payload: workitem
    };
};

export const getworkitemsupportdata = (workitem) => {
    return {
        type: WorkItemDetails.GET_WORKITEMSWITHSUPPORT,
        payload: workitem
    };
};
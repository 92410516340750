import { CompanyRelationDetails } from "../Constants/CompanyRelation"

export const getallcompanyrelationdata = (relationtypes) => {
    return {
        type: CompanyRelationDetails.GET_ALLCOMPANYRELATION,
        payload: relationtypes
    };
};

export const postallcompanyrelationdata = (relationtypes) => {
    return {
        type: CompanyRelationDetails.POST_ALLCOMPANYRELATION,
        payload: relationtypes
    };
};

export const updateallcompanyrelationdata = (relationtypes) => {
    return {
        type: CompanyRelationDetails.UPDATE_ALLCOMPANYRELATION,
        payload: relationtypes
    };
};

export const deletecompanyrelationdata = (relationtypes) => {
    return {
        type: CompanyRelationDetails.DELETE_COMPANYRELATION,
        payload: relationtypes
    };
};

export const getallsubcompanydata = (relationtypes) => {
    return {
        type: CompanyRelationDetails.GET_ALLSUBCOMPANIES,
        payload: relationtypes
    };
};

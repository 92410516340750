import { CompanyDetails } from "../Constants/Company"

export const getallcompanies = (company) => {
    return {
        type: CompanyDetails.GET_COMPANYDETAILS,
        payload: company
    };
};

export const getallcompanieswithsites = (company) => {
    return {
        type: CompanyDetails.GET_COMPANYWITHSITES,
        payload: company
    };
};
export const saveallcompanies = (company) => {
    return {
        type: CompanyDetails.POST_COMPANYDETAILS,
        payload: company
    };
};

export const updatecompanydata = (company) => {
    return {
        type: CompanyDetails.UPDATE_COMPANYDETAILS,
        payload: company
    };
};

export const deletecompanydata = (company) => {
    return {
        type: CompanyDetails.DELETE_COMPANYDETAILS,
        payload: company
    };
};

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import Layout from "../Layout";
import Table from "antd/lib/table";
import { Tooltip } from 'antd';
import Loader from "../loader";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { fetchsupportrequestratio, fetchAllSiteData, fetchUserRoledata } from "../../Redux/Api/fetch.api";
import { getsupportreportdata } from '../../Redux/Actions/SupportActions';
import supportIcon from "../../assets/images/support.svg";
import activesupportIcon from "../../assets/images/activesupport.svg";
import acknowledgesupportIcon from "../../assets/images/acknowledgesupport.svg";
import closedIcon from "../../assets/images/closed.svg";
import Chart from 'react-apexcharts';
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';
import NotAuthorised from "../Authentication/NotAuthorised";

const SupportReport = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [supportReportData, setSupportReportData] = useState([]);
    const [requestResolved, setRequestResolved] = useState([]);
    const [totalsupportCount, setTotalSupportCount] = useState();
    const [totalactiveCount, setTotalActiveCount] = useState();
    const [totalacknowledgedCount, setTotalAcknowledgedCount] = useState();
    const [totalclosedCount, setTotalClosedCount] = useState();
    const [chartData, setChartData] = useState([]);
    const [ratioData, setRatioData] = useState([]);
    const [closedratioData, setClosedRatioData] = useState([]);
    const [allSitesData, setAllSitesData] = useState([]);
    const [checkedListOfSites, setCheckedListOfSites] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [searchSite, setSearchSite] = useState('');
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    
   
    useEffect(() => {
      //  getSupportReportDetails();
        getSitesDetails();
        getRoleDetails();
       
    }, []);

    useEffect(() => {              
       
        getSupportReportDetails(false);        
    }, [checkedListOfSites])

    const getSitesDetails = () => {
        fetchAllSiteData()
            .then(response => {                  
                setAllSitesData(response.data.data);               
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    const getRoleDetails = () => {
        fetchUserRoledata(loggedUserId)
            .then(response => {
                dispatch(getuserroleandmenu(response.data))
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }
        

    const handleSiteChange = (e) => {
        setSearchSite(e.target.value);
    };

    const filteredSite = allSitesData.filter(site =>
        site.siteName.toLowerCase().includes(searchSite.toLowerCase())
    );

    const getSupportReportDetails = (isReset = true) => {            
        setLoading(true)
        fetchsupportrequestratio({
            SiteIds: isReset ? [] : checkedListOfSites,
        })       
            .then(response => {                     
                dispatch(getsupportreportdata(response.data))  
                if (response.data.data) {
                    setSupportReportData(response.data.data)
                    setTotalSupportCount(response.data.data.supportRequest.totalSupportRequest)
                    setTotalActiveCount(response.data.data.supportRequest.totalWorking)
                    setTotalAcknowledgedCount(response.data.data.supportRequest.totalAcknowledged)
                    setTotalClosedCount(response.data.data.supportRequest.totalClosed)
                    setRequestResolved(response.data.data.resolvedByTechnicians)
                    const technicians = response.data.data.resolvedByTechnicians;
                    const categories = technicians.map(tech => tech.technician);
                    const counts = technicians.map(tech => tech.solvedCount);
                    setOptions(prevOptions => ({
                        ...prevOptions,
                        xaxis: {
                            ...prevOptions.xaxis,
                            categories: categories
                        },
                    }));
                    setSeries([
                        {
                            name: 'Count',
                            data: counts
                        }
                    ]);
                    setChartData(response.data.data.supportReportByTechnicians);
                    setRatioData(response.data.data.supportRequestRatioByTechnicians);
                    setClosedRatioData(response.data.data.acknowledgeClosedRatioByTechnician)
                    setLoading(false)
                }
                
            })           
            .catch((err) => {
                console.log("Err: ", err);
            });       
        //setTimeout(() => {
        //    setLoading(false)
        //}, 3000)         
    }

    const supporttechnicians = chartData.map(data => data.technician);
    const totalsupport = chartData.map(data => data.totalSupportRequest);
    const totalacknowledged = chartData.map(data => data.totalAcknowledged);
    const totalactive = chartData.map(data => data.totalWorking);
    const typeclosed = chartData.map(data => data.totalClosed);

    const handleSiteCheck = (id) => {
        setIsChecked(true)
        var updatedList = [...checkedListOfSites];
        if (event.target.checked) {
            updatedList = [...checkedListOfSites, id];
        } else {
            updatedList.splice(checkedListOfSites.indexOf(id), 1);
        }
        setCheckedListOfSites([...updatedList]);
    };

    const unchecksites = () => {
        setIsChecked(true)
        const checkboxes = document.querySelectorAll('input[name="checksite"][type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setCheckedListOfSites((prevCheckedList) => {
            const newCheckedList = [];
            return newCheckedList;
        });
        getSupportReportDetails();
    }

    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            background: '#ffffff',
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
        },
        xaxis: {
           
            categories: [],
            title: {
                text: 'Technician'
            }
        },
        yaxis: {
            title: {
                text: 'Resolved Count'
            },
             tickAmount: 8
        },
        tooltip: {
            enabled: false,           
        },
        
    });

    const [series, setSeries] = useState([
        {
            name: 'Count',
            data: []
        }
    ]);

    const ratiooptions = {
        chart: {
            id: 'line-chart',
            background: '#ffffff',
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            
        },
        xaxis: {
            categories: ratioData.map(item => item.technician),
            title: {
                text: 'Technician'
            }
        },
        yaxis: {
            title: {
                text: 'Acknowledged Ratio (%)'
            },
            tickAmount: 8
        },

        stroke: {
            curve: 'smooth',
            width: 1.5
        },
        tooltip: {
            enabled: false,
           
        }
    };

    const ratioseries = [{
        name: 'Acknowledged Ratio (%)',
        data: ratioData.map(item => item.acknowledgedRatio)
    }];

    const closedoptions = {
        chart: {
            id: 'line-chart',
            background: '#ffffff',
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },

        },
        xaxis: {
            categories: closedratioData.map(item => item.technician),
            title: {
                text: 'Technician'
            }
        },
        yaxis: {
            title: {
                text: 'Acknowledged to Closed Ratio (%)'
            },
            tickAmount: 8
        },

        stroke: {
            curve: 'smooth',
            width: 1.5
        },
        tooltip: {
            enabled: false,

        }
    };

    const closedratioseries = [{
        name: 'Acknowledged to Closed Ratio (%)',
        data: closedratioData.map(item => item.closedRatio)
    }];

    const supportoptions = {
        chart: {
            type: 'bar',
            background: '#ffffff',
            height: 350,
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            categories: supporttechnicians,
            title: {
                text: 'Technician'
            }
        },
        tooltip: {
            enabled: false,          
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560'],
    };

    const supportseries = [{
        name: 'Support Requests',
        data: totalsupport,
    },
        {
            name: 'Working',
            data: totalactive,
        },
        {
        name: 'Acknowledged',
        data: totalacknowledged,
        },
        {
        name: 'Closed',
        data: typeclosed,
        },
       
    ];

    const scrollerStyle = {
        maxHeight: '300px',
        overflowY: 'auto',
        overflowX: 'hidden',
    };
    return (
        <div>           
            {roleName == null  ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer" ? <NotAuthorised></NotAuthorised> :
                <>
                    <Layout>
                        <section className="Company_section common ">
                            {loading ? <Loader /> : ''}
                            <Toaster />
                            <div className="container site-container">
                                <div className="py-4 mb-0 w-100 wk-select ratio-graph-filter d-flex justify-content-between align-itmes-center">
                                    <h4 className="m-0">Support Report</h4>
                                    <div className="more-filter">
                                        <ul className="list-inline">
                                            <li className="list-inline-item">
                                                <div class="assigned-drop common-drop dropdown">
                                                    <button class="btn text-white dropdown-toggle px-2 custom-dropbpx" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Filter By Site
                                                    </button>

                                                    <ul class="dropdown-menu ratio-filter-listing" aria-labelledby="dropdownMenuButton1" style={scrollerStyle}>
                                                        <li className="px-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Search By Site"
                                                                value={searchSite}
                                                                onChange={handleSiteChange}
                                                            />
                                                        </li>
                                                        {filteredSite.map((item) =>
                                                            <li className="px-2">
                                                                <a class="dropdown-item">
                                                                    <input type="checkbox" class="form-check-input cursor-pointer" name="checksite"
                                                                        //  defaultChecked={item.isChecked}
                                                                        onChange={() => {
                                                                            handleSiteCheck(item.siteId)

                                                                        }}
                                                                    />
                                                                    <p class="form-check-label ml-2 non-clickable" for="exampleCheck1">{item.siteName}</p>
                                                                </a>

                                                            </li>
                                                        )}
                                                        <li className="pr-3 text-right">
                                                            <button
                                                                type="button"
                                                                class="btn btn-bordered"
                                                                onClick={() => {

                                                                    unchecksites()
                                                                    setSearchSite('');
                                                                }}

                                                            >
                                                                Clear
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="dash-column">

                                    <div className="row">
                                        <div className="col-sm-12 boxes-outers col-md-6 col-lg-3">
                                            <div className="box--inner">
                                                <div className="dash-icon dashboard-icon1 p-2">
                                                    <img src={supportIcon} alt="" />
                                                </div>
                                                <div className="content--box">
                                                    <div className="counts-text">{totalsupportCount}</div>
                                                    <p>Support Requested</p>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="boxes-outers col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                            <div className="box--inner">
                                                <div className="dash-icon dashboard-icon3 p-2">
                                                    <img src={acknowledgesupportIcon} alt="" />
                                                </div>
                                                <div className="content--box">
                                                    <div className="counts-text">{totalacknowledgedCount}</div>
                                                    <p>Acknowledged</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-sm-12 boxes-outers col-md-6 col-lg-3">
                                            <div className="box--inner">
                                                <div className="dash-icon dashboard-icon2 p-2">
                                                    <img src={activesupportIcon} alt="" />
                                                </div>
                                                <div className="content--box">
                                                    <div className="counts-text">{totalactiveCount}</div>
                                                    <p>Active</p>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="boxes-outers col-md-6 col-sm-12 col-lg-3 col-xl-3">
                                            <div className="box--inner">
                                                <div className="dash-icon dashboard-icon4 p-2">
                                                    <img src={closedIcon} alt="" />
                                                </div>
                                                <div className="content--box">
                                                    <div className="counts-text">{totalclosedCount}</div>
                                                    <p>Closed</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mb-4'>
                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                                            <div className="analytic-card request-tech">
                                                <h4>Request Solved By Technician</h4>
                                                <div className="analytic-card-body p-3">
                                                    <Chart
                                                        options={options}
                                                        series={series}
                                                        type="bar"
                                                        width="100%"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6">
                                            <div className="analytic-card">
                                                <h4>Request Report of Each Technician</h4>
                                                <div className="analytic-card-body p-3">
                                                    <Chart
                                                        options={supportoptions}
                                                        series={supportseries}
                                                        type="bar"
                                                        width="100%"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" >
                                            <div className="analytic-card" >
                                                <h4>Support Acknowledged Ratio</h4>
                                                <div className="analytic-card-body acknowledge-radio-grapgh p-3">
                                                    <Chart
                                                        className="radio-chart"
                                                        options={ratiooptions}
                                                        series={ratioseries}
                                                        type="line"
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12" >
                                            <div className="analytic-card" >
                                                <h4>Acknowledged to closed Ratio</h4>
                                                <div className="analytic-card-body acknowledge-radio-grapgh p-3">
                                                    <Chart
                                                        className="radio-chart"
                                                        options={closedoptions}
                                                        series={closedratioseries}
                                                        type="line"
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Layout>
                </>
            }
        </div>
    )

}
export default connect()(SupportReport);
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../../../src/custom.css'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Arrow_leftbrown from "../../assets/images/Arrow_leftbrown.svg";
import { Input, Form, FormFeedback } from "reactstrap"
import { Formik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import * as url from "../../api/uri_helper";
import axios from "axios";
import Layout from '../Layout';
import Loader from "../loader";
import { fetchAllCompaniesdata, fetchcompanyrelationdata, fetchCompanyRelationById, fetchUpdatedCompanyRelation, fetchUserMenudata } from "../../Redux/Api/fetch.api";
import { getallcompanies } from '../../Redux/Actions/CompanyActions';
import { postallcompanyrelationdata, updateallcompanyrelationdata } from '../../Redux/Actions/CompanyRelationAction';
import NotAuthorised from "../Authentication/NotAuthorised";
import { getuserroleandmenu } from '../../Redux/Actions/UsersActions';

const AddMapping = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [allCompaniesData, setAllCompaniesData] = useState([]);
    const [masterCompanyId, setMasterCompanyId] = useState();
    const [subCompanyId, setSubCompanyId] = useState();
    const [isActive, setisActive] = useState(true)
    const [currentId, setCurrentId] = useState(null);
    const [checked, setChecked] = useState(true);
    let history = useHistory();
    const [disable, setDisable] = useState(false);
    const loggedUserId = localStorage.getItem('UserId')
    const [roleName, setRoleName] = useState(null);
    const [userRoleData, setUserRoleData] = useState([]);

    let pageButton = "Update"

    if (!currentId) {
        pageButton = "Save"
    }

    useEffect(() => {
        getUserDetails();
        getallcompaniesdata();
    }, [])

    const handleChange = () => {
        setisActive(!isActive)
    }

    const getUserDetails = () => {
        fetchUserMenudata(loggedUserId)
            .then(response => {
               
                dispatch(getuserroleandmenu(response.data.data))
                setUserRoleData(response.data.data.userRoleDetails);
                if (response.data.data.userRoleDetails[0].roleName)
                    setRoleName(response.data.data.userRoleDetails[0].roleName);
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }

    useEffect(() => {
        setLoading(true);
        let urlData = location.pathname.split('/');
        setCurrentId(urlData[3])
        if (currentId) {
            setLoading(true);
            fetchCompanyRelationById(urlData[3]).then(
                response => {
                    debugger;
                    setMasterCompanyId(response.data.data.masterCompanyId)
                    setSubCompanyId(response.data.data.subCompanyId)
                    setisActive(response.data.data.active)                    
                })
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)

    }, [currentId])

    const getallcompaniesdata = () => {
        setLoading(true)
        fetchAllCompaniesdata()
            .then(response => {
                dispatch(getallcompanies(response.data))
                setAllCompaniesData(response.data.data)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const addRelationData = async () => {
        
        setLoading(true)
        fetchcompanyrelationdata({
            MasterCompanyId: masterCompanyId,
            SubCompanyId: subCompanyId,
        }).then(response => {
            
            dispatch(postallcompanyrelationdata(response.data))
            if (!response.data.success) // False 
            {
                toast.error(response.data.message, {
                    className: "toastSuccess",
                },);
                setLoading(false);
                setDisable(false)
                
            }
            else {
                toast.success(response.data.message, {
                    className: "toastSuccess",
                },);
                history.push('/Mapping');
                setLoading(false);
                setDisable(true)
               
            }
        })
            .catch((err) => {
                console.log("Err: ", err);
            });


    }

    const updateRelationData = async () => {
        debugger;
        setLoading(true)
        fetchUpdatedCompanyRelation({
            ID: currentId,
            MasterCompanyId: masterCompanyId,
            SubCompanyId: subCompanyId,
            Active: isActive
        }).then(response => {
            debugger;
            dispatch(updateallcompanyrelationdata(response.data))
            if (!response.data.success) // False 
            {
                toast.error(response.data.message, {
                    className: "toastSuccess",
                },);
                setLoading(false);
                setDisable(false)

            }
            else {
                toast.success(response.data.message, {
                    className: "toastSuccess",
                },);
                history.push('/Mapping');
                setLoading(false);
                setDisable(true)

            }
        })


    }

    return (
        <div>
            {roleName == null ? <Loader /> : roleName && roleName != "SuperAdmin" && roleName && roleName != "Developer"? <NotAuthorised></NotAuthorised> :
                <Layout>
                    <section className="User_section common ">
                        <Toaster />
                        {loading ? <Loader /> : ''}
                        <div className="container">
                            <div className="mt-4 w-100 position-relative wk-select">
                                <h4 className="m-0">
                                    <Link className="backBTN" to="/Mapping">
                                        <img src={Arrow_leftbrown}></img>
                                        Back
                                    </Link>
                                </h4>

                            </div>
                            <div className="content-area">

                                <div className="col-lg-10">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form-group wk-select">
                                                <label>Master Company <span className="required-fields"> * </span></label>
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="roles" aria-label=".form-select-lg example"
                                                    value={masterCompanyId}
                                                    key={masterCompanyId}
                                                    onChange={(e) => setMasterCompanyId(e.target.value)}
                                                >
                                                    <option selected="">Select Master Company</option>
                                                    {
                                                        allCompaniesData && allCompaniesData.map(company =>
                                                            company.companyId != subCompanyId ?
                                                                <option key={company.companyId} value={company.companyId}>
                                                                    {company.companyName} </option> : null
                                                        )
                                                    }
                                                </select>

                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="form-group wk-select">
                                                <label>Sub Company <span className="required-fields"> * </span></label>
                                                <select className="form-select mb-0 flex-grow-1 filter-select" name="roles" aria-label=".form-select-lg example"
                                                    value={subCompanyId}
                                                    key={subCompanyId}
                                                    onChange={(e) => setSubCompanyId(e.target.value)}
                                                >
                                                    <option selected="">Select Sub Company</option>
                                                    {
                                                        allCompaniesData && allCompaniesData.map((company) => (
                                                            company.companyId != masterCompanyId ?
                                                                <option key={company.companyId} value={company.companyId}>
                                                                    {company.companyName} </option> : null
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>

                                        <div className="col-md-10 mb-4">
                                            <div className="form-checkbox ">
                                                {loading && currentId == undefined || currentId == null || currentId == isNaN ?
                                                    <label>
                                                        <input type="checkbox" className="check mr-1" defaultChecked={checked} name="isActive" value={isActive} onChange={() => handleChange()} ></input> Active</label> :

                                                    <label>  <input type="checkbox" className="check mr-1" name="isActive" checked={isActive} onChange={() => handleChange()} ></input>
                                                        Active</label>}
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            {masterCompanyId && masterCompanyId != "Select Master Company" && subCompanyId && subCompanyId != "Select Sub Company" ? <button className="btn btn-primary"
                                                onClick={currentId == undefined || currentId == null || currentId == isNaN ? addRelationData : updateRelationData}>{pageButton}</button>
                                                : <button className="btn btn-primary" disabled
                                                    onClick={currentId == undefined || currentId == null || currentId == isNaN ? addRelationData : updateRelationData}>{pageButton}</button>}

                                            <Link className="btn btn-bordered ml-2" to="/Mapping">Cancel</Link>

                                        </div>


                                    </div>
                                </div>



                            </div>

                        </div>
                    </section>


                </Layout>
            }
        </div>
    )

}
export default connect()(AddMapping);


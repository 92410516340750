/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { connect, useDispatch, useSelector } from 'react-redux';
//import linkedinIcon from '@iconify-icons/uil/linkedin';
//import youtubeIcon from '@iconify-icons/mdi/youtube';
//import Logo from '../../assets/images/authenteklogo.png';
import '../../../src/custom.css'
import LoginImage from "../../assets/images/login-image.png";
//import Signinsqurelight from "../../assets/images/logout.png";
import { useHistory } from "react-router-dom";
import Changepassword from "../../assets/images/change-password.png";
import Feedbackicon from "../../assets/images/Feedbackicon.svg";
import BackArrow from "../../assets/images/arrow-back.svg";
import DropArrow from "../../assets/images/drop-arrow.png";
import DropArrowBlack from "../../assets/images/droparrow-black.png";
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { loginasuser, fetchUsersFromNotification } from "../../Redux/Api/fetch.api";
import { validateuserdata, getuserslistfromnotification } from '../../Redux/Actions/UsersActions';
import Loader from "../loader";
import * as signalR from "@microsoft/signalr";
import { fetchLogout } from "../../Redux/Api/fetch.api";
import { Modal, Button } from "react-bootstrap";
import { Form } from "reactstrap"
import TEKaVerseSiteMap from "../../assets/images/SiteMap.png";
//import SiteMapIcon from "../../assets/images/SiteMap.svg";
import MapIcon from "../../assets/images/map-icon.png";
import { Tooltip, Skeleton } from 'antd';
import Person2Icon from '@mui/icons-material/Person2';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationIco from "../../assets/images/notifications-ico.svg";

const Navbar = ({ supportRequestCount }) => {
    const [loading, setLoading] = useState(false);
    const allLoginTypes = useSelector((state) => state.allLogindata.login);
    const allUserTypes = useSelector((state) => state.allUserdata.users);
    const userCompany = allUserTypes.data && allUserTypes.data.userCompanyDetails;
    const companyLogo = userCompany && userCompany[0].companyLogo;
    const colorcode = allUserTypes.data && allUserTypes.data.colorCodeDetails;
    const headercolor = colorcode && colorcode.headerColorCode;
    const userRole = allUserTypes.data && allUserTypes.data.userRoleDetails;
    const roleName = userRole && userRole[0].roleName;
    const roleId = userRole && userRole[0].roleId;
    const emailid = userRole && userRole[0].emailId;
    const firstname = userRole && userRole[0].firstName;
    const lastname = userRole && userRole[0].lastName;
    const username = `${firstname ?? ""} ${lastname ?? ""}`.trim();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [settingdropdownOpen, setSettingDropdownOpen] = useState(false);
    const [supportdropdownOpen, setSupportDropdownOpen] = useState(false);
    const logincheck = localStorage.getItem('isloginClicked');
    const remmeberMe = localStorage.getItem('isChecked');
    const loggedInEmailId = localStorage.getItem('emailid');
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const apps = ["/Apps"];
    const [showModal, setShowModal] = useState(false);
    const openModal = () => setShowModal(true);
    const [isSiteMapOpened, setisSiteMapOpened] = useState(false);
    const [allUserData, setAllUserData] = useState([]);
    const loggedUserId = localStorage.getItem('UserId')
    const userId = allUserTypes.data && allUserTypes.data.userId;
    const profilePicture = localStorage.getItem('profilePicture');
    const [notificationCount, setNotificationCount] = useState(1);
    const { REACT_APP_JOBS_LINK } = process.env;
    
    useEffect(() => {                              
        if (localStorage.getItem('tekaVerseToken') == null) {
            history.push("/");
        }

        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/logoutHub")
            .build();

        connection.start().then().catch((err) => console.error(err));

        connection.on("ShowAlert", (emailId, isRefresh) => {
            if (emailId == loggedInEmailId) {
                if (!isRefresh) {
                    if (!remmeberMe) {
                        localStorage.removeItem('userName')
                        localStorage.removeItem('emailid')
                        localStorage.removeItem('password')
                        localStorage.removeItem('isChecked')
                        localStorage.removeItem('tekaVerseToken');
                    }
                    localStorage.removeItem('tekaVerseToken');
                    openModal()
                } else {
                    window.location.reload(true);
                }
            }
        });
        getallusersdata();

        return () => {
            connection.off("ShowAlert");
        };
    }, [])

    const MyProfile = () => {
        history.push('/MyProfile')
    }
    
    const handleKey = (event) => {
        if (event.keyCode === 1) {
            switch (event.target.innerText) {
                case 'Apps':
                    return history.push("/Apps");
                case 'Company':
                    return history.push("/Company")
                case 'User':
                    return history.push("/User")
            }
        }
    }

    const ChangePassword = (e) => {
        history.push('/ChangePassword');
    };

    const BackToAccount = () => {
        localStorage.removeItem('tekaVerseToken');
        getvalidateuser();
    }

    const getvalidateuser = () => {
        loginasuser(loggedInEmailId)
            .then(response => {
                dispatch(validateuserdata(response.data))
                localStorage.setItem('UserId', response.data.data.userId);
                localStorage.setItem('tekaVerseToken', response.data.data.token);
                localStorage.setItem('isloginClicked', false)

                history.push("/Apps");
                window.location.reload(true);

            })
            .catch((err) => {
                console.log("Err: ", err);
            });

    }

    const getallusersdata = () => {
        setLoading(true)
        fetchUsersFromNotification()
            .then(response => {
                dispatch(getuserslistfromnotification(response.data))
                setAllUserData(response.data.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log("Err: ", err);
            });       
    }

    const supportUser = allUserData && allUserData.includes(parseInt(loggedUserId));

    const logoutUser = () => {
        //IsLogoutOrRefresh = true -- Refresh
        //IsLogoutOrRefresh = false -- Logout

        fetchLogout(loggedInEmailId, false, true)
            .then(response => {
            })

            .catch((err) => {
                console.log("Err: ", err);
            });

    }

    const handleToggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleSettingDropdown = () => {
        setSettingDropdownOpen(!settingdropdownOpen);
    };

    const handleSupportDropdown = () => {
        setSupportDropdownOpen(!supportdropdownOpen);
    };

    const Logout = (e) => {
        logoutUser();
        if (!remmeberMe) {
            localStorage.removeItem('userName')
            localStorage.removeItem('emailid')
            localStorage.removeItem('password')
            localStorage.removeItem('isChecked')
            localStorage.removeItem('tekaVerseToken');
            localStorage.removeItem('profilePicture');
            localStorage.removeItem('UserId');
            window.location.reload(true);
        }
        localStorage.removeItem('tekaVerseToken');
        localStorage.removeItem('UserId');
        window.location.replace("/");
    };

    const handleCloseSiteMap = () => {
        setisSiteMapOpened(false);
    }

    const handleOpenSiteMap = () => {
        setisSiteMapOpened(true);
    };

    const handleMouseEnter = () => {       
            setSettingDropdownOpen(true);
        
    };

    const handleMouseLeave = () => {       
            setSettingDropdownOpen(false);       
    };

    const handleMappingMouseEnter = () => {
        setDropdownOpen(true);
    };

    const handleMappingMouseLeave = () => {
        setDropdownOpen(false);
    };

    const handleSupportMouseEnter = () => {
        setSupportDropdownOpen(true);
    };

    const handleSupportMouseLeave = () => {
        setSupportDropdownOpen(false);
    };

    const handleClick = () => {
        setSettingDropdownOpen(prev => !prev);
    };

    const openJobs = () => {    
        //var url = "https://tekaverse.com/Jobs/Dashboard"
        //var win = window.open(url, '_blank');
        var win = window.open("/Jobs/Dashboard", '_blank');
        win.focus();
    }

    return (
        <div>
            {showModal && (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" style={{ color: '#000' }}>Logout Confirmation</h5>
                            </div>
                            <div className="modal-body" style={{ color: '#000' }}>
                                <p>You have been logged out by the Super Admin.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={Logout}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal && <div className="modal-backdrop fade show"></div>}
            <div className="top_bar_outer" style={{
                backgroundColor: headercolor,
            }}
            >
                <div className="container-fluid">
                    <div className="top_bar">
                        <ul className="top_contact_detail">
                            <div className="feedback-btn d-flex">
                                {roleId == 7 ?
                                    <Tooltip title="Site Map of TEKaVerse"> <img className="mr-2" src={MapIcon} onClick={handleOpenSiteMap}>
                                    </img> </Tooltip> : ""
                                }
                            </div>
                        </ul>
                        <ul className="top_social list-inline">
                            <li className="list-inline-item">
                                {/*<div className="feedback-btn d-flex position-relative mar-right-15">*/}
                                {/*    {notificationCount > 0 ?*/}
                                {/*        <div className="notification--btn notify">*/}
                                {/*            <span>{notificationCount}</span>*/}
                                {/*        </div>*/}
                                {/*        : ''}*/}
                                {/*    <img className="mr-2" src={NotificationIco}></img>*/}
                                {/*</div>*/}
                            </li>
                            <li className="list-inline-item">
                                <div className="feedback-btn d-flex">
                                    <img className="mr-2" src={Feedbackicon}></img>
                                    <Link className="" to="/Workitem"> Feedback</Link>
                                </div>
                            </li>
                            <li className="list-inline-item">
                                <div className="dropdown header-dropdown">

                                    <button className="d-flex dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">

                                        <div className="card d-flex align-items-center whflex dropdown-toggle">
                                            {/*<span> <img src={LoginImage}></img></span>*/}
                                            <div className="content">
                                                <h6 className="dashboard_user"><div className='img-height' ><img src={profilePicture != null && profilePicture != "null" && profilePicture !== "" ? profilePicture : LoginImage}></img></div></h6>
                                            </div>
                                        </div>
                                    </button>
                                    <ul className="dropdown-menu side_profile" aria-labelledby="dropdownMenuButton1">
                                        <div className="card d-flex align-items-center">
                                            <span>
                                                <div className="profile-pic-size"><img src={profilePicture != null && profilePicture != "null" && profilePicture !== "" ? profilePicture : LoginImage}></img></div>
                                            </span>
                                            <div className="content">
                                                <h5>{username} {"(" + roleName + ") "}</h5>
                                                <p className="text-muted">{emailid}</p>
                                            </div>
                                        </div>
                                        <ul>
                                            {logincheck == "true" ?
                                                <li className="remove_dots">  <a className="dropdown-item" onClick={(e) => BackToAccount(e)}>
                                                    <img src={BackArrow}></img>
                                                    Back To My Account</a>
                                                </li> : ""
                                            }

                                            <li className="remove_dots">  <a className="dropdown-item" onClick={(e) => ChangePassword(e)}>
                                                <img src={Changepassword}></img>
                                                Change Password</a>
                                            </li>

                                            <li className="remove_dots">  <a className="dropdown-item" onClick={(e) => MyProfile()}>
                                                <Person2Icon className='mr-2' fontSize="small" />
                                                My Profile</a>
                                            </li>

                                           

                                            <li className="remove_dots"><a className="dropdown-item" onClick={(e) => Logout(e)}>
                                                <LogoutIcon className='mr-2' fontSize="small" />
                                                Sign Out</a>
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                            </li>


                        </ul>
                    </div>
                </div>
            </div>

            {loading ? <Skeleton active /> :
                <>
                    <Link className="navbar-brand main_logo" to="/Apps"> <img src={companyLogo} alt="Logo" /> </Link>
                    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                        <div className="container">

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">

                                {userRole && userRole.map((item, id) =>
                                    <ul className="navbar-nav  mb-2 mb-lg-0" >

                                        {item.roleId >= 1 ?
                                            <>
                                                <li className="nav-item" tabIndex={1} onKeyDown={(event) => handleKey(event)}>
                                                    <NavLink className="nav-link" to="/Dashboard" exact activeClassName="active">Dashboard</NavLink>
                                                </li>
                                            </>
                                            : ""}

                                        {item.roleId >= 7 && item.roleId != 9 ?
                                            <>
                                                <li className="nav-item" tabIndex={2} onKeyDown={(event) => handleKey(event)}>
                                                    <NavLink className="nav-link" to="/Company" activeClassName="active">Company</NavLink>
                                                </li>
                                                <li className="nav-item" tabIndex={4} onKeyDown={(event) => handleKey(event)}>
                                                    <NavLink className="nav-link" to="/Site" activeClassName="active">Sites</NavLink>
                                                </li>

                                                <li className="nav-item" tabIndex={5} onMouseEnter={handleMappingMouseEnter}
                                                    onMouseLeave={handleMappingMouseLeave}
                                                    onKeyDown={(event) => handleKey(event)}>
                                                    <p className="nav-link drop-btn mb-0" activeClassName="active">
                                                        <span>Mapping</span>
                                                        <span> <img src={DropArrow} onClick={handleToggleDropdown} ></img></span>
                                                    </p>
                                                    {dropdownOpen && (
                                                        <div className="drop-box p-0">
                                                            <NavLink className="mb-0 p-2" to="/Mapping" >Company & Sub Company</NavLink>
                                                            <NavLink className="mb-0 p-2 border-0" to="/SubCompanyMapping">Sub Company & Sites</NavLink>
                                                        </div>
                                                    ) }
                                                </li>

                                                <li className="nav-item" tabIndex={5} onMouseEnter={handleMouseEnter}
                                                    onMouseLeave={handleMouseLeave} onKeyDown={(event) => handleKey(event)}>
                                                    <p className="nav-link drop-btn mb-0" activeClassName="active">
                                                        <span>Setting</span>
                                                        <span> <img src={DropArrow} onClick={handleSettingDropdown}></img></span>
                                                    </p>
                                                    {settingdropdownOpen && (
                                                        <div className="drop-box setting-dropdown">
                                                            <NavLink className="" to="/Banner" >Banners</NavLink>
                                                            <NavLink className="" to="/CallOrder" >Support Group</NavLink>
                                                            <a type="text" className="logs-menu position-relative text-black mb-0">
                                                                <p className="logs-drop d-flex justify-content-between mb-0">
                                                                    <span>Logs</span>
                                                                    <span> <img src={DropArrowBlack}></img></span>
                                                                </p>
                                                                <div className="logs-submenu">
                                                                    <NavLink className="px-2 py-1" to="/UserLogs" >Activity</NavLink>
                                                                    <NavLink className="px-2 py-1 border-0" to="/UserSessions" >Sessions</NavLink>
                                                                </div>
                                                            </a>
                                                            <NavLink className="" to="/Notifications" >Notifications</NavLink>
                                                            <NavLink className="" to="/Projects" >Projects</NavLink>
                                                            <NavLink className="border-0" to="/Tekacontroller" >TEKaController</NavLink>
                                                            <NavLink className="" to="/Version" >Versions</NavLink>

                                                        </div>
                                                   ) }
                                                </li>

                                               
                                            </>
                                            : ""}

                                        {item.roleId >= 6 && item.roleId != 9 ?
                                            <>
                                                <li className="nav-item" tabIndex={3} onKeyDown={(event) => handleKey(event)}>
                                                    <NavLink className="nav-link" to="/User" activeClassName="active">User</NavLink>
                                                </li>
                                                <li className="nav-item" tabIndex={6} onKeyDown={(event) => handleKey(event)}>
                                                    <NavLink className="nav-link" to="/UserCompany" activeClassName="active">User Company</NavLink>
                                                </li>
                                                <li className="nav-item" tabIndex={7} onKeyDown={(event) => handleKey(event)}>
                                                    <NavLink className="nav-link" to="/UserSite" activeClassName="active">User site</NavLink>
                                                </li>
                                            </>
                                            : ""}

                                        {item.roleId >= 1 ?
                                            <>
                                                <li className="nav-item" tabIndex={1} 
                                                   
                                                    onKeyDown={(event) => handleKey(event)}>
                                                    <NavLink className="nav-link" to="/Apps" exact activeClassName="active">Apps</NavLink>
                                                </li>
                                                {item.roleId >= 7 && item.roleId != 9 || supportUser ?
                                                    <li className="nav-item" tabIndex={5} onMouseEnter={handleSupportMouseEnter}
                                                        onMouseLeave={handleSupportMouseLeave}
                                                        onKeyDown={(event) => handleKey(event)}>
                                                        <p className="nav-link drop-btn mb-0" activeClassName="active">
                                                            <span>Support</span>
                                                            {supportRequestCount > 0 ? <span className="blink support-badge ml-1">{supportRequestCount}
                                                            </span> : ""}
                                                            <span> <img src={DropArrow} onClick={handleSupportDropdown}></img></span>
                                                        </p>
                                                       
                                                        {supportdropdownOpen && (
                                                            <div className="drop-box setting-dropdown">
                                                                <NavLink className="" to="/Support" >Request</NavLink>
                                                                {item.roleId >= 7 && item.roleId != 9 ?
                                                                    <NavLink className="" to="/SupportReport" >Report</NavLink>
                                                                    : ""
                                                                }
                                                            </div>
                                                        )}
                                                    </li> : ""
                                                  
                                                }
                                                {item.roleId >= 7 && item.roleId != 9  ?
                                                    <li className={`nav-item ${location.pathname === '/Support' ? 'active' : ''}`} >                                                       
                                                        <Link className="nav-link position-relative"
                                                            onClick={() => openJobs()}>
                                                            <span>Jobs</span>                                                           
                                                        </Link>
                                                    </li> : ""
                                                }
                                            </>
                                            : ""}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </nav>

                    <Modal
                        show={isSiteMapOpened}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="sitemap-modal-dialog-video"
                    >
                        <Modal.Header closeButton><h4 className="text-black">TEKaVerse Site Map</h4>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                <Form className="form-horizontal">
                                    <div className="add-banner-body mb-3">
                                        <img className="mr-2" src={TEKaVerseSiteMap}></img>
                                    </div>
                                    <div className="text-right">
                                        <button className="btn btn-primary" type="button" onClick={handleCloseSiteMap}>
                                            Cancel
                                        </button>
                                    </div>
                                </Form>
                            }
                        </Modal.Body>

                    </Modal>
                </>
            }
        </div>
    );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import { Input,  FormFeedback } from "reactstrap"
import { connect, useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useHistory, useLocation } from "react-router-dom";
import socialIcon1 from '../../assets/images/social1.svg';
import socialIcon2 from '../../assets/images/social2.svg';
import socialIcon3 from '../../assets/images/social3.svg';
import socialIcon4 from '../../assets/images/social4.svg';
import LocationIcon from '../../assets/images/icon1.svg';
import PhoneIcon from '../../assets/images/icon2.svg';
import SupportIcon from '../../assets/images/icon3.svg';
import '../../../src/custom.css'
import Footer from '../PageLayout/Footer';
import Header from '../PageLayout/Header';
import * as url from "../../api/uri_helper";
import Layout from '../Layout';
import { fetchsendmessage } from "../../Redux/Api/fetch.api";
import { useFormik } from 'formik';
import { postsendmessage } from '../../Redux/Actions/SendMessageActions';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import * as Yup from 'yup';
import axios from "axios";
import Loader from "../loader";


const TOAST_LIMIT = 1;
const ContactSection = () => {
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
    }, [toasts]);
    const [name, setName] = useState("");
    const [emailId, setEmailid] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch(); 
    const [messageErr, SetMessageErr] = useState(false)
    const history = useHistory();
    const [nameErr, SetNameErr] = useState(false)
    const [count, setCount] = useState(0);
   

   

    const sendMessage = async () => {  
        
        setLoading(true)
        const formData = new FormData();
        formData.append("ClientName", name);
        formData.append("ClientEmail", emailId);
        formData.append("ClientMessage", message);
        const response_ = await axios({
            method: 'post',
            url: url.VERIFY_SEND_MESSAGE,
            data: formData,
        })
            .then(response => {
                debugger;
                dispatch(postsendmessage(response.data))
           if (!response.data.success) {
               toast.error(response.data.message, {
                   className: "toastSuccess",
               });
           }
          else {
               
                toast.success(response.data.message, {
                    className: "toastSuccess",
                });
               history.push('/Apps'); 
              
           }   
               
            }).catch((err) => {
                console.log("Err: ", err);
            });
        setLoading(false)
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            emailId: emailId ? emailId : '',
           
        },
        validationSchema: Yup.object({
            emailId: Yup.string().required('Please Enter Email').matches(
                /^\w+([\.-]?\w+)*@\w+([\.web]?\w+)*(\.\w{2,3})+$/,
                "Please enter a valid email"
            ),
           
        }),
        onSubmit: (values) => {
            if (!nameErr && !messageErr) {

                sendMessage(values);
            }
        }
    })
    
    return (
        <Layout>                           
                <section className="contact_section common">
                    <Toaster />
                {loading ? <Loader /> : ''}
            <Container>
                        <div className="support-wrap py-5 my-3">
                           
                    <Row>
                        <Col sm={5}>
                            <div className="left_form">
                                <div className="headings_2">
                                    <h2>Let’s Build your Solution</h2>
                                    <p>Feel free to contact us and we will get back to you as soon as we can.</p>
                                </div>
                                        <Form className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();   
                                                if (!name) {
                                                    SetNameErr(true);
                                                }
                                                if (!message) {
                                                    SetMessageErr(true);
                                                }
                                                return false;

                                            }} >
                                            <div className="col-md-14">
                                            <div className="form-group">
                                                <label>Name <span className="required-fields"> * </span> </label>
                                            <div className="input-group">
                                                <Input name="name" className="form-control input-bg" placeholder="Enter Name"
                                                            type="text" maxLength={30} value={name} onChange={(e) =>
                                                            {
                                                                setName(e.target.value)
                                                                SetNameErr(false)                                             
                                                            }}                                                  
                                                    />                                                   
                                                    </div>
                                                    {< small className="text-danger d-block mt-2">{!nameErr ? ' ' : 'Please Enter Name'}</small>}
                                               </div>
                                            </div>
                                           
                                            <div className="form-group">
                                                <label>Email Id <span className="required-fields"> * </span></label>
                                            <div className="input-group">
                                                <Input name="emailId" className="form-control input-bg" placeholder="Enter Email" type="text" maxLength={50} onChange={(e) => { validation.handleChange(e); setEmailid(e.target.value) }}
                                                    onBlur={validation.handleBlur} value={validation.values.emailId}
                                                    invalid={validation.touched.emailId && validation.errors.emailId ? true : false} />
                                                    {validation.touched.emailId && validation.errors.emailId ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.emailId}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-14">
                                                <div className="form-group">
                                                    <label>Message <span className="required-fields"> * </span></label>
                                                    <div className="input-group">
                                                    <Input name="message" className="form-control input-bg" placeholder="Enter Message"
                                                            type="textarea" maxLength="250" value={message} onChange={(e) => {
                                                                setMessage(e.target.value)                                   
                                                                SetMessageErr(false)
                                                            }} />
                                                    </div>                                               
                                            </div>                                           
                                                {< small className="text-danger d-block mt-2">{!messageErr ? ' ' : 'Please Enter Message'}</small>}
                                            </div>
                                           
                                            <Button type="submit" className="btn btn-primary mt-3">Send Message</Button>
                                </Form>
                            </div>
                        </Col>
                        <Col sm={7}>
                            <div className="right_contact">
                                <ul className="contact_list">
                                    <li>
                                        <span className="con_icon"><img src={LocationIcon} width="24" height="24"></img></span>
                                        <span className="con_detail">
                                            <b>Location</b>
                                            <span>2155 Chenault Dr. Suite 510 Carrollton, TX 75006</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="con_icon"><img src={PhoneIcon} width="24" height="24"></img></span>
                                        <span className="con_detail">
                                            <b>Feel free to contact us</b>
                                            <span>(833) 288-4835</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="con_icon"><img src={SupportIcon} width="24" height="24"></img></span>
                                        <span className="con_detail">
                                            <b>Support</b>
                                            <span>sales@authentek.io</span>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="social_icons">
                                    {/*<li><a href="#"><img src={socialIcon1} alt="Social Icon 1" /></a></li>*/}
                                    {/*<li><a href="#"><img src={socialIcon2} alt="Social Icon 2" /></a></li>*/}
                                    {/*<li><a href="#"><img src={socialIcon3} alt="Social Icon 3" /></a></li>*/}
                                    {/*<li><a href="#"><img src={socialIcon4} alt="Social Icon 4" /></a></li>*/}
                                        </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
           
            </section>
            
        </Layout>
    );
}

export default ContactSection;

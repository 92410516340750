import * as React from 'react';
import Footer from '../PageLayout/Footer';
import Header from '../PageLayout/Header';

function Home() {
    return (
        <>
          <Header />
        <section className="Home">
          
            <div className="container">
                <h1>Home coming soon.</h1>
            </div>
            <Footer />

            </section>
        </>
    );
}

export default Home;

import { getAllUsers } from "../Constants/Users"
// initial state
const initialState = {
    users: []
}

export const UsersReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case getAllUsers.GET_USERROLEANDMENU:
            return { ...state, users: payload };
        default:
            return state;
    }
};
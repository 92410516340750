import { UserCompanyDetails } from "../Constants/UserCompany"

export const getusercompanydata = (usercomtypes) => {
    return {
        type: UserCompanyDetails.GET_USERSWITHCOMPANIES,
        payload: usercomtypes
    };
};

export const getsubcompanybyuserid = (usercomtypes) => {
    return {
        type: UserCompanyDetails.GET_SUBCOMPANIESBYUSERID,
        payload: usercomtypes
    };
};

export const postusercompanydata = (usercomtypes) => {
    return {
        type: UserCompanyDetails.POST_USERSWITHCOMPANIES,
        payload: usercomtypes
    };
};

export const updateusercompanydata = (usercomtypes) => {
    return {
        type: UserCompanyDetails.UPDATE_USERSWITHCOMPANIES,
        payload: usercomtypes
    };
};

export const deleteusercompanydata = (usercomtypes) => {
    return {
        type: UserCompanyDetails.DELETE_USERSWITHCOMPANIES,
        payload: usercomtypes
    };
};


export const SiteDetails = {
    GET_ALLSITES: "GET_ALLSITES",
    GET_SITESBYCOMPANYID: "GET_SITESBYCOMPANYID",
    POST_ALLSITES: "POST_ALLSITES",
    DELETE_SITEDETAILS: "DELETE_SITEDETAILS",
    UPDATE_SITEDETAILS: "UPDATE_SITEDETAILS",
    GET_ALLSITESDETAILS: "GET_ALLSITESDETAILS",
    GET_SITERETENTIONDATA: "GET_SITERETENTIONDATA",
   
}

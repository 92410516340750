import axios from "axios";
const GetClientIPAddress = async () => {
    try {
        const ipAddress = await axios.get("https://ipapi.co/json/");
       
        return ipAddress.data?.ip ?? null;
    } catch (error) {      
        return null;
    }
}
export default GetClientIPAddress;
import { logindata } from "../Constants/Login"
// initial state
const initialState = {
    login: []
}

export const LoginReducers = (state = initialState, { type, payload }) => {   
    switch (type) {
        case logindata.GET_LOGINDATA:
            return { ...state, login: payload };
        default:
            return state;
    }
};